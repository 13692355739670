
import React from "react";
import { classNames } from "../../utils/helpers";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { ChatIcon } from "@heroicons/react/outline";


const Categories = ({ categories, loading }) => (
  <div className="pl-6 lg:w-80">
    <div className="pt-6 pb-2">
      <h2 className="text-sm font-semibold">Topics Categories</h2>
    </div>
    <div>
      {loading ? (
        <Skeleton className="mt-4" count={5} />
      ) : (
        categories.length > 0 ?
          <>
            <ul role="list" className="">
              {categories.map((item) => (
                <>
                  <li key={item.name}>
                    <Link
                      to={`/forum/category/${item._id}`}
                      className={classNames(
                        item.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-blue-500',
                        ' flex items-center py-2 text-sm font-medium rounded-md border-b-1'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <ChatIcon
                        className={classNames(
                          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-blue-500',
                          'mr-4 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  </li>
                </>
              ))}
            </ul>
            {/* <a href="#" className="bg-blue-500 py-2 rounded flex justify-center text-white w-full text-sm font-medium mt-2 hover:bg-blue-500">View All &rarr;</a> */}
          </>
          : <p className="mt-4 text-gray-500 text-sm">No category available.</p>
      )}
    </div>
  </div>
)
export default Categories;
