import React from 'react';
import { EyeIcon, EyeOffIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react'

export default function PasswordField(props) {

  const [show, setShow] = useState(false);

  return (
    <div>
      <div className="relative rounded-md">
        <input
          type={show ? 'text' : 'password'}
          {...props}
          className={`form__border w-full my-2 ${props.errors ? "red__form__border" : ""
            }`}
        //   className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={() => setShow(!show)}>
          {show ?
            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            :
            <EyeOffIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          }
        </div>
      </div>
    </div>
  )
}