import React, { Component, useEffect, useState } from "react";
import {
  ArrowNarrowLeftIcon,
  CheckIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  SearchIcon,
  ThumbUpIcon,
  UserIcon,
} from '@heroicons/react/solid'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import Header from "../../components/Shared/Header";
import { api, get, post } from "../../utils/api";
import { useMutation, useQueries, useQuery } from "react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { Skeleton } from "antd";


export default function User(props) {
  // const { user, token } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});


  useEffect(async () => {
    setLoading(true);
    // // console.log('see me here')
    const res = await get(`/users/profile/${props.match.params.userId}`, true);

    setLoading(false);
    setUser(res.data.user);
    // // console.log({res})
  }, [])

  // const { mutate, isLoading: createLoader } = useMutation(() => startChat(), {
  //   onError: (error, variables, context) => {
  //     toast.error(error.response.data.message);
  //     setLoading(false);
  //   },
  //   onSuccess: (data, variables, context) => {
  //     props.history.push("/messages?username=" + user.username);
  //   },
  // });

  // const startChat = async () => {
  //     setLoading(true);
  //     const res = await get(
  //       `/chats/start-channel/${props.match.params.userId}`,
  //       true
  //     );
  //     // console.log({res});
  //   // } catch (e) {
  //   //   toast.error("An error occurred");
  //   //   return e;
  //   // }
  // };

  // // console.log({user})


  return (
    <>
      <div className="min-h-full bg-gray-100">
        <Header />
        {loading ? <Skeleton count={4} /> : (

          <main className="py-10 w-4/6 mx-auto">
            {/* Page header */}
            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="flex flex-col items-center space-x-5">
                <div className="relative">
                  {user && user.profile_image ? (
                    <img
                      src={user.profile_image}
                      alt=""
                      className="h-32 w-32 rounded-full"
                    />
                  ) : (
                    <div className="text-center h-16 w-16 rounded-full">
                      <i className="fas fa-5x text-white fa-user-circle text-center mx-auto"></i>
                    </div>
                  )}
                </div>

                <h1 className="text-2xl font-bold text-gray-900">@{user.username}</h1>
                <p className="text-sm font-medium text-gray-500">
                  Member since <time className="text-gray-500" datetime={moment(user.createdAt).format("D-MM-YYYY")}>{moment(user.createdAt).format("Do MMMM YYYY")}</time>
                </p>
                <div>
                  {/* <a href={`/messages?username=${user.username}`}
                    type="button"
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                  >
                    Send Private Message
                  </a> */}
                </div>
              </div>
            </div>

          </main>

        )}
      </div>
    </>
  )
}
