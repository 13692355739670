
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import SupportGroup from '../../../views/SupportGroupExplainer/SupportGroup';


export default function Support() {
    return (
        <section className="py-12 md:py-20 lg:py-24">
            <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="text-center md:mx-auto md:max-w-6xl lg:col-span-6">
                    <h2>

                        <span className="mt-1 block text-4xl md:text-5xl font-bold tracking-tight text-center md:text-left">
                            <span className="block text-gray-900">24/7 Support Group</span>
                        </span>
                    </h2>
                </div>


                <SupportGroup />
            </div>
        </section>
    )
}


