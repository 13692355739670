import "./benefit.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../../Shared/Button";
import user_1 from "../../../img/user_1.png";
import user_2 from "../../../img/user_2.png";
import user_3 from "../../../img/user_3.png";
import probability from "../../../img/probability.png";
import game_theory from "../../../img/game_theory.png";
import sam from "../../../img/sam.png";
import laura from "../../../img/laura.png";
import help from "../../../img/help.png";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      benefits: [
        {
          name: "Personalised Learning",
          content:
            "We've made the content so simple, a child can use NGL without supervision and grasp concepts quickly.",
          img: user_1,
        },
        {
          name: "Trusted Content",
          content:
            "The founders of NGL all have strong backgrounds in Mathematics, so you can rest assured that the quality of teaching is second to none.",
          img: user_2,
        },
        {
          name: "Learn it all",
          content:
            "We have a video and worksheet for every topic! You’ll never need to buy another 11+ textbook.",
          img: user_3,
        },
      ],
      investments: [
        {
          name: "Probability & Statistics",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
          cover_image: probability,
          user_image: sam,
        },
        {
          name: "Game Theory",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do",
          cover_image: game_theory,
          user_image: laura,
        },
      ],
    };
  }

  render() {
    const { benefits, investments } = this.state;
    return (
      <main className="mb-0">
        <div className="benefits  py-8">
          <main className="boxed__container">
            <span className="top__header flex">
              <hr className="w-12 mr-2  yellow mt-3" />
              Why NGL?
            </span>
            <p className="main"> Benefits of Joining</p>
            <main className="flex flex-wrap w-full pb-16 pt-8">
              {benefits.map((benefit, index) => (
                <div key={index} className=" w-full md:w-1/3 text-center px-4">
                  <img className="mx-auto h-24" src={benefit.img} />
                  <p className="main__header mt-4">{benefit.name}</p>
                  <p className="mini__header my-2">{benefit.content}</p>
                </div>
              ))}
            </main>
          </main>
        </div>
        {/* <div className="boxed__container  my-4">
          <main className="w-full investment flex flex-wrap my-8">
            <main className="flex flex-wrap w-full md:w-1/2">
              {investments.map((investment, index) => (
                <main key={index} className="w-full md:w-1/2">
                  <div className="mx-2">
                    <img className="h-48 w-full" src={investment.cover_image} />
                    <main className="bottom_bg p-6 rounded-b-lg">
                      <p className="main mb-2">{investment.name}</p>
                      <span className="mini">{investment.content}</span>
                      <main className="flex my-4">
                        <img src={sam} className="h-8" />
                      </main>
                    </main>
                  </div>
                </main>
              ))}
            </main>
            <main className="w-full md:w-1/2">
              <main className="flex">
                <main className="w-1/4"></main>
                <main className=" w-full md:w-3/4">
                  <p className="top__header flex">
                    <hr className="w-12 mr-2  yellow mt-3" />
                    Why are we trusted
                  </p>
                  <p className="right__main">Help your kids learn better</p>
                  <span className="right__content my-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore
                  </span>
                </main>
              </main>
              <img className="h-48  m-6" src={help} />
            </main>
          </main>
        </div> */}
      </main>
    );
  }
}
