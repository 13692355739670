import React, { Component, useState } from "react";
import Header from "../../components/HomePage/Header";
import Footer from "../../components/HomePage/Footer";
import './index.css';
import Plans from "../Subscribe/Plans";
import Button from "../../components/Button";
import { CheckIcon } from "@heroicons/react/solid";
import App from "../../components/Layouts/App";

const texts = [
  'Weekly online group tuition with a max class size of 6 children.',
  'Full access to the NGL Maths Platform',
  'Access to our 24/7 Support Group'
]







const PremiumTuition = () => {


  return (
    <App title="Premium Tuition offer">

      <div className="p-4 bg-gray-50 rounded-md w-full block space-y-6">

        <ol className=" leading-7">
          {texts.map((feature) => (
            <li key={feature} className="flex">
              <CheckIcon className="flex-shrink-0 w-6 h-6 text-blue-500" aria-hidden="true" />
              <span className="ml-3 text-gray-500">{feature}</span>
            </li>
          ))}
        </ol>

        <Button href='/premium-tuition/register'>
          Register Interest
        </Button>
      </div>

    </App>

  )
}

export default PremiumTuition;