import React, { Component, useContext, useEffect, useState } from "react";
import Header from "../../components/Shared/Header";
import { api, get, post, handleError } from "../../utils/api";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Categories from "./Categories";
import Trending from "./Trending";
import Button from "../../components/Shared/Button";
import NewPost from "./NewPost";
import Modal from "../../components/Shared/Modal";
import { toast } from "react-toastify";
import { userServices } from "../../services/userServices";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    padding: "0px",
  },
};

const ForumBase = (props) => {

  const { user } = useContext(AuthContext);
  const history = useHistory();

  const token = userServices.getToken();

  const [loadingTrends, setLoadingTrends] = React.useState(true);
  const [loadingCategories, setLoadingCategories] = React.useState(true);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [categories, setCategories] = useState([]);
  const [trendingData, setTrendingData] = useState([]);


  useEffect(() => {
    fetchTrending();
    fetchCategories();
  }, [])

  useEffect(() => {
    setIsOpen(props.openModal)

  }, [props.openModal])

  function openModal() {
    setIsOpen(true);
    // setTitle("");
    // setBody("");
  }

  function closeModal() {
    setIsOpen(false);
  }


  // const {
  //   isLoading: loadingCategories,
  //   refetch: refetchCatgories,
  // } = useQuery("allForumCategoriesData", () =>
  //   get(`forums/category/fetch-all`, true).then((res) => {
  //     setCategories(res.data.data);
  //   }).catch(e => {
  //     toast.error('Error loading categories')
  //   })
  // );

  // const handlePageClick = (event) => {
  //   setThreadsCurrentPage(event.selected + 1);
  //   setAllThreadsCount(event.selected + 1);
  //   refetchData();
  //   refetchByResponse();
  //   window.scrollTo(0, 0);
  //   history.push({
  //     pathname: "/forum",
  //     search: `?page=${event.selected + 1}`,
  //   });
  // };


  const fetchTrending = () => {
    setLoadingTrends(true);
    get("/forums/fetch-all-threads?type=by_trend&pageSize=5", true).then(
      (res) => {
        setTrendingData(res.data.threads);
      }).catch(e => {
        // toast.error('Error loading trending data')
      })
    setLoadingTrends(false);
  }

  const fetchCategories = () => {
    setLoadingCategories(true);
    get("forums/category/fetch-all", true).then(
      (res) => {
        setCategories(res.data.data);
      }).catch(e => {
        toast.error('Error loading categories')
      })
    setLoadingCategories(false);
  }


  // const {
  //   isLoading: loadingTrends,
  //   error: trendError,
  //   data: trendingData,
  //   refetch: refetchTrends,
  // } = useQuery("trenddata", () =>
  // );



  return (
    <div className="forum_page ">
      <Header />


      <Modal
        open={modalIsOpen}
        setOpen={closeModal}
        style={customStyles}
      >
        <NewPost closeModal={closeModal} categories={categories} />
      </Modal>
      <main className="">


        <div className=" min-h-full flex flex-col">
          <div className="flex-grow w-full mx-auto xl:px-8 lg:flex">

            <div className="flex-1 min-w-0 bg-white xl:flex">
              <div className="xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 bg-white">
                <div className="pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
                  <div className="flex items-center justify-between">
                    {token && Object.keys(user).length > 0 ?

                      <div className="flex-1 space-y-8">
                        <div className="space-y-8 sm:space-y-0 sm:flex sm:justify-between sm:items-center xl:block xl:space-y-8">
                          <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0 h-12 w-12">
                              <img className="h-12 w-12 rounded-full bg-gray-300" src={user.profile_image} alt="" />
                            </div>
                            <div className="space-y-1">
                              <div className="text-sm font-medium text-gray-900">{user.fullname}</div>
                              {user.username && (
                                <Link to='/profile' className="group flex items-center space-x-2.5">
                                  <span className="text-sm text-gray-500 group-hover:text-gray-900 font-medium">@{user.username}</span>
                                </Link>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col sm:flex-row xl:flex-col">
                            <button type="button"
                              onClick={() => openModal()}
                              className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl:w-full">
                              Post New Topic
                            </button>
                            {/* <button onClick={() => history.push('/messages')} type="button" className="mt-3 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 xl:ml-0 xl:mt-3 xl:w-full">
                              Messages
                            </button> */}
                            {/* <button type="button" className="mt-3 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 xl:ml-0 xl:mt-3 xl:w-full">
                                  My Topics
                                </button> */}
                          </div>
                        </div>
                        {/* <div className="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-8 xl:flex-col xl:space-x-0 xl:space-y-6">
                              <div className="flex items-center space-x-2">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                </svg>
                                <span className="text-sm text-gray-500 font-medium">Pro Member</span>
                              </div>
                              <div className="flex items-center space-x-2">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                  <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                                </svg>
                                <span className="text-sm text-gray-500 font-medium">8 Topics</span>
                              </div>
                            </div> */}
                      </div>

                      :
                      <div className="flex-1 space-y-8">
                        <div className="space-y-8 sm:space-y-0 sm:flex sm:justify-between sm:items-center xl:block xl:space-y-8">
                          <div className="flex items-center space-x-3">
                            <div className="space-y-1">
                              <div className="text-sm font-medium text-gray-900 mb-4">Welcome, Guest!</div>
                              <p className="text-sm text-gray-500">Don't miss out as you too can be a part of our engaging conversations. Join us now.</p>
                            </div>
                          </div>
                          <div className="flex flex-col sm:flex-row xl:flex-col">
                            <Button
                              outline
                              href='/login' >
                              Login
                            </Button>
                            <Button type="button" className="mt-2"
                              href='/register'
                            >
                              Create an account
                            </Button>
                          </div>
                        </div>

                      </div>

                    }
                  </div>
                </div>
              </div>

              <div className="bg-white lg:min-w-0 lg:flex-1">
                <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
                  {props.header}
                </div>

                {props.children}

              </div>
            </div>

            <div className=" pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0">

              <Categories categories={categories} loading={loadingCategories} />

              <Trending trendingData={trendingData} loading={loadingTrends} />




            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForumBase;