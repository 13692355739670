import React from 'react';

export default function SectionTitle({ title, subtitle, isCentered = false }) {
  return (
    <div className="bg-white rounded-md my-4 px-4 py-5 border border-gray-200 sm:px-6">
      <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
        <div className={`ml-4 mt-5 ${isCentered && 'text-center'}`}>
          <h3 className="text-lg leading-5 font-medium text-gray-900">{title}</h3>
          <p className={`mt-1 text-sm text-gray-500 ${isCentered && 'text-center'}`}>
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  )
}
