import React from "react";
import Subscribe from "./index";


const MonthlyTutorPlan = () => {


    return (
        <Subscribe showOnlyTutorsPlan />
    )
}

export default MonthlyTutorPlan;