import React, { Component, useState } from "react";
import './index.css';
import { CheckIcon } from "@heroicons/react/outline";


const texts = [
    'Each customer is put in a small support group',
    'You can ask a question on any worksheet, video, or mock paper you’re struck on. (You won’t have any questions however because the platform is so good)'
]

const SupportGroup = () => {


    return (
        <div>

            <div className="px-4 py-12 w-full block">
                <ol className="list leading-7">
                    {texts.map((feature) => (
                        <li key={feature} className="flex">
                            <CheckIcon className="flex-shrink-0 w-6 h-6 text-blue-500" aria-hidden="true" />
                            <span className="ml-3 text-gray-500">{feature}</span>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    )
}

export default SupportGroup;