import React, { Component, useState } from "react";
import Header from "../../components/HomePage/Header";
import Footer from "../../components/HomePage/Footer";
import './index.css';
import Plans from "../Subscribe/Plans";


const MoneyBackGuarantee = () => {


  return (
    <div className="">
      <Header />
      <div className="pt-12" />
      <main className="container ">

        <main className="">
          <main className="mx-auto md:w-max-7xl">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight ">
              100% Money Back Guarantee</h2>
            <p className="page__header my-4"></p>
            <div className="text-base my-4 mb-8 space-y-3">
              <p>
                We are so confident in the effectiveness of our Maths platform that we offer a 100% Money-Back Guarantee if:
              </p>

              <p>a. A child sits the 11+ exam and <strong>doesn’t score at least 80% in the Maths</strong> component of their top choice school.</p>
              <p>or</p>
              <p>b. A child sits the KS2 SATs exam and <strong>doesn’t score at least 80% in the Maths</strong> component. </p>

              <p>To qualify for the Money-Back Guarantee:</p>
              <ol className="list leading-7">
                <li>The student must have completed over 90% of the video lessons and worksheets provided by our platform during the subscription period.</li>
                <li>Each section must be completed in order. After each section is completed, the student must complete an end of section test, to ensure they have grasped the content in the section. Test feedback will be provided.

                </li>
                <li>Students must achieve a minimum score of 90% in every end of section test. They can repeat this end of section test as many times as they want.

                </li>
                <li>Your child can only qualify for the 11+ guarantee or the KS2 SATs guarantee, but not both.</li>
                <li>If you want to be eligible for the KS2 SATs guarantee, then you will need to inform us by email no later than 2 weeks as to when they will sit their KS2 Maths exam.</li>
                <li>The 11+ guarantee only applies to the first exam a child sits for a school. If a school has multiple stages in their selection process, or has a scholarship exam after the main exam, then the guarantee only applies to the first exam they have sat. You will need to inform us by email which school/exam this is, no later than 2 weeks before your child sits the exam. They will also need to be using the platform for a minimum of 3 months before the exam.</li>
                <li>The parent must provide documented proof of their child’s exam results, including a copy of their final exam scorecard or transcript.</li>
                <li>The request for a refund must be submitted within 7 days of receiving the exam results.</li>

                <li>Refunds will be processed within 30 days of receiving the required documentation.</li>
                <li>This guarantee is valid for a one-time use per student and cannot be transferred.</li>
                <li>If you have more than one of your children using the platform, if one of your children qualify for the money-back guarantee then you will be refunded the total amount of money spent on the platform, divided by the number of your children who have used the platform. </li>

              </ol>
            </div>
          </main>

          <main className=" my-8">


            <main className="md:w-max-7xl mx-auto ">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight">
                Our Pricing Plans
              </h2>
              <p className="mt-6 max-w-2xl text-xl text-gray-500">
                Choose an affordable plan that's packed with the best features that works for you.
              </p>

              <Plans />
            </main>

          </main>
        </main>


      </main>
      <Footer />
    </div>
  )
}

export default MoneyBackGuarantee;