import React from "react";
import RNSkeleton from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'

export default function Skeleton(props) {
    return (
        <div className='w-full'>
            <RNSkeleton count={props.count ?? 10} {...props} />
        </div>
    )
}