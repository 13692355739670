import React from 'react';
import "../../../styles/carousel.css"
import gameTheory from '../../../img/animated-videos.png';
import worksheets from '../../../img/worksheets.png';
import exams from '../../../img/exams.png';
import { useState } from "react";
import Modal from "../../Shared/Modal";
import ReactPlayer from "react-player";
import { CheckCircleIcon } from "@heroicons/react/outline";
import Button from "../../Shared/Button";
import Carousel from 'react-bootstrap/Carousel';
import { userServices } from '../../../services/userServices';

const features = [
    {
        title: "Animated videos",
        details: [
            "Our 130+ short animated videos cover every topic in the KS2 and 11+ Maths syllabus.",
            "Each video builds upon the content in previous videos to optimise learning.",
            "The videos are extremely easy for you and your child to follow.",
        ],
        video_url: "https://www.youtube.com/watch?v=RukBZ6NXPyk",
        thumbnail_url: gameTheory,
        isVideo: true,
    },
    {
        title: "Worksheets",
        details: [
            "Once your child has completed a video, we have detailed worksheets to thoroughly test your child straight after, so that they actively recall what they have just learnt.",
            "We have <strong>analysed thousands of past paper questions</strong> for each topic, to ensure the worksheets are up to exam standard.",
            "We have <strong>very detailed solutions</strong> too. We don’t just give the answer, we explain how to arrive at it! ",
        ],
        video_url: "https://www.youtube.com/watch?v=RukBZ6NXPyk",
        thumbnail_url: worksheets,
        isVideo: false,
    },
    {
        title: "Mock Exams",
        details: [
            "Once your child has worked their way through all the videos and worksheets, we have <strong>20+ mock papers</strong> for them to practice.",
            "We analysed hundreds of past exam papers before carefully creating the NGL mock exams, to ensure they’re as indicative of the real exam as possible.",
        ],
        video_url: "https://www.youtube.com/watch?v=RukBZ6NXPyk",
        thumbnail_url: exams,
        isVideo: false,

    },

];

export default function Features() {

    const [showModal, setShowModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);

    const token = userServices.getToken();

    const handleShowVideoModal = (video_url) => {

        setShowModal(true);
        setVideoUrl(video_url);
    };

    return (
        <div className="relative overflow-hidden bg-blue-50">

            <div className="relative py-10 md:py-12 ">
                <div className="sm:text-center md:mx-auto md:max-w-6xl lg:col-span-6 lg:text-left mb-0 lg:mb-10 ">
                    <h2>

                        <span className="mt-1 block text-4xl md:text-5xl font-bold tracking-tight text-center md:text-left">
                            <span className="block text-gray-900">Our Features</span>
                        </span>
                    </h2>
                </div>

                <Carousel interval={null} prevLabel={null} nextLabel={null}>

                    {features.map((item, index) => (
                        <Carousel.Item key={index}>
                            <main className="mx-auto max-w-7xl px-10">
                                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                                    <div className=" flex relative order-last mt-12 sm:mx-auto sm:max-w-lg lg:col-span-6 lg:mx-0 lg:mt-0 lg:flex lg:max-w-none lg:items-center">

                                        <div className="">
                                            {/* <div className="relative mx-auto w-full rounded-lg lg:max-w-md"> */}
                                            <button
                                                type="button"
                                                onClick={() => handleShowVideoModal(item.video_url)}
                                                className={`relative block w-full overflow-hidden rounded-lg ${item.isVideo ? "cursor-pointer" : 'cursor-default'}`}
                                            >
                                                {/* <span className="sr-only">Watch our video to learn more</span> */}
                                                <img
                                                    className="w-full"
                                                    src={item.thumbnail_url}
                                                    alt=""
                                                />
                                                {item.isVideo && (
                                                    <span className="absolute inset-0 flex h-full w-full items-center justify-center" aria-hidden="true">
                                                        <svg className="h-16 w-16 text-blue-500" fill="currentColor" viewBox="0 0 84 84">
                                                            <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
                                                            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                                                        </svg>
                                                    </span>
                                                )}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:text-left">
                                        <div className="mt-6 sm:mx-auto sm:max-w-lg sm:text-center lg:mx-0 lg:text-left">
                                            <h3 className="font-bold text-3xl md:text-4xl text-blue-500 text-center md:text-left">{item.title}</h3>

                                            <ul className="mt-4 text-lg leading-6 list-none space-y-5">
                                                {item.details.map((item, detailIndex) => (
                                                    <li key={detailIndex} className="inline-flex items-center w-full text-base font-medium text-gray-900 ">
                                                        <div className="">
                                                            <CheckCircleIcon className="w-5 h-5 mr-3 text-blue-500" />
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: item,
                                                        }}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="mt-12 w-full text-center md:text-left">
                                                <Button href={token ? '/subscribe' : '/register'}>
                                                    Get Started
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </main>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>

            <Modal
                open={showModal}
                setOpen={setShowModal}
            // style={customStyles}
            >
                <ReactPlayer
                    className="w-full bg-gray-900 h-full"
                    width="100%"
                    height="350px"
                    controls
                    playing
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload'
                            }
                        }
                    }}
                    onContextMenu={e => e.preventDefault()}
                    url={videoUrl}
                // url={activeCourse?.lesson?.video}
                />

                {/* <iframe width="100%" height="315" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe> */}
            </Modal>
        </div>
    )
}
