import React, { Component, useState } from "react";
import "./sidemenu.css";
import { useHistory } from "react-router-dom";
import play from "../../../img/blue-play.svg";
import SideMenuItem from "./menu-item";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { post } from "../../../utils/api";

const CourseSideMenu = (props) => {
  const route = props.route || "courses";

  const handleClick = (lesson, type, pdf) => {
    props.onClickItem(lesson, type, pdf);
  };

  const handleSelected = (e, lesson, type, index, status) => {

    if (status) {
      markCourseComplete(lesson._id);
    } else {
      markCourseUnCompleted(lesson._id);
    }
  };



  const markCourseComplete = async (lessonId) => {
    toast.success("Lesson updated!");
    try {
      await post(`/${route}/lessons/${lessonId}/completed`, {}, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const markCourseUnCompleted = async (lessonId) => {
    toast.success("Lesson updated!");
    try {
      await post(`/${route}/lessons/${lessonId}/uncompleted`, {}, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  return (
    <div className="course__sidemenu">
      {props &&
        props.lessons &&
        props.lessons.length &&
        props.lessons.map((lesson, index) => (
          <main
            key={index}
            // className={props.activeCourseIndex === index ? "bg-red-400" : ""}
            className="w-full"
          >
            <SideMenuItem
              activeLessonId={props.activeLessonId}
              selectCourseItem={(lesson, type, pdf) => {
                handleClick(lesson, type, pdf);
              }}
              markCompleted={(e, lesson, type, index, status) => {
                handleSelected(e, lesson, type, index, status);
              }}
              lesson={lesson}
              {...props}
            />
          </main>
        ))}
    </div>
  );
};
export default CourseSideMenu;
