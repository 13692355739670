import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { post } from "../../../utils/api";


export default function Completed(props) {

    const route = props.route || "courses";
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setCompleted(props.lesson?.status?.completed);
    }, [props.lesson])

    const markCourseComplete = async (lessonId) => {
        try {
            await post(`/${route}/lessons/${lessonId}/completed`, {}, true);
            setCompleted(true)
            toast.success("Lesson updated!");
        } catch (e) {
            toast.error("An error occurred");
            return e;
        }
    };

    const markCourseUnCompleted = async (lessonId) => {
        try {
            await post(`/${route}/lessons/${lessonId}/uncompleted`, {}, true);
            setCompleted(false)
            toast.success("Lesson updated!");
        } catch (e) {
            toast.error("An error occurred");
            return e;
        }
    };

    const handleSelect = async (e, lesson, type) => {
        setLoading(true);

        if (completed) {
            await markCourseUnCompleted(lesson._id)
        } else {
            await markCourseComplete(lesson._id)
        }

        setLoading(false)
        // // console.log({ e, lesson, type })
    }

    return (
        <input
            onChange={(e) => {
                handleSelect(e, props.lesson);
            }}
            type="checkbox"
            checked={completed}
            disabled={loading}
            className="mr-2"
        />
    )
}