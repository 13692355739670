import { get, post } from '../utils/api';
import { userServices } from './userServices';


export const subscriptionServices = {
    checkSubscription,
    checkUsedTrialSubscription,
    getPlans,
    subscribe,
}


async function checkSubscription() {

    const response = await get(`/subscription/my-subscription`, true);

    return response;
}


async function checkUsedTrialSubscription() {

    if (userServices.getToken()) {
        const response = await get(`/subscription/check-used-trial`, true);

        // console.log({ response })
        if (response.data.used_trial) {
            return true
        }
        return false;
    } else {
        return false;
    }
}

async function getPlans() {

    const response = await get(`/subscription/all`);

    return response;
}

async function subscribe(plan) {

    let data = {};

    if (plan.amount == 0) {
        data.isTrial = true
    }

    const response = await post(`/subscription/${plan.priceId}/subscribe`, data, true);

    return response;
}

