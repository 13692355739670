import imagePlaceholder from "../../src/img/image-placeholder.png";
import videoPlaceholder from "../../src/img/video-placeholder.jpg";


export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function redirectTo(url, interval = 1000) {
    setInterval(() => {
        window.location.href = url
    }, interval);
}


export function renderImage(file, type = 'image') {
    if (file) {
        return file;
    } else {
        if (type == 'image') {
            return imagePlaceholder;
        } else {
            return videoPlaceholder;
        }
    }
}

export function passwordCheck(password) {
    return password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*].{7,}$/);

}