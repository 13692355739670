import React, { Component, useEffect, useState } from "react";
import "./sidemenu.css";
import { useHistory } from "react-router-dom";
import play from "../../../img/blue-play.svg";
import Completed from "./Completed";

const CourseSideMenuItem = (props) => {

  const [showTab, setShowTab] = useState(false);
  const [completed, setCompleted] = useState(props.lesson?.status?.completed ?? false);

  useEffect(() => {
    if (props.activeLessonId == props.lesson._id) {
      setShowTab(true);
    }
  }, [])

  const handleClick = (lesson, type, pdf = null) => {
    props.selectCourseItem(lesson, type, pdf);
  };

  const handleSelect = (e, lesson, type) => {
    e.preventDefault();

    setCompleted(!completed)

    const value = e.target.checked;
    if (value) {
      props.markCompleted(e, lesson, type);
    }
  };

  return (
    <div className="course__sidemenu">
      <main className="w-full border-t">
        <main className="">
          <main className="flex w-full cursor-pointer bg-gray-50 px-4 py-6 border-b border-gray-100 items-center" onClick={() => {
            setShowTab(!showTab);
          }}
          >
            <Completed lesson={props.lesson} />
            <span
              className="flex justify-between w-full"
            >
              <span className="title">{props.route == "exams" ? props.lesson.name : props.lesson.title}</span>
              {showTab
                ? <i className='fas fa-angle-up cursor-pointer'></i>
                : <i className='fas fa-angle-down cursor-pointer'></i>
              }
            </span>
          </main>
          {showTab && (

            props.route == "exams" ? (
              props.lesson?.pdfs?.length > 0 ? props.lesson.pdfs.map((item, index) => (
                < div className="pl-6">
                  <main
                    onClick={() => {
                      handleClick(item);
                    }}
                    className="flex mt-2 ml-3"
                  >
                    <p className="assessment my-2 cursor-pointer flex">
                      {/* <input
                        onChange={(e) => {
                          handleSelect(e, item, "lesson");
                        }}
                        type="checkbox"
                        checked={
                          props.lesson &&
                          props.lesson.status &&
                          props.lesson.status.completed
                        }
                        // disabled={
                        //   props.lesson &&
                        //   props.lesson.status &&
                        //   props.lesson.status.completed
                        // }
                        className="mr-2"
                      /> */}
                      <i className="fas fa-file-pdf mr-2"></i> {item.title}
                    </p>
                  </main>
                </div>
              ))
                :
                <p><small>No content available yet</small></p>
            ) :

              < div className="pl-6">
                <main
                  className="flex mt-2 ml-2"
                >
                  <p className="assessment my-2 cursor-pointer flex w-full p-2hover:bg-gray-100">

                    <span
                      className="flex"
                      onClick={() => {
                        handleClick(props.lesson, "lesson");
                      }}
                    >
                      <img className="mr-2" src={play} /> Watch Lesson
                    </span>
                  </p>
                </main>
                {
                  props.lesson.assessments &&
                  props.lesson.assessments.length > 0 &&
                  props.lesson.assessments.map(
                    (assessment, i) =>
                      (
                        <main className="my-2 ml-3" key={i}>
                          <p className="assessment my-2 cursor-pointer flex">
                            <span className="flex">
                              {/* <Completed lesson={assessment} type="assessment" /> */}

                              {/* <input
                              onChange={(e) => {
                                handleSelect(e, assessment, "assessment");
                              }}
                              type="checkbox"
                              checked={
                                // assessment &&
                                // assessment.status &&
                                assessment.status?.completed
                              }
                              // disabled={true}
                              className="mr-2"
                            /> */}
                              <span
                                onClick={() => {
                                  handleClick(assessment, "assessment");
                                }}
                              >
                                <i className="fas fa-subscript mr-2"></i>{" "}
                                {assessment.title}
                              </span>
                            </span>
                          </p>
                        </main>
                      ) || ""
                  )}
                {props.lesson.pdfs?.length > 0 && (
                  props.lesson.pdfs.map((item, index) =>
                    <main
                      key={index}
                      className="flex mt-2 ml-3"
                    >
                      <p className="assessment my-2 cursor-pointer flex">
                        {/* <Completed lesson={item} type="document" /> */}
                        {/* <input
                        onChange={(e) => {
                          handleSelect(e, item, "document");
                        }}
                        type="checkbox"
                        // checked={false}
                        // disabled={true}
                        className="mr-2"
                      /> */}
                        <span
                          onClick={() => {
                            handleClick(props.lesson, "document", item);
                          }}
                        >
                          <i className="fas fa-file-pdf mr-2"></i> {item.title}
                        </span>
                      </p>
                    </main>

                  )
                )}
              </div>
          )}
        </main>
      </main>
    </div >
  );
};
export default CourseSideMenuItem;
