
import React from "react";
import ViewSDKClient from "./ViewSDKClient";

const AdobePDFReader = ({ url, allowDownload = false }) => {
    const loadPDF = () => {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            viewSDKClient.previewFile(
                "pdf-div",
                {
                    defaultViewMode: "FIT_WIDTH",
                    showAnnotationTools: false,
                    showLeftHandPanel: false,
                    showPageControls: false,
                    showDownloadPDF: allowDownload,
                    showPrintPDF: false,
                },
                url
            );
        });
    };
    return (
        <div
            style={{ height: "100vh" }}
            id="pdf-div"
            className="full-window-div border border-gray-100 min-h-full"
            onDocumentLoad={loadPDF()}
        ></div>
    );
};

export default AdobePDFReader;