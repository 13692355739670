import React, { Component, useContext, useState } from "react";
import Header from "../../components/Auth/Header";
import Side from "../../components/Auth/Side";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { StyledProgress } from "../../components/Auth/progress.style";
import Loader from "../../components/Shared/Loader";
import registerImg from "../../img/register.png";
import register_mini from "../../img/register-mini.png";
import "./authentication.css";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../utils/api";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as Yup from "yup";
import { userServices } from "../../services/userServices";
import { Link, useHistory } from "react-router-dom";
import Button from "../../components/Shared/Button";
import PasswordInput from "../../components/PasswordInput";
import { passwordCheck } from "../../utils/helpers";

const header = {
  side: "Already have an account?",
  text: "Login",
  route: "/login",
};

const heard_about_us = [
  'Through a friend',
  'Google',
  'Facebook',
  'Advertisement',
  'Other',
]

const Register = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [sideNav, setSideNav] = useState({
    name: "Funmi O.",
    quote:
      "I love the fact that I am able to watch the videos alongside my child. I learnt a lot too and the quality of the videos are excellent.",
    // backgroundImage: registerImg,
    profile: register_mini,
  });


  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("others");
  const [otherLocation, setOtherLocation] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [childYear, setChildYear] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const { user, setUser, setToken, token } = useContext(AuthContext);

  const handleRegister = (event) => {
    mutate({
      firstname,
      lastname,
      email,
      location: otherLocation ?? location,
      username,
      childYear,
      phone,
      password,
    });
  };

  const onSubmit = (data) => {
    setLoading(true);

    if (!checkPassword()) {
      setLoading(false);

      return false
    } else {
      handleRegister();
    }
  };

  const { mutate, isLoading } = useMutation(
    (payload) => registerUser(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {


        toast.success("Registration Successful!");
        // toast.success("Registration Successful! Please check your email inbox/spam account to activate your account.");

        setToken(data.data.data.user.token);
        setUser(data.data.data.user);

        userServices.logUserIn(data.data.data.user)
        localStorage.setItem("email", email);

        setTimeout(() => { //Mild delay before redirecting so the toast can complete
          setLoading(false);

          window.location.href = "/subscribe?registered=success";
          // window.location.href = "/verify?email=" + email;
        }, 2000);
      },
    }
  );

  const registerUser = async (data) => {
    try {

      if (location == 'others' && otherLocation) {
        data.location = otherLocation;
      }

      return api.post("/auths/signup", data);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const checkPassword = () => {

    // let check = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*].{7,}$/;

    if (!passwordCheck(password)) {
      toast.error('Password requirements were not met. New password must contain: at least 6 letters, one number, and a special character e.g [?£$*?/#]()!', {
        delay: 3000
      });
      return false;
    }

    return true;
  }

  return (
    <div className="authentication min-h-screen flex flex-wrap w-full">
      <main className="w-full hidden xl:block xl:w-1/3">
        <Side data={sideNav} />
      </main>
      <main className="w-full xl:w-2/3">
        <Header data={header} />
        <main className="mt-8 w-4/5 mx-auto xl:px-32">
          <p className="form__header">Let's get started</p>
          <p className="">
            We need a few details to get you all set up.
          </p>
          <main className="unfilled__progress rounded mt-4  mb-18 ">
            <StyledProgress width="50%" />
          </main>
          <form autocomplete="off" onSubmit={handleSubmit(onSubmit)}>
            <main className="flex flex-wrap mt-8">
              <main className="w-full md:w-1/2 my-1 px-2">
                <p className="form__label">
                  First Name<span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  required
                  // {...register("firstName", { required: true })}
                  className={`form__border w-full my-1 ${errors.firstName ? "red__form__border" : ""
                    }`}
                  placeholder="e.g Ademola"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <p className="error__message">
                  {errors.firstName && "First name is required"}
                </p>
              </main>

              <main className="w-full md:w-1/2 my-1 px-2">
                <p className="form__label">
                  Last Name<span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  required
                  // {...register("lastName", { required: true })}
                  className={`form__border w-full my-1 ${errors.lastName ? "red__form__border" : ""
                    }`}
                  placeholder="e.g Yomi"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <p className="error__message">
                  {errors.lastName && "Last name is required"}
                </p>
              </main>

              <main className="w-full md:w-1/2 my-1 px-2">
                <p className="form__label">
                  Username<span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  required
                  // {...register("username", { required: true })}
                  className={`form__border w-full my-1 ${errors.username ? "red__form__border" : ""
                    }`}
                  placeholder="e.g yomite"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <p className="error__message">
                  {errors.username && "Username is required"}
                </p>
              </main>

              <main className="w-full md:w-1/2 my-1 px-2">
                <p className="form__label">
                  Password <span className="text-red-500">*</span>
                </p>

                <PasswordInput
                  type="password"
                  required
                  // {...register("password", { required: true })}
                  className={`form__border w-full my-1 ${errors.password ? "red__form__border" : ""
                    }`}
                  placeholder="e.g ******"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                {/* <input
                  type="password"
                  required
                  // {...register("password", { required: true })}
                  className={`form__border w-full my-1 ${errors.password ? "red__form__border" : ""
                    }`}
                  placeholder="e.g ******"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                /> */}
                <p className="error__message">
                  {errors &&
                    errors.password &&
                    errors.password.type === "required" &&
                    "Password is required"}
                  {errors &&
                    errors.password &&
                    errors.password.type === "minLength" &&
                    "Password must be a minimum of 6 characters"}
                </p>
              </main>

              <main className="w-full my-1 px-2">
                <p className="form__label">
                  Email<span className="text-red-500">*</span>
                </p>
                <input
                  type="email"
                  required
                  // {...register("email", {
                  //   required: true,
                  //   pattern: "/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i",
                  // })}
                  className={`form__border w-full my-1 ${errors.email ? "red__form__border" : ""
                    }`}
                  placeholder="e.g user@email.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p className="error__message">
                  {errors &&
                    errors.email &&
                    errors.email.type === "required" &&
                    "Email is required"}
                </p>
                <p className="error__message">
                  {errors &&
                    errors.email &&
                    errors.email.type === "pattern" &&
                    "Email must be valid"}
                </p>
              </main>

              <main className="w-full my-1 px-2">
                <p className="form__label">
                  Phone Number (optional)
                </p>
                <input

                  className={`form__border w-full my-1 ${errors.phone ? "red__form__border" : ""
                    }`}
                  placeholder="e.g +44 123 456 7879"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <p className="error__message">
                  {errors &&
                    errors.phone &&
                    errors.phone.type === "required" &&
                    "Phone is required"}
                </p>

              </main>

              <main className="w-full md:w-1/2 my-1 px-2">
                <p className="form__label">
                  How did you hear about us? <span className="text-red-500">*</span>
                </p>
                <select
                  value={location}
                  name="location"
                  required
                  // {...register("location", { required: true })}
                  onChange={(e) => setLocation(e.target.value)}
                  // {...register("location", { required: true })}
                  className={`form__border w-full my-1 ${errors.location ? "red__form__border" : ""
                    }`}
                >
                  <option value="">Select an option</option>
                  {heard_about_us.map((sta, i) => {
                    return (
                      <option key={i} value={sta}>
                        {sta}
                      </option>
                    );
                  })}
                </select>
                <p className="error__message">
                  {errors.location && "This field is required"}
                </p>
              </main>
              {location == "Other" && (
                <main className="w-full md:w-1/2 my-1 px-2">
                  <p className="form__label">
                    Other (please specify) <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    required
                    // {...register("location", { required: true })}
                    className={`form__border w-full my-1 ${errors.location ? "red__form__border" : ""
                      }`}
                    onChange={(e) => setOtherLocation(e.target.value)}
                  />
                  {/* <p className="error__message">
                    {errors.location && "This field is required"}
                  </p> */}
                </main>

              )}

              <main className="w-full md:w-1/2 my-1 px-2">
                <p className="form__label">
                  What year is your child in?<span className="text-red-500">*</span>
                </p>
                <select
                  value={childYear}
                  required
                  // {...register("childYear", { required: true })}
                  onChange={(e) => setChildYear(e.target.value)}
                  // {...register("gender", { required: true })}
                  name="childYear"
                  // ref={register({
                  //   required: "select one option",
                  // })}
                  className={`form__border w-full my-1 ${errors.childYear ? "red__form__border" : ""
                    }`}
                >
                  <option value="">Select an option</option>
                  <option>Year 1</option>
                  <option>Year 2</option>
                  <option>Year 3</option>
                  <option>Year 4</option>
                  <option>Year 5</option>
                  <option>Year 6</option>
                  <option>Other</option>
                </select>
                <p className="error__message">
                  {errors.childYear && "This field is required"}
                </p>
              </main>

              <main className="w-full my-1 px-2">
                <Button className="w-full mt-3" disabled={loading}>
                  {loading ? <Loader /> : "Create Profile"}
                </Button>
              </main>
              <p className="mb-10 md:hidden w-full text-center">Already own an account? <Link href="login">Login now.</Link></p>
            </main>
          </form>
        </main>
      </main>
    </div>
  );
};

export default Register;
