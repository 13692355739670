import React from 'react';



export default function SearchResultList(props) {
  const { courses } = props;

  // // console.log({ courses })

  return (
    <div className="inline-block min-w-full py-2 align-middle ">
      <div className="overflow-x-auto  ring-1 ring-black ring-opacity-5 ">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50 text-left">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Section</th>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Video Title</th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {courses && courses.length > 0 ?
              courses.map((item, index) => {

                let lessons = item.lessons;
                return (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"> <a href={`/course/${item.id}`} className="text-black">{item.title}</a></td>

                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {lessons.map((lesson) => (
                        <p className="flex w-full justify-between" key={index}>
                          <a href={`/course/${item.id}?lesson=${lesson.id}`} className="text-black">{lesson.title}</a>
                          <a href={`/course/${item.id}?lesson=${lesson.id}`} className="text-blue-500 hover:text-blue-900">Watch</a>
                        </p>
                      ))}
                    </td>
                  </tr>
                )
              }) : <p>No item available</p>}
          </tbody>
        </table>
        {/* </ul> */}
      </div>
    </div>
  )
}
