import "./discussion.css";
import React, { Component } from "react";
import sam from "../../../img/sam.png";
import { StyledButton } from "../../Shared/Button/button.style";
import { StyledCard } from "./discussion-card-style";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { userServices } from "../../../services/userServices";

const Discussion = (props) => {
  const history = useHistory();
  const handleClick = (route) => {
    history.push(route);
  };
  const token = userServices.getToken();

  return (
    <main className="my-16 pt-16 discussion">
      <main className="boxed__container py-8">
        <div className="flex flex-wrap my-16">
          <main className=" w-full md:w-2/5">
            <span className="top__header flex">
              <hr className="w-12 mr-2  yellow mt-3" />
              Discussion
            </span>
            <p className="title">Discuss with other parents and tutors</p>
            <span className="right__content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore
            </span>
          </main>
          <main className="w-full md:w-3/5">
            <main className="mx-4 flex items-end">
              <img className="h-12 ml-4" src={sam} />
              <StyledCard
                backgroundColor="rgb(0,181,95,0.8)"
                className=" text-white p-6 mx-2 rounded-2xl"
              >
                <p className="card__title">Name Surname</p>
                <p className="card__content my-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam.
                </p>
              </StyledCard>
            </main>
          </main>
          <main className="w-full md:w-3/5 my-16">
            <main className="mx-4 flex items-end">
              <img className="h-12 ml-4" src={sam} />
              <StyledCard
                backgroundColor="rgb(54,85,198,0.8)"
                className=" text-white p-6 mx-2 rounded-2xl"
              >
                <p className="card__title">Name Surname</p>
                <p className="card__content my-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam.
                </p>
              </StyledCard>
            </main>
          </main>
          <main className="w-full md:w-2/5 md:mt-32">
            <main className="mx-4 flex items-end">
              <img className="h-12 ml-4" src={sam} />
              <StyledCard
                backgroundColor="rgb(1,11,29, 0.8)"
                className=" text-white p-6 mx-2 rounded-2xl"
              >
                <p className="card__title">Name Surname</p>
                <p className="card__content my-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam.
                </p>
              </StyledCard>
            </main>
          </main>
          <main className="flex w-full mt-12 mr-6 justify-end">
            {token ? (
              <StyledButton
                onClick={() => {
                  handleClick("/forum");
                }}
                backgroundColor="#286cff"
                width="200px"
              >
                Go to Dashboard
              </StyledButton>
            ) : (
              <StyledButton
                onClick={() => {
                  handleClick("/subscribe");
                }}
                backgroundColor="#286cff"
              >
                Get Started
              </StyledButton>
            )}
          </main>
        </div>
      </main>
    </main>
  );
};

export default withRouter(Discussion);
