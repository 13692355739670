import "./BeforeFooter.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../../Shared/Button";
import footer__icon from "../../../img/knowledge.svg";
import { StyledButton } from "../../Shared/Button/button.style";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { userServices } from "../../../services/userServices";

const BeforeFooter = (props) => {
  const history = useHistory();

  const handleClick = (route) => {
    history.push(route);
  };
  const token = userServices.getToken();

  return (
    <main className=" ">
      <div className="bg-blue-500 py-12 ">
        <main className="flex flex-wrap text-white items-center">
          <main className="w-full md:w-1/2 p-3">
            <h2 class="text-3xl font-bold tracking-tight text-white"> A Maths experience you'll never forget!</h2>
            <p className="my-4">
              If you’ve already scrolled this far, why don't you click the button below and {token ? "join the conversation" : "create an account"}?
            </p>
            {token ? (
              <StyledButton
                onClick={() => {
                  handleClick("/subscribe");
                }}
                backgroundColor="#fff"
                color="#286cff"
                width="250px"
                padding="15px 30px"
              >
                Get Started!
              </StyledButton>
            ) : (
              <StyledButton
                onClick={() => {
                  handleClick("/login");
                }}
                backgroundColor="#fff"
                color="#286cff"
                width="250px"
                padding="15px 30px"
              >
                Create an Account
              </StyledButton>
            )}
          </main>
          <main className="w-full md:w-1/2 justify-center hidden md:flex">
            <img src={footer__icon} className="w-6/12" />
          </main>
        </main>
      </div>
    </main>
  );
};

export default withRouter(BeforeFooter);
