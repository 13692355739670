import React from 'react';
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function GlossaryList({ list }) {
  // // console.log({ list, type: Object.keys(list).sort() })

  const renderLoop = (items) => {
    for (let key in items) {
      // console.log(key)
      return key;
    }
  }

  return (
    <div className="">
      <div className="max-w-7xl mx-auto  px-4  sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {/* // let key = Object.key(lesson);
            // let values = Object.values(lesson); */}
            {Object.keys(list).sort().map((key, index) => {
              let values = list[key];
              return key.trim() != '' && values.length > 0 && (
                <Disclosure as="div" key={index} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">{key}</span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <div className="mt-2 sm:flex sm:justify-between">
                          <div className="block space-y-3 cursor-auto">
                            {values.sort().map((item, i) => (
                              <a key={i} href={`glossary/search/${item.toLowerCase()}`} className="block capitalize text-sm text-gray-500 mr-2">
                                {item}
                              </a>
                            ))}
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )
                  }
                </Disclosure>
              )

            })}

            {/* // return key.trim() != '' && values.length > 0 && (
              //   <Disclosure as="div" key={index} className="pt-6">
              //     {({ open }) => (
              //       <>
              //         <dt className="text-lg">
              //           <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
              //             <span className="font-medium text-gray-900">{key}</span>
              //             <span className="ml-6 h-7 flex items-center">
              //               <ChevronDownIcon
              //                 className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
              //                 aria-hidden="true"
              //               />
              //             </span>
              //           </Disclosure.Button>
              //         </dt>
              //         <Disclosure.Panel as="dd" className="mt-2 pr-12">
              //           <div className="mt-2 sm:flex sm:justify-between">
              //             <div className="sm:flex cursor-auto">
              //               {values.map((item, i) => (
              //                 <a key={i} href={`glossary/search/${item.toLowerCase()}`} className="flex items-center capitalize text-sm text-gray-500 mr-2">
              //                   {item}
              //                 </a>
              //               ))}
              //             </div>
              //           </div>
              //         </Disclosure.Panel>
              //       </>
              //     )
              //     }
              //   </Disclosure>
              // ) */}

          </dl>
        </div>
      </div>
    </div>
  )
}
