import ReactQuill from "react-quill";



export default function(props) {

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        //   ['link', 'image'],
          ['clean']
        ],
      };

    const imageHandler = () => {

    }

    return (
    <ReactQuill
        className={props.className}
        value={props.value}
        modules={modules}
        onChange={props.onChange}
      />

    )
}