import "./auth.module.css";
import React, { Component } from "react";
import star from "../../img/star.svg";

export default class Side extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    const { data } = this.props;
    return (
      <main
        className="bg-gray-800 h-full"
      // style={{ backgroundImage: `url(${data.backgroundImage})` }}
      >
        <main className=" min-h-screen flex items-end pb-8 px-16">
          <main className="p-4 mb-8">
            <p className="text-gray-200">"I love the fact that I am able to watch the videos alongside my child. I learnt a lot too and the quality of the videos are excellent."</p>
            {/* <p className="text-gray-200">"{data.quote}"</p> */}
            <main className="flex items-center my-6">
              {/* <img className="h-16" src={data.profile} /> */}
              <main className="">
                <p className="text-gray-50">Funmi O</p>
                {/* <p className="text-gray-50">{data.name}</p> */}
                {/* <span className="flex">
                  {[...Array(5)].map((index) => (
                    <img key={index} src={star} />
                  ))}
                </span> */}
              </main>
            </main>
          </main>
        </main>
      </main>
    );
  }
}
