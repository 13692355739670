import React from 'react';
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { userServices } from "../../../services/userServices";
import Button from "../../Shared/Button";

const why = [
    {
        title: "Teaching the 'NGL way'",
        details: [
            "Our short, animated videos cover <strong>every topic</strong> in the KS2 and 11+ Maths syllabus. We have made them so simple, <strong>any child can understand it</strong>.",
            "Each video builds upon the content in previous videos to optimise learning.",
            "Many studies show that the best way to retain information is to recall what you have learnt as quickly as possible – this is our active recall strategy.",
            "We are the first platform to <strong>teach and immediately test</strong> everything your child needs to know, ensuring they recall the content they have just learnt.",
        ],
    },
    {
        title: "Maximise your child’s learning potential",
        details: [
            "We don’t believe a child’s learning should be limited by their age or year group.",
            "We assume that your child doesn’t have any knowledge, and we teach <strong>every topic</strong> in detail from first principles with our videos and worksheets.",
            "With this approach, we will build a solid mathematical foundation for your children and have them performing far above their peer group. We <strong>guarantee it</strong>.",
        ],
    },
    {
        title: "Light-speed progress",
        details: [
            "If you were to use NGL every-day, your child would know the entire KS2/11+ Maths syllabus within <strong>6 months</strong>.",
            "We have had Year 4 students master algebra in a matter of 5 weeks!",
        ],
    },
];

export default function WhyTheBest() {
    const [activeWhy, setActiveWhy] = useState(why[0]);
    const token = userServices.getToken();


    return (
        <div className="mx-auto max-w-7xl py-8 px-4 sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg bg-blue-500 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
                <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-15 xl:px-20">
                    <div className="lg:self-center">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl mb-10">
                            <span className="block">Why we are the best</span>
                            {/* <span className="block text-xl">Maximising learning potential</span> */}
                        </h2>
                        <ul className="mt-4 text-lg leading-6 list-none space-y-5">
                            {why.map((item, index) => (
                                <li key={index} className={`${activeWhy.title == item.title ? 'text-green-100 font-bold' : 'text-blue-200'} inline-flex items-center cursor-pointer w-full`} onClick={() => setActiveWhy(why[index])}>
                                    <div className="flex">
                                        <CheckCircleIcon className="w-5 h-5 mr-3" />
                                    </div>
                                    {item.title}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {/* aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1 */}
                <div className="">
                    <div className="bg-gray-50 text-left rounded-md w-full h-full p-12">
                        <div className="text-gray-600">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 flex items-center">  {activeWhy.title}</h2>
                            <ul className="mt-4 text-lg leading-6 list-none space-y-5">
                                {activeWhy.details.map((why, whyIndex) => (
                                    <li key={whyIndex} className="inline-flex items-center w-full ">
                                        <div className="">
                                            <CheckCircleIcon className="w-5 h-5 mr-3 text-blue-500" />
                                        </div>
                                        <div dangerouslySetInnerHTML={{
                                            __html: why,
                                        }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="w-full mt-5 mx-auto text-center">
                            <Button href={token ? "/subscribe" : "/register"}>
                                Give us a try!
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
