import React from 'react';

const Card = () => {
    return (
        <div className="relative group">

            <div className="h-32 bg-gray-200 rounded-tr rounded-tl animate-pulse"></div>

            <div className="p-5">
                <div className="h-6 rounded-sm bg-gray-200 animate-pulse mb-4"></div>

                <div className="grid grid-cols-4 gap-1">
                    <div className="col-span-3 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                    <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>

                    <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                    <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>

                    <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                    <div className="col-span-3 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                    <div className="col-span-2 h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                    <div className="h-4 rounded-sm bg-gray-200 animate-pulse"></div>
                </div>

            </div>

        </div>
    )
}


export default function CardsSkeleton(props) {

    return (
        <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
            <Card />
            <Card />
            <Card />
            <Card />
        </div>
    )
}
