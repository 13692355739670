import React, { Component, useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../img/logo-w.png";
import { AuthContext } from '../../../contexts/AuthContext';

import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import Button from "../../Shared/Button";
import { api, post } from "../../../utils/api";
// import ActivationNotice from "./ActivationNotice";
import { userServices } from "../../../services/userServices";
import Avatar from "../../Avatar";
// import "./header.module.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const routes = [
  { name: "About Us", route: "/about" },
  { name: "FAQs", route: "/faqs" },
  { name: "Contact Us", route: "/contact" },
];

export const logged_in_routes = [
  { name: "About Us", route: "/about" },
  { name: "Videos & Worksheets", route: "/theory" },
  // { name: "Challenge & Scholarship", route: "/challenge" },
  { name: "Glossary", route: "/glossary" },
  { name: "Exam Papers", route: "/exams" },
  // { name: "Forum", route: "/forum" },
  { name: "FAQs", route: "/faqs" },
  { name: "Contact Us", route: "/contact" },
];

export const auth_routes = [
  // { name: "Messages", route: "/messages" },
  { name: "Settings", route: "/profile" },
  // { name: "Settings", route: "/account" },
  { name: "Logout", route: "/logout" },
];

export const guest_routes = [
  { name: "Login", route: "/login" },
  { name: "Register", route: "/register" },
];

export default function Header() {

  const history = useHistory();
  const [currentPage, setCurrentPage] = useState("");
  const { user, setUser, setToken } = useContext(AuthContext);

  const token = userServices.getToken();

  const _routes = token ? logged_in_routes : routes;


  useEffect(() => {
    const _user = localStorage.getItem("maths-app-user");

    if (_user) {
      setUser(JSON.parse(_user))
    }

  }, [])

  const logOut = async () => {
    // await setInactive();
    await userServices.logOut();
  };


  function handleClick(route) {
    history.push(route);
  }



  return (
    <>
      {/* <ActivationNotice /> */}
      <Disclosure as="nav" className="bg-blue-500 py-3">
        {({ open }) => (
          <>
            <div className="w-full mx-auto px-2 sm:px-4 lg:px-2">
              <div className="flex justify-between h-16">
                <div className="flex px-2 lg:px-0">
                  <div className="flex-shrink-0 flex items-center">
                    <Link to="/">
                      <img
                        className="block lg:hidden h-12 w-auto"
                        src={logo}
                        alt="NGL"
                      />
                      <img
                        className="hidden lg:block h-12 w-auto"
                        src={logo}
                        alt="NGL"
                      />
                    </Link>
                  </div>
                  <div className="md:flex lg:ml-6 hidden lg:flex  items-center">
                    {_routes.map((item) => (
                      <Link
                        key={item.name}
                        to={item.route}
                        className={classNames(
                          currentPage ? '' : '',
                          'text-white hover:text-white px-3 py-2 hover:bg-blue-500 inline-flex items-center rounded-md text-sm font-medium')}
                        aria-current={currentPage ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="hidden lg:ml-4 lg:flex lg:items-center">
                  {token && Object.keys(user).length > 0 ? (
                    <>
                      {/* <button
                        type="button"
                        className="flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}

                      <Menu as="div" className="ml-4 relative flex-shrink-0">
                        <div>
                          <Menu.Button className=" rounded-full flex text-sm ">
                            <span className="sr-only">Open user menu</span>
                            <Avatar user={user} />
                            {/* {user.profile_image ?
                              <img
                                className="h-8 w-8 rounded-full"
                                src={user.profile_image}
                                alt=""
                              />
                              :
                              <i className="fas fa-3x text-black fa-user-circle text-center mx-auto"></i>
                            } */}
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {auth_routes.map((item, index) =>
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <Link
                                    to={item.route}
                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            )}
                            {/* <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/account"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item> */}
                            {/* <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  onClick={logOut}
                                  className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                >
                                  Sign out
                                </a>
                              )}
                            </Menu.Item> */}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </>
                  ) : (
                    <span className="invisible lg:visible flex">
                      <Button
                        outline
                        onClick={() => {
                          handleClick("/login");
                        }}
                      >
                        Login
                      </Button>
                      <Button
                        onClick={() => {
                          handleClick("/register");
                        }}
                      >
                        Register
                      </Button>
                    </span>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {routes.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.route}
                    className={classNames(
                      // currentPage ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      // 'block px-3 py-2 rounded-md text-base font-medium'

                      currentPage
                        ? "bg-blue-500 text-white"
                        : "text-white hover:bg-blue-500 hover:text-white",
                      "block px-3 py-2 rounded-md text-sm font-medium"
                    )}
                    aria-current={currentPage ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
                {token
                  ? auth_routes.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.route}
                      className={classNames(
                        currentPage
                          ? "bg-blue-500 text-white"
                          : "text-white hover:bg-blue-500 hover:text-white",
                        "block px-3 py-2 rounded-md text-sm font-medium"
                      )}
                      aria-current={currentPage ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))
                  : guest_routes.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.route}
                      className={classNames(
                        currentPage
                          ? "bg-blue-500 text-white"
                          : "text-gray-500 hover:bg-blue-500 hover:text-white",
                        "block px-3 py-2 rounded-md text-sm font-medium"
                      )}
                      aria-current={currentPage ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}
