import React, { Component, useState } from "react";

import { CheckIcon } from "@heroicons/react/solid";
import Button from "../../Button";

const texts = [
    'Weekly online group tuition with a max class size of 6 children.',
    'Full access to the NGL Maths Platform',
    'Access to our 24/7 Support Group'
]







const PremiumTuition = () => {


    return (
        <section className="py-12 md:py-20 bg-blue-50 lg:py-24">
            <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="text-center md:mx-auto md:max-w-6xl lg:col-span-6">
                    <h2>

                        <span className="mt-1 block text-4xl md:text-5xl font-bold tracking-tight text-center md:text-left">
                            <span className="block text-gray-900">Premium Tuition Offer</span>
                        </span>
                    </h2>
                </div>


                <div className="p-4 py-12 w-full block space-y-6">

                    <ol className="list leading-7">
                        {texts.map((feature) => (
                            <li key={feature} className="flex">
                                <CheckIcon className="flex-shrink-0 w-6 h-6 text-blue-500" aria-hidden="true" />
                                <span className="ml-3 text-gray-500">{feature}</span>
                            </li>
                        ))}
                    </ol>

                    <div className="list">
                        <a className={` cursor-pointer inline-flex justify-center font-medium rounded-md bg-blue-500 text-white px-6 py-3  mt-5 `}
                            href="#tally-open=wz7r81&tally-layout=modal&tally-emoji-text=👋&tally-emoji-animation=wave"
                        // href='/premium-tuition/register'
                        >
                            Register Interest
                        </a>
                    </div>
                </div>


            </div>
        </section>


    )
}

export default PremiumTuition;