import React, { useEffect } from 'react';
import Header from "../../components/Shared/Header";
import SuccessAlert from '../../components/SuccessAlert';
import Plans from './Plans';
import Button from '../../components/Shared/Button';
import SectionTitle from '../../components/SectionTitle';


export default function Success() {

    useEffect(() => {

        const URLParams = new URLSearchParams(window.location.search);

        const sessionID = URLParams.get('session_id');

        if (!sessionID) {
            window.location.href = "/profile";
        }

    }, []);

    return (
        <>
            <Header />

            <div className="max-w-7xl mx-auto py-10 px-4 bg-white sm:px-6 lg:px-8">
                <SuccessAlert title="Subscription successful!" />


                <SectionTitle title="Your subscription is active" subtitle="You now have continued access to our videos and worksheets." />


                {/* <Button>Go to account profile</Button> */}

            </div>
        </>
    )
}
