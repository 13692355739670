import { Link, useHistory } from "react-router-dom";

export default function GuestComment() {

    const currentURL = window.location.pathname;

    return (
      <div className="bg-blue-50 rounded-md w-full">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
          Join the conversation</h2>
          <p className="mt-3 text-gray-800">
           You must be logged in to comment on this thread
          </p>          
        <div className="mt-8 flex">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/login"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
            >
              Login
            </Link>
          </div>
          <div className="ml-3 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
            <Link
              to="/register"
              className="inline-flex "
            >
              Create a FREE account
            </Link>
          </div>
        </div>
      </div>
    </div>
    )
  }
  