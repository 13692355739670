import React, { Component, useState, useContext, useEffect } from "react";
import Header from "../../components/Shared/Header";
import { StyledCard } from "./card.style";
import "./index.css";
import { withRouter } from "react-router-dom";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post } from "../../utils/api";
import Skeleton from "../../components/Skeleton";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Button from "../../components/Shared/Button";
import { toast } from "react-toastify";
import { redirectTo } from "../../utils/helpers";
import Dashboard from "../../components/Layouts/Dashboard";
import CardsSkeleton from "../../components/CardsSkeleton";
import ChallengeCard from './ChallengeCard';
import SectionTitle from "../../components/SectionTitle";
import CommingSoon from "../../components/ComingSoon";


const Challenges = (props) => {
  const history = useHistory();

  const { user, token } = useContext(AuthContext);
  const [challenges, setChallenges] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchchallenges();
  }, []);

  const fetchchallenges = () => {
    setLoading(true)

    try {
      const response = get("/challenges/all", true);

      if (response?.data?.courses) {
        setChallenges(response.data.courses);
      }
    } catch (e) {

    }
  }

  const handleClick = (route) => {
    history.push(route);
  };

  return <CommingSoon />

  return (
    <Dashboard header={<Header />}>


      <div className="py-2" />

      <SectionTitle title="Challenge &amp; Scholarships" subtitle={<>Get challenged. Test your grit. You sure can scale through these tasks 💪🏻</>} />

      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="flex items-center justify-between space-x-4">
          <h2 className="text-lg font-medium text-gray-900">Select a Challenge</h2>
        </div>

        {loading ? <CardsSkeleton /> : (
          <ChallengeCard challenges={challenges} />
        )}
      </div>
    </Dashboard>
  );
};

export default withRouter(Challenges);
