import React from 'react';
import Header from "../../components/HomePage/Header";
import SuccessAlert from '../../components/SuccessAlert';
import Plans from './Plans';


export default function Subscribe({ showOnlyTutorsPlan = false }) {

  const URLParams = new URLSearchParams(window.location.search);

  const isRegisteredUser = URLParams.get('registered');

  return (
    <>
      <Header />

      <div className="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
        {isRegisteredUser && (
          <SuccessAlert title="Registration successful!" message="Please select a subscription plan to continue." />
        )}

        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
          Our Pricing Plans
        </h2>
        <p className="mt-6 max-w-2xl text-xl text-gray-500">
          Choose an affordable plan that's packed with the best features that works for you.
        </p>

        <Plans showOnlyTutorPlan={showOnlyTutorsPlan} />

      </div>
    </>
  )
}
