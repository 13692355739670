import React, { useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.css";
import SideMenu from "../../components/Course/SideMenu";
import Quiz from "../../components/Course/Quiz";
import Document from "../../components/Course/Document";
import ReactPlayer from "react-player";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { RWebShare } from "react-web-share";
import { ShareIcon } from "@heroicons/react/outline";
import { toast } from "react-toastify";
import PageError from "../../components/PageError";

const Dashboard = (props) => {
  const [isAssessment, setIsAssessment] = useState(false);
  const [course, setCourse] = useState([]);
  const [activeCourseIndex, setActiveCourseindex] = useState(0);
  const [activeCourse, setActiveCourse] = useState({});
  const [hasFetched, setHasFetched] = useState(false);
  const [lessonType, setLessonType] = useState("video");

  const {
    isLoading: loadingData,
    error,
    data,
    refetch,
  } = useQuery("courseData", () =>
    get(`challenges/${props.match.params.challengeid}`, true).then((res) => {
      setCourse(res.data.course);
      if (Object.keys(activeCourse).length === 0) {
        setActiveCourse(res.data.course.lessons[0]);
        setHasFetched(false);
      }
    }).catch(e => {
      toast.error('An error occured. Please try again.')
    })
  );

  const handleLessonChange = (lesson, type, index) => {
    setActiveCourse(lesson);
    setLessonType(type);
    if (type === "assessment") {
      setIsAssessment(true);
    } else {
      setIsAssessment(false);
    }
  };

  return (
    <div className="course__page ">
      <Header />
      <div className="py-" />
      {/* {loadingData ? (
        <main className="mt-1">
          <Skeleton count={5} />
        </main>
      ) : (
        course?.lessons?.length > 0 ?
          <main className="py-4 w-full ">
            <main className=" my-4 flex flex-wrap boxed__container top__wrapper ">
              <div className="flex w-full bg-black p-6 justify-between header__text">
                <main>{course.title}</main>
                <main className="flex">

                </main>
              </div>
              <main className="w-full md:w-1/5 border">
                <p className="cursor-pointer side__text my-3 mx-2">
                  Challenge Content
                </p>
                <SideMenu
                  activeCourseIndex={activeCourseIndex}
                  lessons={course?.lessons}
                  route="courses"
                  onClickItem={(lesson, type) => {
                    handleLessonChange(lesson, type);
                  }}
                />
              </main>
              <main className="w-full md:w-4/5 border">
                <main>
                  {lessonType === "assessment" ? (
                    <Quiz route="courses" assessment={activeCourse} />
                  ) : lessonType === "document" ? (
                    <Document route="document" document={activeCourse} />
                  ) : (
                    <>
                      <ReactPlayer
                        className="w-full"
                        width="100%"
                        height="600px"
                        controls
                        playing
                        config={{
                          file: {
                            attributes: {
                              controlsList: 'nodownload'
                            }
                          }
                        }}
                        onContextMenu={e => e.preventDefault()}
                        url={activeCourse?.video}
                      />
                    </>

                  )}
                </main>
              </main>
            </main>
          </main>
          :
          <div className="w-5/6 mx-auto">
            <PageError title="Oops!" subtitle={<>No content yet for this category</>} />
          </div>
      )} */}
    </div>
  );
};

export default Dashboard;
