import ScreenLoader from "../components/ScreenLoader";
import React, { useState, useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "./Shared/Header";
import { userServices } from "../services/userServices";
import { subscriptionServices } from "../services/subscriptionServices";

const ProtectedRoute = ({ component: Component, ...rest }) => {

  const history = useHistory();

  const token = userServices.getToken();
  const user = userServices.getUser();
  const [loading, setLoading] = useState(false);

  const paidRoutes = ['/challenge', '/theory', '/glossary', '/exams'];


  useEffect(async () => {
    setLoading(true);
    if (!token || !user) {
      userServices.logOut(true);

    } else if (token && user && paidRoutes.includes(rest.path)) {
      await checkActivated();
      await checkSubscription();
    } else {
      setLoading(false);
    }
  }, [])

  const checkActivated = async () => {
    if ((await userServices.checkIsVerified()) == false) {
      window.location.href = "/verify";
    }
  }

  const checkSubscription = async () => {
    setLoading(true);
    const res = await subscriptionServices.checkSubscription()
    const subscription = res.data?.subscription;

    if (res.statusCode == 401) {
      userServices.logOut(true);
    }

    if (res.status == 200 && !subscription.past_due && subscription.is_active) {
      setLoading(false);
    } else {
      window.location.href = '/subscribe/?sub-required=true';
    }
    setLoading(false);

  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return loading ? <><Header /><ScreenLoader /></> : <Component {...rest} {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
