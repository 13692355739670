
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

const testimonies = [
    {
        person: "Penny",
        testimony: "My daughter is in Year 4 but she can now do any algebra question – I promise you. It’s amazing.",
    },
    {
        person: "Funmi",
        testimony: "I love the fact that I am able to watch the videos alongside my child. I learnt a lot too and the quality of the videos are excellent.",
    },
    {
        person: "Amanda",
        testimony: "The course really worked for my son! He’s just finished yr 5 and started the course aged 9, so hadn’t any previous algebra teaching. I confess, I left him to do the course on his own while I worked, fortunately he’s quite focused and keen to learn.",
    },
    {
        person: "Anon",
        testimony: "I really enjoyed the videos. I say this because the child is taught a topic then given practice questions. Whereas most platforms I have come across, they simply give a child questions without teaching them how to do the questions.",
    },
    {
        person: "Anon",
        testimony: "Your videos will fill a gap in the market. All videos I have come across relate to questions and answers but they are not teaching the basics.",
    },
    {
        person: "Munayah",
        testimony: "I just wanted to say a huge thank you for all your help – you are very much a part of her amazing success story. I hope you go on ahead with your product, which I believe will be a great arsenal for kids to excel at the 11 plus!",
    },
    {
        person: "Nneoma",
        testimony: "My son really found the videos enjoyable and easy to follow. We still revert back to them as refreshers.",
    },
    {
        person: "Julian",
        testimony: "The quality of the videos are very good and as someone personally involved in technology start ups I’m always impressed by entrepreneurial innovation.",
    },
    {
        person: "Anon",
        testimony: "Just wanted to say your course was awesome and my daughter went on to smash all exams she took, ultimately gaining admission to her first choice school.",
    }

];

export default function Testimonials() {
    return (
        <section className="overflow-hidden bg-gray-50 py-12 md:py-20 lg:py-24">
            <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="text-center md:mx-auto md:max-w-6xl lg:col-span-6">
                    <h2>

                        <span className="mt-1 block text-3xl font-bold tracking-tight ">
                            <span className="block text-gray-900">Testimonials</span>
                        </span>
                    </h2>
                </div>


                <Carousel interval={null} prevLabel={null} nextLabel={null}>

                    {testimonies.map((item, index) => (
                        <Carousel.Item key={index}>
                            <div className="relative my-10">
                                <blockquote className="mt-10">
                                    <div className="mx-auto max-w-3xl text-center text-2xl font-medium leading-9 text-gray-900">
                                        <p className="px-12 md:px-0">
                                            "{item.testimony}"
                                        </p>
                                    </div>
                                    <footer className="mt-8">
                                        <div className="md:flex md:items-center md:justify-center">
                                            <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                                                <div className="text-base font-bold text-gray-900">- {item.person}</div>

                                            </div>
                                        </div>
                                    </footer>
                                </blockquote>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </section>
    )
}


