import React, { Component, Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import "./index.css";
import sam from "../../../img/sam.png";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon, ChatAlt2Icon, ChatAltIcon, ChevronRightIcon, CodeIcon, DotsVerticalIcon, EyeIcon, FlagIcon, ShareIcon, StarIcon, ThumbUpIcon } from "@heroicons/react/outline";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ThreadCard = (props) => {
  const history = useHistory();

  const handleClick = (e, route) => {
    e.preventDefault();

    history.push(route);
  };

  const { thread } = props;

  return (
    <li
      key={thread.repo}
      className="relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6 cursor-pointer	"
      onClick={(e) => {
        handleClick(e, `/post/${thread.id}`);
      }}
    >
      <div className="flex items-center justify-between space-x-4">
        <div className="min-w-0 space-y-2">
          <div className="flex items-center space-x-3">
            <span
              className={classNames(
                thread.active ? 'bg-green-100' : 'bg-gray-100',
                'h-4 w-4 rounded-full flex items-center justify-center'
              )}
              aria-hidden="true"
            >
              <span
                className={classNames(
                  thread.active ? 'bg-green-400' : 'bg-gray-400',
                  'h-2 w-2 rounded-full'
                )}
              />
            </span>

            <span className="block">
              <h2 className=" font-medium">
                <a href={`/post/${thread.id}`}
                  onClick={(e) => {
                    handleClick(e, `/post/${thread.id}`);
                  }}
                  className="flex"
                >
                  <span className="mr-2" aria-hidden="true">{thread.title} {' '}</span>
                  {thread.category && (
                    <span className=" items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">{thread.category.name}</span>
                  )}
                </a>
              </h2>
            </span>
          </div>

          <span className="relative group flex items-center space-x-2.5">
            <img className="h-6 w-6 rounded-full" src={thread.user?.profile_image} alt="" />
            <span className="text-sm text-gray-500 group-hover:text-gray-900 font-medium truncate">
              {thread.user?.fullname}
            </span>
          </span>
        </div>
        <div className="sm:hidden">
          <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        {/* Repo meta info */}
        <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
          <p className="flex items-center space-x-4">
            <button
              type="button"
              className="relative bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {/* <span className="sr-only">
              {thread.starred ? 'Add to favorites' : 'Remove from favorites'}
            </span> */}
              <ChevronRightIcon
                className={classNames(
                  thread.starred
                    ? 'text-yellow-300 hover:text-yellow-400'
                    : 'text-gray-300 hover:text-gray-400',
                  'h-5 w-5'
                )}
                aria-hidden="true"
              />
            </button>
          </p>
          <p className="flex text-gray-400 text-xs space-x-2">
            <span className="flex"><ChatAlt2Icon className="w-3 h-3 mr-1 flex" /> {thread.comments_count ?? 0}</span>
            <span aria-hidden="true">&middot;</span>
            <span>Last comment {moment(thread.last_comment_time).fromNow()}</span>
          </p>
        </div>
      </div>
    </li>
  );
};
export default withRouter(ThreadCard);
