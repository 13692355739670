import React, { Component, Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import "./index.css";
import sam from "../../../img/sam.png";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon, ChatAlt2Icon, ChatAltIcon, ChevronRightIcon, CodeIcon, DotsVerticalIcon, EyeIcon, FlagIcon, ShareIcon, StarIcon, ThumbUpIcon } from "@heroicons/react/outline";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ThreadCard = (props) => {
  const history = useHistory();
  const handleClick = (route) => {
    history.push(route);
  };
  const { post } = props;
  // console.log({post});
  return (
    <li key={post.id} className="flex py-4 space-x-3">
      <div className="flex-shrink-0">
        <img className="h-8 w-8 rounded-full" src={post.user.profile_image} alt="" />
      </div>
      <div className="min-w-0 flex-1">
        <p className="text-sm text-gray-800">{post.title}</p>
        <div className="mt-2 flex">
          <span className="inline-flex items-center text-sm">
            <button
              type="button"
              className="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
            >
              <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
              <span className="font-medium text-gray-900">{post.comments_count}</span>
            </button>
          </span>
        </div>
      </div>
    </li>
  )
};

export default withRouter(ThreadCard);
