import "./auth.module.css";
import { Link, NavLink } from "react-router-dom";
import React, { Component, useContext } from "react";
import logo from "../../img/logo.png";
import { userServices } from "../../services/userServices";
import Button from "../Shared/Button";

const Header = (props) => {
  const token = userServices.getToken();
  const { data } = props;

  const handleLogout = async () => {
    await userServices.logOut();
  }

  return (
    <main className="auth__header">
      <main className="flex w-full md:pr-32 p-8 items-center justify-between">
        <div>
          <Link to="/">
            <img
              className="h-10"
              src={logo}
              alt="NGL"
            />
          </Link>
        </div>
        {!token ? (
          <div className="hidden md:flex items-center">
            {/* <p className="text mr-4">{data.side}</p> */}
            <Button href={data.route}>
              {data.text}
            </Button>
          </div>
        ) : (
          <button className="nav__button" onClick={handleLogout}>
            Log Out
          </button>
        )
        }
      </main>
    </main>
  );
};
export default Header;
