import { Upload } from 'antd';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { post, token } from '../utils/api';

const { Dragger } = Upload;

export default function Uploader(props) {

  const [fileName, setFileName] = useState('');
  const [fileExt, setFileExt] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [signedRequest, setSignedRequest] = useState('');
  const [file, setFile] = useState('');
  const [data, setData] = useState({});


  const initialUpload = async ({ name, type }) => {
    try {
      const response = await post(`/services/file/presign-url/?file_name=${name}&file_type=${type}`, {}, true)

      if (response.status) {
        let { signedRequest, url } = response.data.data;

        setSignedRequest(signedRequest);
        // setFile(meta.previewUrl);

        // // console.log({signedRequest});
        // awsUpload({
        //   route:signedRequest,
        //   url,
        // });  
      }
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };


  const awsUpload = async ({ route, url }) => {
    try {
      const xhr = new XMLHttpRequest();

      xhr.open("PUT", route);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            toast('Uploaded successfully')
          } else {
            toast.error("An error occurred! Could not upload file.");
          }
        }
      };
      xhr.send(file);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const presignURL = `/services/file/presign-url/?file_name=${fileName}&file_type=${fileExt}`;

  const _props = {
    name: 'file',
    multiple: props.multiple ? true : false,
    accepts: props.accepts,
    action: signedRequest,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    data,
    beforeUpload(file) {
      return Promise(resolve => {
        // console.log({file})
        initialUpload(file);

        // console.log({resolve})
      })
      // let fileURL = URL.createObjectURL(file);
      // setFileURL(fileURL);

      // // console.log(initialUpload(file.name, file.type))
      // // console.log({fileURL});


      // initialUpload()
    },
    onChange(info) {
      const { status } = info.file;

      // console.log({info})
      props.uploadFiles(info.fileList);
      // if (status !== 'uploading') {
      //   // // console.log(info.file, info.fileList);
      // }
      // if (status === 'done') {
      //   if(props.onUploadComplete) {
      //     props.onUploadComplete(info.file);
      //   }
      //   toast(`${info.file.name} uploaded successfully.`);
      // } else if (status === 'error') {
      //   toast.error(`${info.file.name} upload failed.`);
      // }
    },
    onDrop(e) {
      // console.log({e})
      // // console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove(e) {
      if (props.deleteUrl) {
        // del(props.deleteUrl, {url: e.response.url}).then((res) =>
        //   // console.log(res)
        // ).catch((err) => // console.log({err}));
      }
    }
  };
  return (
    <Dragger {..._props}>
      <p className="ant-upload-drag-icon">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        {/* Support for a single or bulk upload. Strictly prohibit from uploading company data or other
      band files */}
      </p>
    </Dragger>
  )
}