import React, { Component, useState, useContext } from "react";
import Header from "../../components/Shared/Header";
// import "./index.css";
import Sidebar from "./Sidebar";

import { toast } from "react-toastify";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import * as moment from "moment";
import { AuthContext } from "../../contexts/AuthContext";

const Dashboard = (props) => {
  const { user, setUser } = useContext(AuthContext);

  return (
    <div className="account__page ">
      <Header />

      <main className="">
        <main className=" my-8 max-w-7xl mx-auto  flex flex-wrap boxed__container top__wrapper ">
          <main className="w-full md:w-1/5 border">
            <Sidebar activeMenu="profile" />
          </main>
          <main className="w-full md:w-4/5 border view__page">
            <main className="pt-8 w-full mx-auto md:w-3/5">
              <main className="flex justify-center mt-12">
                {user && user.profile_image ? (
                  <img
                    src={user.profile_image}
                    alt="User Profile Picture"
                    className="rounded-full mx-auto h-24 w-24 "
                  />
                ) : (
                  <p className="text-center">
                    <i className="fas fa-5x text-black fa-user-circle text-center mx-auto"></i>
                  </p>
                )}
              </main>
              <p className=" pt-8 text-center font-bold capitalize">
                {user.fullname}
              </p>
              <p className="user__subheader text-center py-2">
                Username: {user.username}
              </p>
              <main className=" text-center  my-4">
                <p>
                  Email Address: {" "}
                  <span className="ml-2 blue__text">{user.email ?? 'Not set'}</span>
                </p>
                <p>
                  Date Registered
                  <span className="ml-2 blue__text">
                    {moment(user.createdAt).format("Do MMMM YYYY")}
                  </span>
                </p>
              </main>
            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Dashboard;
