import React, { Component, useState } from "react";
import Header from "../../components/HomePage/Header";
import Footer from "../../components/HomePage/Footer";
import './index.css';
import Plans from "../Subscribe/Plans";
import { CheckIcon } from "@heroicons/react/outline";
import App from "../../components/Layouts/App";
import SupportGroup from "./SupportGroup";

const texts = [
  'Each customer is put in a small support group',
  'You can ask a question on any worksheet, video, or mock paper you’re struck on. (You won’t have any questions however because the platform is so good)'
]

const SupportGroupExplainer = () => {


  return (
    <App>

      <SupportGroup />
    </App>
  )
}

export default SupportGroupExplainer;