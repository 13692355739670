import React, { useEffect, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.css";
import SideMenu from "../../components/Course/SideMenu";
import Quiz from "../../components/Course/Quiz";
import Document from "../../components/Course/Document";
import Overview from "../../components/Course/Overview";
import ReactPlayer from "react-player";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post } from "../../utils/api";
import Skeleton from "react-loading-skeleton";
import { RWebShare } from "react-web-share";
import { ShareIcon } from "@heroicons/react/outline";
import { toast } from 'react-toastify';
import PageError from "../../components/PageError";
import PDFViewer from "../../components/PDFViewer";

const Dashboard = (props) => {
  const lessonId = new URLSearchParams(window.location.search).get('lesson');

  const [isAssessment, setIsAssessment] = useState(false);
  const [course, setCourse] = useState([]);
  const [activeCourseIndex, setActiveCourseindex] = useState(0);
  const [activeCourse, setActiveCourse] = useState({});
  const [activePDF, setActivePDF] = useState({});
  const [lessonType, setLessonType] = useState("video");
  const [courseData, setCourseData] = useState([]);
  const [loadingData, setIsLoadingData] = useState(false);
  const [activeLessonId, setActiveLessonId] = useState(lessonId);


  useEffect(() => {

    fetchCourses();
  }, []);


  const fetchCourses = async () => {
    setIsLoadingData(true);

    try {
      const res = await get(`courses/${props.match.params.courseId}`, true);

      // console.log({ res })
      let course = res.data.course;

      if (course) {

        setCourse(course);

        if (activeLessonId != null) {
          let activeLesson = course?.lessons?.filter((lesson) => lesson._id == activeLessonId);

          if (activeLesson.length > 0) {
            setActiveCourse(activeLesson[0]);
            let curIndex = course.lessons.findIndex(lesson => lesson._id == activeLessonId);
            // // console.log({curIndex})
            setActiveCourseindex(curIndex);
          } else {
            setActiveCourse(course.lessons[0]);
          }
        } else if (Object.keys(activeCourse).length === 0) {
          setActiveCourse(course.lessons[0]);
        } else {
          // console.log('see me here');
          setActiveCourse(course.lessons[0]);
          setActiveLessonId(course.lessons[0]?._id);
        }
      }
      setIsLoadingData(false);


    } catch (e) {
      // console.log({ e })
      toast.error(e.response?.message);
      setIsLoadingData(false);
    }
  }


  const handleLessonChange = (lesson, type, pdf) => {
    // // console.log({lesson, pdf})
    setActiveCourse(lesson);
    window.history.replaceState({}, '', '?lesson=' + lesson._id);
    setLessonType(type);
    if (type === "assessment") {
      setIsAssessment(true);
    } else {
      setIsAssessment(false);
    }

    if (pdf) {
      setActivePDF(pdf)
    }
  };

  return (
    <div className="course__page">
      <Header />
      {loadingData ? (
        <main className="mt-8">
          <Skeleton count={5} />
        </main>
      ) : (
        course.lessons?.length > 0 ?

          <main className="  mx-10	">
            <main className=" mt-8 mb-10 pb-10 w-full min-h-full">
              <div className="flex w-full font-bold bg-gray-900 p-5 justify-between text-white">
                <main>{course.title}</main>
                <main className="flex">
                  {/* <p className="mx-2">Your Progress</p> */}
                  {/* <RWebShare
                    data={{
                      text: `Hi, I am currently taking this course on NGL. I think you would like it also. http://${process.env.BASE_URL}/course/${props.match.params.courseId}}`,
                      url: window.location.href,
                      title: "Share Course",
                    }}
                  >
                    <p className="cursor-pointer flex">Share Course <ShareIcon className="ml-2 w-4 h-4 flex" /> </p>
                  </RWebShare> */}
                </main>
              </div>
              <div className="flex flex-wrap ">
                <main className="w-full md:w-1/5 max-h-screen overflow-y-scroll">
                  {/* <p className="cursor-pointer" className="side__text my-3 mx-2 text-bolder">
                    Courses Content
                  </p> */}
                  <SideMenu
                    activeLessonId={activeLessonId}
                    lessons={course.lessons}
                    route="courses"
                    onClickItem={(lesson, type, pdf) => {
                      handleLessonChange(lesson, type, pdf);
                    }}
                  />
                </main>
                <main className="w-full md:w-4/5 ">
                  <main>
                    {lessonType === "assessment" ? (
                      <Quiz route="courses" assessment={activeCourse.lesson} />
                    ) : lessonType === "document" ? (
                      <PDFViewer url={activePDF.url} allowDownload={activePDF?.allow_download} />
                      // <Document route="document" document={activePDF} />
                    ) : (
                      <div>
                        <ReactPlayer
                          className="w-full bg-gray-900 h-full"
                          width="100%"
                          height="100%"
                          controls
                          playing
                          config={{
                            file: {
                              attributes: {
                                controlsList: 'nodownload'
                              }
                            }
                          }}
                          onContextMenu={e => e.preventDefault()}
                          url={activeCourse?.video}
                        // url={activeCourse?.lesson?.video}
                        />
                        <Overview title="Topic Overview" content={activeCourse?.description} />
                      </div>

                    )}
                  </main>
                </main>
              </div>
            </main>
          </main>

          :
          <div className="w-5/6 mx-auto">
            <PageError title="Opps! No content yet" subtitle={
              <>No course has been uploaded in this section yet. Please check back later.</>
            } />
          </div>

      )}
    </div>
  );
};

export default Dashboard;
