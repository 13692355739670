import { api, get, post, put, del } from "../utils/api";


export const coursesService = {
    fetchAll,
    fetchList,
    // createProvider,
    // createHandler,
    // fetchAllStaff,
    // getCompany,
    // deleteCompany,
    // updateHandler,
    // deleteStaff,
    // updateProvider
    // fetchCompanyClaims,
    // getClaim,
}

async function fetchAll() {

    const response = await get(`/courses/all`, true);

    return response;
}

async function fetchList() {

    const response = await get(`/courses/list`, true);

    return response;
}

async function getCompany(id) {

    const response = await get(`company/${id}`, true);

    if (response.status) {
        return response;
    }

    return response;
}


async function createProvider(data) {

    const response = await post(`company/create`, data, true);

    return response;
}

async function updateProvider(id, data) {

    const response = await post(`company/${id}/update`, data, true);

    return response;
}

async function createHandler(data) {

    const response = await post(`company/create-staff`, data, true);

    return response;
}

async function updateHandler(id, data) {

    const response = await put(`company/staff/${id}/update`, data, true);

    return response;
}

async function fetchAllStaff() {

    const response = await get(`company/staff-list`, true);

    return response;
}


async function deleteStaff(id) {

    const response = await del(`company/staff/${id}/delete`, true);

    return response;
}

async function deleteCompany(id) {

    const response = await del(`company/${id}`, true);

    return response;
}