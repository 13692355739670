import { get, post } from '../utils/api';

const userSubject = JSON.parse(localStorage.getItem('maths-app-user'));
const tokenSubject = localStorage.getItem('maths-user');

export const userServices = {
    getUser,
    getToken,
    setUser,
    setToken,
    checkIsVerified,
    updateUserData,
    isLoggedIn,
    logUserIn,
    login,
    logOut,

}

function getUser() {
    return userSubject;
}

function getToken() {
    return tokenSubject;
}

function setUser(user) {
    localStorage.setItem("maths-app-user", JSON.stringify(user));
}

function setToken(token) {
    localStorage.setItem("maths-user", token);
}


async function login(username, password) {

    const response = await post(`auths/login`, { id: username, password });

    if (response.status) {
        // publish user to subscribers and store in local storage to stay logged in between page refreshes
        const user = response.data;

        //Ensure login does not accept 'super_admin' role.
        if (user.user.role.name == 'super_admin') {
            return {
                status: false,
                message: 'Error logging in'
            }
        }
        // userSubject.next(user);

        logUserIn(user);
        return response;
    }

    return response;
}

async function checkIsVerified() {

    const response = await get(`auths/check-verified`, true);

    if (response.status) {
        return response.data.user_verified;
    }

    return false;
}

function logOut(useNotice = false) {
    localStorage.removeItem('maths-app-user');
    localStorage.removeItem('maths-user');
    // userSubject.next(null);

    window.location.href = useNotice ? '/login-notice' : '/';
    // Router.push({pathname: '/'});
}

function isLoggedIn() {
    if (tokenSubject && userSubject && Object.keys(userSubject).length > 0) {
        return true;
    }

    return false
}

function updateUserData(user) {
    localStorage.setItem(
        "maths-app-user",
        JSON.stringify(user)
    );
}

function logUserIn(user) {
    setUser(user);
    setToken(user.token)
}
