import React, { Component, useState } from "react";
import ThreadCard from "../../components/Forum/Thread";
import { api, get, post, handleError } from "../../utils/api";
import "./index.css";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQueries, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ForumBase from './ForumBase'


const queryString = require("query-string");


const Forum = (props) => {
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [category, setCategory] = useState(props.match.params.categoryId);
  const [loading, setLoading] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [allThreadsCount, setAllThreadsCount] = useState(0);
  const [threadsCurrentPage, setThreadsCurrentPage] = useState(
    queryString.parse(props.location.search).page || 1
  );
  const [recentCurrentPage, setRecentCurrentPage] = useState(
    queryString.parse(props.location.search).page || 1
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  function openModal() {
    setIsOpen(true);
    setTitle("");
    setBody("");
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onSubmit = (event) => {
    setBodyError(false);
    mutate({ title, body, category });
  };

  const {
    data: usersData,
    isLoading: loadingCategories,
    refetch: refetchCatgories,
  } = useQuery("allForumCategoriesData", () =>
    get(`forums/category/fetch-all`, true).then((res) => {
      setCategories(res.data.data);
    })
  );

  const handlePageClick = (event) => {
    setThreadsCurrentPage(event.selected + 1);
    setAllThreadsCount(event.selected + 1);
    refetchData();
    // refetchByResponse();
    window.scrollTo(0, 0);
    history.push({
      pathname: `/forum/category/${props.match.params.categoryId}`,
      search: `?page=${event.selected + 1}`,
    });
  };

  const { mutate, isLoading: createLoader } = useMutation(
    (payload) => createPost(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setLoading(false);
        if (data && data.status < 400) {
          toast.success("Thread created Successful");
          refetchByResponse();
          refetchData();
          setTitle("");
          setBody("");
          setIsOpen(false);
        }
      },
    }
  );

  const createPost = async (data) => {
    try {
      setLoading(true);
      const res = await post("/forums/create-thread", data, true);
      setLoading(false);
      toast.success("Thread created Successful");
      history.push(`/post/${res.data.thread._id}`);
    } catch (e) {
      setLoading(false);
      const message = await handleError(e);
      toast.error(message);
    }
  };

  const {
    isLoading: loadingData,
    error,
    data,
    refetch: refetchData,
  } = useQuery("formumbdData", () =>
    get(
      `/forums/fetch-all-threads?category=${props.match.params.categoryId}&pageSize=10&page=${threadsCurrentPage}`,
      true
    ).then((res) => {
      setAllThreadsCount(res.data.pagination.total);

      // // console.log('d', res.data.threads)
      return res.data.threads;
    })
  );

  const {
    isLoading: byResponseLoader,
    error: byResponseError,
    data: byResponseData,
    refetch: refetchByResponse,
  } = useQuery("byResponse", () =>
    get(
      `/forums/fetch-all-threads?category=${props.match.params.categoryId}&type=by_response&pageSize=10&page=${threadsCurrentPage}`,
      true
    ).then((res) => res.data.threads)
  );

  const handleChange = (value) => {
    setBody(value);
  };

  return (
    <ForumBase header={
      <div className="flex items-center">
        <h1 className="flex-1 text-lg font-medium">Topics in {category.name} Category </h1>
        <div className="relative">
          <button type="button" className="w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="sort-menu-button" aria-expanded="false" aria-haspopup="true">
            <svg className="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
            </svg>
            Sort
            <svg className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
          {/* <div className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="sort-menu-button" tabindex="-1">
          <div className="py-1" role="none">
            <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="sort-menu-item-0">Name</a>
            <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="sort-menu-item-1">Date modified</a>
            <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="sort-menu-item-2">Date created</a>
          </div>
        </div> */}
        </div>
      </div>
    }>

      {!data || !data.length || loadingData ? (
        <Skeleton className="mt-4" count={5} />
      ) : (
        data.map((thread) => (
          <ul role="list" className="relative z-0 divide-y divide-gray-200 border-b border-gray-200">
            <ThreadCard thread={thread} />
          </ul>
        ))
      )}
    </ForumBase>
  );
};

export default Forum;
