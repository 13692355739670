import React, { Component, useContext, useEffect, useState } from "react";
import ThreadCard from "../../components/Forum/Thread";
import { api, get, post, handleError } from "../../utils/api";
import Skeleton from "../../components/Skeleton";
import { useMutation, useQueries, useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import ForumBase from './ForumBase'
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import SearchForm from "./SearchForm";

const queryString = require("query-string");



const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    padding: "0px",
  },
};

const Forum = (props) => {

  const { user } = useContext(AuthContext);
  const history = useHistory();

  const [topics, setTopics] = useState([]);

  const [allThreadsCount, setAllThreadsCount] = useState(0);
  // const [threadsCurrentPage, setThreadsCurrentPage] = useState(
  //  1
  // );
  const [threadsCurrentPage, setThreadsCurrentPage] = useState(
    queryString.parse(props.location?.search).page || 0
  );

  const [recentCurrentPage, setRecentCurrentPage] = useState(
    queryString.parse(props.location?.search).page || 1
  );
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(true);
  const [openNewTopicModal, setOpenNewTopicModal] = useState(false);


  useEffect(() => {
    fetchTopics();
  }, [])



  const fetchTopics = (pageNumber = 1) => {

    window.history.replaceState({}, '', `?page=${pageNumber}&pageSize=10&title=${searchParam}`);

    setLoading(true)
    get(
      `/forums/fetch-all-threads?pageSize=10&page=${pageNumber}&title=${searchParam}`,
      true
    ).then((res) => {
      setAllThreadsCount(res.data.pagination.total);
      setTopics(res.data.threads);
      setLoading(false)
    }).catch(e => {
      toast.error('Error loading forum topics');
    })
  }


  // const {
  //   isLoading: loadingData,
  //   error,
  //   data,
  //   refetch: refetchData,
  // } = useQuery("formumbdData", () =>

  // );


  // // console.log({loadingData})

  // const {
  //   isLoading: byResponseLoader,
  //   error: byResponseError,
  //   data: byResponseData,
  //   refetch: refetchByResponse,
  // } = useQuery("byResponse", () =>
  //   get(
  //     `/forums/fetch-all-threads?type=by_response&pageSize=10&page=${threadsCurrentPage}&title=${searchParam}`,
  //     true
  //   ).then((res) => res.data.threads)
  // );


  const handlePageClick = (event) => {
    // // console.log({e: event.selected})
    const pageNumber = event.selected + 1;
    setThreadsCurrentPage(pageNumber);
    setAllThreadsCount(pageNumber);
    // refetchByResponse();
    window.scrollTo(0, 0);

    fetchTopics(pageNumber);

    // history.push({
    //   pathname: "/forum",
    //   search: `?page=${event.selected + 1}`,
    // });
  };



  return (
    <ForumBase header={
      <div className="flex items-center">
        <h1 className="flex-1 text-lg font-medium">Latest Topics</h1>
        <div className="relative">
          <SearchForm onChange={setSearchParam} onSearch={fetchTopics} />
        </div>
      </div>
    } openModal={openNewTopicModal}>

      {loading ? <Skeleton /> : (
        !topics || !topics.length ? (
          <p className="m-3 text-center">No topic found. <a href="#" onClick={() => setOpenNewTopicModal(true)}>Post a new topic</a></p>
        ) : (
          <>
            {
              topics.map((thread) => (
                <ul role="list" className="relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                  <ThreadCard thread={thread} />
                </ul>
              ))
            }
            <div className="flex justify-center my-6">
              <ReactPaginate
                previousLabel="&larr;"
                nextLabel="&rarr;"
                breakLabel="..."
                breakClassName="inline mx-2 border rounded-md  p-3 "
                previousClassName="inline mx-2 border rounded-md w-12 p-3 "
                activeClassName="bg-blue-50"
                nextClassName="inline mx-2 rounded-md  border p-3  "
                pageCount={allThreadsCount / 10}
                forcePage={threadsCurrentPage - 1}
                // marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                pageClassName="inline mx-2 rounded-md  border p-3 "
                onPageChange={handlePageClick}
                // initialPage={1}
                containerClassName=""
              />
            </div>
          </>

        )
      )}


    </ForumBase>
  );
};

export default Forum;