import "./Landing.css";
import React, { Component, useState } from "react";
import { StyledButton } from "../../Shared/Button/button.style";
import background from "../../../img/landing2.jpg";
import quality_content from "../../../img/quality_content.svg";
import forum from "../../../img/forum.svg";
import access_anywhere from "../../../img/access_anywhere.svg";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { userServices } from "../../../services/userServices";
import WhyTheBest from "../WhyTheBest";
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon, StarIcon } from "@heroicons/react/solid";
import Button from "../../Shared/Button";
import Modal from "../../Shared/Modal";
import ReactPlayer from "react-player";


const features = [
  {
    name: '95%',
    description:
      'Say they love the style of our videos',
    icon: GlobeAltIcon,
  },
  {
    name: '8.7',
    description:
      'Average rating of our teaching style out of 10. (We know we are a 10, deep down 😊)',
    icon: StarIcon,
  },
  {
    name: '90%',
    description:
      'Say their child was kept engaged by our videos',
    icon: LightningBoltIcon,
  },
]

const Home = (props) => {
  const history = useHistory();
  const handleClick = (route) => {
    history.push(route);
  };
  const token = userServices.getToken();

  const [showDemo, setShowDemo] = useState(false);

  const [items, setItems] = useState([
    {
      img: quality_content,
      title: "Quality Content",
      content: "We have over 140+ animated videos and tailored worksheets that perfectly suits kids of different academic levels.",
      // content: "140+ animated videos and tailored worksheets.",
    },
    {
      img: access_anywhere,
      title: "Exam Papers",
      content: "We have prepared Mock exam papers that strongly resemble previous exam papers with detailed solutions.",
    },
    {
      img: forum,
      title: "Forum",
      content: "An interactive forum where parents can discuss 11+ related matters and message each other",
    },
  ]);
  return (
    <div className="landing">
      <div
        className=" bg-gray-900 flex w-full items-center md:items-end md:pb-32 justify-center bg-cover"
        style={{ backgroundImage: `url(${background})` }}
      >
        <main className="text-center mt-16 md:mt-16 md:mx-8 max-w-6xl mx-auto">
          <h1 className="text-3xl md:text-5xl font-bold tracking-tight text-white">
            <span className="block">
              Master the entire KS2/11+ Maths syllabus using our animated videos, worksheets and mock papers!
            </span>
          </h1>
          <p className="m-3 text-base text-gray-100 sm:mt-5 md:text-2xl">
            Immerse your child in our ordered, animated platform.
            {/* <br />
            <a href="/money-back-guarantee" className="underline">Learn more</a> */}
          </p>

          <main className="my-12 md:mt-12 space-x-2">
            <Button href={token ? '/subscribe' : '/register'}>
              Start free 10-day trial!
            </Button>
            <Button outline onClick={() => setShowDemo(true)}>
              View Demo
            </Button>

            {/* {token ? (
              <Button>
                Hello
              </Button>
              // <StyledButton
              //   onClick={() => {
              //     handleClick("/forum");
              //   }}
              //   backgroundColor="#286cff"
              //   width="200px"
              // >
              //   Go to Forum
              // </StyledButton>
            ) : (

              // <StyledButton
              //   onClick={() => {
              //     handleClick("/login");
              //   }}
              //   backgroundColor="#286cff"
              // >
              //   Get Started
              // </StyledButton>
            )} */}
          </main>
        </main>
      </div>
      <div className="w-full boxed__container">
        <div className=" ">
          <div className=" bg-blue-50 py-10 text-center">
            <h2 className="text-3xl font-bold  text-blue-500">
              <a href="/money-back-guarantee">
                100% Money Back Guarantee. <span className="underline" >Learn more</span>
              </a>
            </h2>
          </div>
          <WhyTheBest />
        </div>
        <div className="bg-white px-3 sm:py-8 lg:py-8 text-center">
          <div className="mx-auto max-w-xl px-0 md:px-6 lg:max-w-7xl lg:px-8">
            <div className="md:flex md:flex-column md:space-x-5">
              {/* ?            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"> */}
              {features.map((feature) => (
                <div key={feature.name} className="flow-root rounded-lg bg-blue-50 px-6 my-6 pb-8 pt-6 w-full md:w-1/3 h-full">
                  <div className="-mt-6 feature_icon">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-md bg-blue-500 p-3 shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-5xl font-bold tracking-tight text-gray-700">{feature.name}</h3>
                    <p className="mt-3 text-base text-gray-600">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* {items.map((item, index) => (
          <main key={index} className="w-full md:w-1/3 px-2 ">
            <div className="rounded-lg border border-gray-200 shadow-md mx-2 mt-8 bg-white flex w-full px-6 py-12">
              <img className="h-20" src={item.img} />
              <div className="ml-8">
                <p className="card__title text-black">{item.title}</p>
                <p className="card__body mt-2">{item.content}</p>
              </div>
            </div>
          </main>
        ))} */}
      </div>

      <Modal
        open={showDemo}
        setOpen={setShowDemo}
      // style={customStyles}
      >
        <ReactPlayer
          className="w-full bg-gray-900 h-full"
          width="100%"
          height="350px"
          controls
          playing
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload'
              }
            }
          }}
          onContextMenu={e => e.preventDefault()}
          url="https://www.youtube.com/watch?v=s9jZfoIcwFc"
        />

        {/* <iframe width="100%" height="315" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe> */}
      </Modal>

    </div >
  );
};

export default withRouter(Home);
