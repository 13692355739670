import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.css";
import Sidebar from "./Sidebar";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, put } from "../../utils/api";
import { toast } from "react-toastify";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import PasswordInput from "../../components/PasswordInput";
import Button from "../../components/Shared/Button";
import { passwordCheck } from "../../utils/helpers";

const UpdateProfilePassword = (props) => {


  const [loading, setLoading] = useState(false);

  // const [email, setEmail] = useState("");
  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const resetPassword = async (data) => {
    setLoading(true);

    try {
      const response = await post("/users/change-password", data, true);

      if (response?.data?.status == 'success') {
        toast('Password changed successfully!');
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    } catch (e) {
      // console.log({ e })
      toast.error('Error updating password');
      setLoading(false);
    }
  };

  // const { mutate, isLoading } = useMutation(
  //   (payload) => resetPassword(payload),
  //   {
  //     onError: (error, variables, context) => {
  //       toast.error(error.response.data.message);
  //       setLoading(false);
  //     },
  //     onSuccess: (data, variables, context) => {
  //       toast.success(data.data.message);

  //       setLoading(false);
  //     },
  //   }
  // );

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!checkPassword()) {
      return false
    } else {
      resetPassword({ old_password, password, confirm_password });
    }

  };


  const checkPassword = () => {

    // let check = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*].{7,}$/;

    if (password != confirm_password) {
      toast.error('Passwords do not match');
      return false;
    } else if (!passwordCheck(password)) {
      toast.error('Password requirements were not met. New password must contain: at least 6 letters, one number, and a special character e.g [?£$*?/#]()!', {
        delay: 3000
      });
      return false;
    }

    return true;
  }


  return (
    <div className="account__page ">
      <Header />

      <main className="">
        <main className=" my-8 max-w-7xl mx-auto flex flex-wrap boxed__container top__wrapper ">
          <main className="w-full md:w-1/5 border">
            <Sidebar activeMenu="password" />
          </main>
          <main className="w-full md:w-4/5 border">
            <main className="mx-auto">

              <main className="w-full mx-auto md:w-1/2 my-2 px-2">
                <p className="my-8"></p>
                <p className="text-center page__header">Update Password</p>
                <p className="my-8"></p>

                <p className="form__label">
                  Enter current Password<span className="text-red-500">*</span>
                </p>
                <PasswordInput
                  type="password"
                  className="form__border w-full my-2"
                  placeholder="Enter current password"
                  min={8}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <p className="form__label">
                  Enter new Password<span className="text-red-500">*</span>
                </p>
                <PasswordInput
                  type="password"
                  className="form__border w-full my-2"
                  placeholder="Enter New Password"
                  min={8}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p className="form__label">
                  Confirm Password<span className="text-red-500">*</span>
                </p>
                <PasswordInput
                  type="password"
                  className="form__border w-full my-2"
                  placeholder="Confirm New Password"
                  min={8}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

                <main className="my-3">
                  <Button
                    className="w-full"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Update Password"}
                  </Button>
                </main>
              </main>



            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default UpdateProfilePassword;
