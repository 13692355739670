import React, { Component, useState } from "react";
import Header from "../../components/HomePage/Header";
import Footer from "../../components/HomePage/Footer";

import {
  PageHeaderContainer,
  PageHeaderText,
} from "../../components/Shared/PageHeader/pageheader.style";
import Modal from "../../components/Shared/Modal";

const CookiePolicy = () => {

  const [showModal, setShowModal] = useState(false);
  const [activeTeamMember, setActiveTeamMember] = useState({});

  const handleShowProfile = (e, profile) => {
    e.preventDefault();

    setActiveTeamMember(profile)
    setShowModal(true);
  }

  return (
    <div className="about ">
      <Header />
      <div className="pt-12" />
      <main className="boxed__container">
        <main className="mx-auto flex justify-center">
          <PageHeaderContainer className="">
            <PageHeaderText>Cookie Policy</PageHeaderText>
          </PageHeaderContainer>
        </main>
        <main className="flex justify-center">
          <main className="w-full md:w-1/2 ">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-2 text-center"> Our Cookie Policy</h2>
            <p className="page__header my-4"></p>
            <div className="text-base text-center my-4 mb-8 space-y-3">
              <p>
                Cookies are small text files that can be used by websites to make a user's experience more efficient.
                <p className="mt-3">
                  The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission. This means that cookies which are categorized as necessary, are processed based on GDPR Art. 6 (1) (f). All other cookies, meaning those from the categories preferences and marketing, are processed based on GDPR Art. 6 (1) (a) GDPR.              </p>
              </p>
            </div>
          </main>
        </main>


      </main>


      <Footer />
    </div>
  )
}

export default CookiePolicy;