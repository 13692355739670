import React, { Component, useEffect, useState } from "react";
import CommentCard from "../../components/Forum/Comment";
import Loader from "../../components/Shared/Loader";
import "./index.css";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, handleError, del } from "../../utils/api";
import { toast } from "react-toastify";
import Skeleton from "../../components/Skeleton";
import { Link, useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import ForumBase from "../Forum/ForumBase"
import Button from "../../components/Shared/Button";
import GuestComment from "./GuestComment";
import Attachments from "../Forum/Attachments";
import { TrashIcon } from "@heroicons/react/solid";
import { userServices } from "../../services/userServices";

export default function Post(props) {
  const history = useHistory();

  const token = userServices.getToken();
  const user = userServices.getUser();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [deleteAllAttachments, setDeleteAllAttachments] = useState(false);
  const [data, setData] = useState({});


  useEffect(() => {
    fetchTopicDetails();
  }, []);
  const handleSubmit = (event) => {
    mutate({ comment, attachments });
  };

  const handleClick = (route) => {
    history.push(route);
  };

  const fetchTopicDetails = () => {
    get(`forums/${props.match.params.postId}/fetch-thread`, true).then(
      (res) => setData(res.data.thread)
    ).catch(e => {
      props.history.replace('/forum');
      toast.error('Cannot fetch topic details at the moment. Please refresh');
    })
  }

  const { mutate, isLoading: createLoader } = useMutation(
    (payload) => createComment(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setLoading(false);
        if (data && data.status < 400) {
          setLoading(false);
          setComment("");
          fetchTopicDetails();
          // // console.log({ data });
          toast.success("Comment created Successful");
          props.history.push(props.match.params.postId + '#comment' + data.data.comment._id)
          setDeleteAllAttachments(true);
        }
      },
    }
  );

  const createComment = async (data) => {
    try {
      setLoading(true);
      const res = await post(
        `forums/${props.match.params.postId}/post-comment`,
        data,
        true
      );
      setLoading(false);
      fetchTopicDetails();
      return res;
    } catch (e) {
      setLoading(false);
      const message = await handleError(e);
      toast.error(message);
    }
  };

  const handleChange = (value) => {
    setComment(value);
  };

  const handleFilesUpload = (file) => {
    setAttachments([...attachments, file])
  }

  const handleDeletePost = async () => {
    const response = await del(`/forums/${data.id}/delete`, {}, true);

    if (response.status) {
      toast('Thread deleted successfully!');
      props.history.replace('/forum')
    } else {
      toast.error('Error deleting thread');
    }
  }

  return (
    <ForumBase header={
      <div className="flex items-center">
        <h1 className="flex-1 text-lg font-medium">{loading ? <Skeleton count={1} /> : data.title}</h1>
        <div className="relative flex">
          <button onClick={() => history.push('/forum')} type="button" className=" bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            &larr; Return to Topics
          </button>
          {user.id == data?.user?.id && (
            <button onClick={handleDeletePost} type="button" className="ml-1 bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <TrashIcon className="w-3 h-3 mr-1 mt-1" /> Delete Topic
            </button>
          )}
        </div>
      </ div>

    } >
      <main className="p-4">
        {loading ? (
          <main className="mt-8">
            <Skeleton />
          </main>
        ) : (
          <main>
            <p
              className=" py-2"
              dangerouslySetInnerHTML={{
                __html: data.body,
              }}
            ></p>

            {data.attachments?.length > 0 && (
              <div>
                <strong>Attachments:</strong><br />
                {data.attachments.map((item, index) => (
                  <>
                    <a key={index} href={item.url} target="_blank">{item.name}</a><br />
                  </>
                ))}
              </div>
            )}

            <main
              onClick={() => {
                handleClick(`/user/${data.user.id}`);
              }}
              className="flex my-8 w-full cursor-pointer"
            >
              {data && data.user && data.user.profile_image ? (
                <img
                  src={data.user.profile_image}
                  alt=""
                  className="rounded-full bg-gray-300 object-cover h-8 w-8 "
                />
              ) : (
                <p className="text-center">
                  <i className="fas text-black fa-user-circle text-center "></i>
                </p>
              )}
              {/* <img src={sam} className="h-8" /> */}
              <p className="posted ml-3 mt-1">
                <span className="poster cursor-pointer">
                  {data && data.user && data.user.fullname}
                </span>
              </p>
            </main>

            <main className="flex justify-end my-4">
              {token ? (
                <main className="w-full">
                  <main className="flex justify-center w-full">
                    <ReactQuill
                      className=" w-full mb-8 mx-auto h-32"
                      value={comment}
                      onChange={handleChange}
                    />
                    {/* <textarea
                          type="text"
                          className="form__border w-full my-2 mx-auto"
                          placeholder="Post a comment"
                          rows="4"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        /> */}
                  </main>
                  <Attachments onUploadFile={handleFilesUpload} deleteAll={deleteAllAttachments} />
                  <Button
                    className="mt-4"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {loading ? <Loader /> : " Comment"}
                  </Button>
                </main>
              ) : (
                <GuestComment />
              )}
            </main>

            <div className="px-4 py-6 sm:px-6">
              {data && data.comments && data.comments.length ? (
                <>
                  <h3>Comments</h3>
                  <div className="space-y-8  divide-y">
                    {data.comments.map((comment, index) => (
                      comment.comment &&
                      <CommentCard thread={data} comment={comment} key={index} />
                    ))}
                  </div>
                </>
              ) : (
                <></>
                // <p>No comments yet</p>
              )}
            </div>
          </main>
        )}
      </main>


    </ForumBase>
  )
}