import React from 'react';

export default function Dashboard(props) {

    return (
        <div className="min-h-full">
            {props.header}

            {props.isWide ? props.children :
                <div className="w-5/6 mx-auto ">
                    {props.children}
                </div>
            }
        </div>
    )
}
