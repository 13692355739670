import React, { Component, useState } from "react";
import "./comment.css";
import sam from "../../../img/sam.png";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { del } from "../../../utils/api";
import { toast } from "react-toastify";
import { userServices } from "../../../services/userServices";

const CommentCard = (props) => {
  // const history = useHistory();
  const { comment, thread } = props;

  const handleDeleteComment = async (e) => {
    e.preventDefault();

    const response = await del(`/forums/${thread.id}/comment/${comment._id}/delete`, {}, true);

    if (response.status) {
      toast('Comment deleted successfully!');
      setTimeout(() => {
        window.location.reload();
      }, 300)
    } else {
      toast.error('Error deleting comment');
    }
  }

  return (
    <div key={comment.id} className="pt-2" id={`#comment${comment._id}`}>
      <div className="flex space-x-3">
        <div className="flex-shrink-0">
          {comment.profile_image && (
            <img
              className="h-10 w-10 rounded-full"
              src={comment.profile_image}
              alt=""
            />
          )}
        </div>
        <div>
          <div className="text-sm">
            <a href="#" className="font-medium text-gray-900">
              {comment.fullname}
            </a>
          </div>
          <div className="mt-1 text-sm text-gray-700">
            <p
              className="comment mt-3"
              dangerouslySetInnerHTML={{
                __html: comment.comment,
              }}
            ></p>
          </div>

          {comment.attachments?.length > 0 && (
            <div>
              <strong>Attachments:</strong><br />
              {comment.attachments.map((item, index) => (
                <>
                  <a href={item.url} target="_blank">{item.name}</a><br />
                </>
              ))}
            </div>
          )}

          <div className="mt-2 text-sm space-x-2">
            <span className="text-gray-500 font-medium">{moment(comment.createdAt).fromNow()}</span>{' '}
            {userServices.getUser().id == comment.user && (
              <a href="#" onClick={handleDeleteComment}>Delete Comment</a>
            )}
            {/* <span className="text-gray-500 font-medium">&middot;</span>{' '}
              <button type="button" className="text-gray-900 font-medium">
                Reply
              </button> */}
          </div>
        </div>
      </div>
    </div >
  );
};
export default withRouter(CommentCard);
