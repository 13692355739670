import React, { Component, useEffect, useState } from "react";
import Header from "../../components/Shared/Header";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import { api, get, post, handleError } from "../../utils/api";
import "./index.css";
import { toast } from "react-toastify";
import Modal from "react-modal";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQueries, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { StyledCard } from "./card.style";
import { DownloadIcon } from "@heroicons/react/solid";
import Dashboard from "../../components/Layouts/Dashboard";
import SectionTitle from "../../components/SectionTitle";
import CardsSkeleton from "../../components/CardsSkeleton";
import ExamsCard from "./ExamsCard";
import CategoryCard from "./CategoryCard";
const queryString = require("query-string");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    padding: "0px",
  },
};

const Exams = (props) => {
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(true)
    get("/exams/category/fetch-all", true).then((res) => {
      setExams(res.data.data);
      setLoading(false);
    })
  }, []);



  return (
    <Dashboard header={<Header />}>


      <div className="py-2" />

      <SectionTitle title="Exam Papers" subtitle={<ul><li>Welcome to the exam papers tab. We are constantly updating this page with more papers! </li><li>We <strong>strongly recommend</strong> your child only attempts these papers once they have a thorough understanding of the syllabus content. </li><li>The papers will sufficiently challenge your child and strongly reflect the difficulty of the real exam.</li></ul>} />

      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">

        {loading ? <CardsSkeleton /> : (
          <CategoryCard exams={exams} />
        )}
      </div>
    </Dashboard>


  );
};

export default Exams;
