import React, { Component, useState, useContext } from "react";
import Header from "../../components/Shared/Header";
import "./index.css";
import Sidebar from "./Sidebar";
import image from "../../img/image.png";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, put } from "../../utils/api";
import { AuthContext } from "../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";
import Button from "../../components/Shared/Button";
import { userServices } from "../../services/userServices";

const Photo = (props) => {
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState({});
  const [previewUrl, setPreviewUrl] = useState(user?.profile_image);

  const { mutate, isLoading } = useMutation(
    (payload) => initialUpload(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        awsUpload({
          route: data.data.data.signedRequest,
          url: data.data.data.url,
        });
        setLoading(false);

      },
    }
  );

  const initialUpload = async ({ name, type }) => {
    // console.log({name})
    try {
      setLoading(true);
      return post(
        `/services/file/presign-url/?file_name=${name}&file_type=${type}`,
        {},
        true
      );
    } catch (e) {
      setLoading(false)
      toast.error("An error occurred");
      return e;
    }
  };

  const { mutate: mutateProfile, isLoading: isUpdatingProfile } = useMutation(
    (payload) => updateProfile(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        setUser(data.data.user);
        userServices.updateUserData(data.data.user);
        toast.success("Profile picture updated successfully!");
        setLoading(false);

        // setTimeout(() => {
        //   window.location.reload()
        // }, 3000);
      },
    }
  );

  const updateProfile = async (data) => {
    try {
      setLoading(true);
      return put("/users/profile/update", data, true);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const onFileChange = async (e) => {
    // Update the state
    setLoading(true);
    e.preventDefault();
    const files = document.getElementById("file-input").files;
    const temp = files[0];
    if (temp == null) {
      setLoading(false);
      return alert("No file selected.");
    }
    mutate({ name: temp.name, type: temp.type });
  };


  const awsUpload = async ({ route, url }) => {
    try {
      const xhr = new XMLHttpRequest();

      xhr.open("PUT", route);
      xhr.onreadystatechange = () => {
        console.log({ xhr })
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            mutateProfile({ profile_image: url });
            setLoading(false);
          } else {
            toast.error("An error occurred! Could not upload file.");
            setLoading(false);
          }
        }
      };
      xhr.send(image);
    } catch (e) {
      toast.error("An error occurred");
      setLoading(false);
      return e;
    }
  };

  return (
    <div className="account__page ">
      <Header />

      <main className="">
        <main className=" my-8 max-w-7xl mx-auto flex flex-wrap boxed__container top__wrapper ">
          <main className="w-full md:w-1/5 border">
            <Sidebar activeMenu="photo" />
          </main>
          <main className="w-full md:w-4/5 border">
            <main className="mx-auto">
              <main className="w-full mx-auto md:w-1/2 my-2 px-2">
                <p className="my-8"></p>
                <p className="text-center page__header">Profile Photo</p>
                <p className="text-center sub__header">
                  Add a picture to your profile
                </p>
                <p className="my-8"></p>
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt="User Profile Picture"
                    className="rounded-full mx-auto h-24 w-24 mb-8 mt-4"
                  />
                ) : (
                  <p className="text-center">
                    <i className="fas fa-8x text-black fa-user-circle text-center mx-auto"></i>
                  </p>
                )}
                {/* <img src={image} className="rounded-full mx-auto mb-8 mt-4" /> */}

                <input
                  type="file"
                  placeholder="e.g Ademola"
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setPreviewUrl(URL.createObjectURL(e.target.files[0]));
                  }}
                  id="file-input"
                  accept="image/*"
                  className={`form__border w-full my-2 `}
                />
                <p className="hint">Note: Size must not be greater than 25mb</p>
                <main className="flex w-full my-3">
                  <Button
                    className="w-full"
                    disabled={loading}
                    onClick={(e) => {
                      onFileChange(e);
                    }}
                  >
                    {loading ? (
                      <Loader />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </main>
              </main>
            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Photo;
