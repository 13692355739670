import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { useState } from 'react'

export default function SuccessAlert({ title, message }) {
    const [show, setShow] = useState(true);

    return (
        show ? (
            <div className="rounded-md bg-green-100 p-4 w-full my-3" >
                <div className="flex">
                    <div className="flex-shrink-0">
                        <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex justify-between w-full">
                        <div className="flex-grow">
                            <h3 className="text-sm font-medium text-green-800">{title}</h3>
                            <div className="mt-2 text-sm text-green-700">
                                <p>{message}</p>
                            </div>

                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    onClick={() => setShow(false)}
                                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
            :
            <></>
    )
}
