import React, { useEffect, useState, useContext } from "react";
import Header from "../../components/Auth/Header";
import Side from "../../components/Auth/Side";
import Loader from "../../components/Shared/Loader";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { StyledProgress } from "../../components/Auth/progress.style";
import loginImg from "../../img/login.png";
import register_mini from "../../img/register-mini.png";
import "./authentication.css";
import { api } from "../../utils/api";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import { useForm } from "react-hook-form";
import PasswordField from "../../components/Input";
import { userServices } from "../../services/userServices";
import { Link } from "react-router-dom";
import Button from "../../components/Shared/Button";

const header = {
  side: "New here?",
  text: "Create an account",
  route: "/register",
};


const Login = (props) => {

  const { user, setUser, setToken, token } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    // // console.log({ user })
    //If user is logged in, auto-redirect
    if ((user && Object.keys(user).length > 0)) {
      window.location.href = "/";
    }
  }, [])



  const onSubmit = (data) => {
    handleLogin();
  };
  const [sideNav, setSideNav] = useState({
    name: "Funmi O.",
    quote:
      "I love the fact that I am able to watch the videos alongside my child. I learnt a lot too and the quality of the videos are excellent.",
    // backgroundImage: loginImg,
    // profile: register_mini,
  });


  const { mutate, isLoading } = useMutation((payload) => loginUser(payload), {
    onError: (error, variables, context) => {
      toast.error(error.response.data.message);
      setLoading(false);
    },
    onSuccess: (data, variables, context) => {
      toast.success("Login Successful!");
      setLoading(false);
      setToken(data.data.data.user.token);
      // console.log('user', data.data.data.user)
      const _user = data.data.data.user;
      setUser(_user);

      userServices.logUserIn(_user);
      // window.location.href = '/';
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = urlSearchParams.get('redirect_from');

      // // console.log({params})

      // props.history.push(params ?? "/");
      if (_user.is_activated) {
        window.location.href = params ?? "/";
      } else {
        window.location.href = "/verify";
      }

    },
  });

  const loginUser = async (data) => {
    try {
      setLoading(true);
      return api.post("/auths/login", data);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const handleLogin = () => {
    mutate({ username, password });
  };
  const handleClick = (route) => {
    props.history.push(route);
  };
  return (
    <main className="auth__Login">
      <div className="authentication min-h-screen flex flex-wrap w-full">
        <main className="w-full hidden xl:block xl:w-1/3">
          <Side data={sideNav} />
        </main>
        <main className="w-full xl:w-2/3">
          <Header data={header} />
          <main className="mt-8 w-4/5 mx-auto xl:px-32">
            <p className="form__header">Welcome back</p>
            <p className="">Please sign in to your account</p>
            <main className="unfilled__progress rounded mt-4">
              <StyledProgress width="100%" />
            </main>

            <form onSubmit={handleSubmit(onSubmit)}>
              <main className="flex flex-wrap mt-8 ">
                <main className="w-full md:w-1/2 my-2 px-2">
                  <p className="form__label">
                    Email/Username<span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    name="username"
                    required
                    className={`form__border w-full my-2 ${errors.username ? "red__form__border" : ""
                      }`}
                    placeholder="e.g Ademola"
                    onChange={(e) => setUsername(e.target.value)}
                  />

                  {/* <input
                    type="text"
                    {...register("username", { required: true })}
                    className={`form__border w-full my-2 ${
                      errors.username ? "red__form__border" : ""
                    }`}
                    placeholder="e.g Ademola"
                    onChange={(e) => setUsername(e.target.value)}
                  /> */}

                  <p className="error__message">
                    {errors.username && "Username is required"}
                  </p>
                </main>

                <main className="w-full md:w-1/2 my-2 px-2">
                  <p className="form__label">
                    Password<span className="text-red-500">*</span>
                  </p>
                  <PasswordField
                    name="password"
                    required
                    errors={errors.password}
                    placeholder="****"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {/* <input
                    type="password"
                    name="password"
                    required
                    // {...register("password", { required: true, minLength: 6 })}
                    className={`form__border w-full my-2 ${
                      errors.password ? "red__form__border" : ""
                    }`} */}

                  {/* onChange={(e) => setPassword(e.target.value)}
                  /> */}

                  <p className="error__message">
                    {errors &&
                      errors.password &&
                      errors.password.type === "required" &&
                      "Password is required"}
                    {errors &&
                      errors.password &&
                      errors.password.type === "minLength" &&
                      "Password must be a minimum of 6 characters"}
                  </p>
                </main>

                <main className="flex flex-wrap justify-between w-full my-2 px-2">
                  <p className="form__muted">
                    <input className="pb-2" name="isGoing" type="checkbox" />{" "}
                    <span className="pb-2">Remember me</span>
                  </p>
                  <p className="form__muted">
                    {/* Reset Password */}
                    <span
                      onClick={() => {
                        handleClick("/reset");
                      }}
                      className="ml-2 blue__text"
                    >
                      Forgot Password/Username?{" "}
                    </span>
                  </p>
                </main>

                <main className="w-full my-8 px-2">
                  <Button className="w-full mt-1" disabled={loading ? true : false}>
                    {loading ? <Loader /> : "Login"}
                  </Button>
                </main>
                <p className="mb-10 md:hidden w-full text-center">New Here? <Link href="register">Create an account</Link></p>
              </main>
            </form>
          </main>
        </main>
      </div>
    </main>
  );
};
export default Login;
