import React from 'react';

export default function PageError({ title, subtitle, emoji = '😥' }) {
  return (
    <div className="bg-white rounded-md my-4 px-4 py-5 border border-gray-200 sm:px-6">
      <div className="-ml-4 -mt-4 flex justify-center items-center flex-wrap sm:flex-nowrap">
        <div className="ml-4 mt-5 text-center">
          <p className="text-lg">{emoji}</p>
          <h3 className="text-lg leading-5 font-medium text-gray-900">{title}</h3>
          <p className="mt-1 text-sm text-gray-500">
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  )
}
