import React, { Component, useState } from "react";
import Header from "../components/Shared/Header";
import PageError from "../components/PageError";
import Dashboard from "../components/Layouts/Dashboard";

const ComingSoon = () => {


  return (
    <Dashboard header={<Header />}>

        <div className="w-5/6 mx-auto">
          <PageError emoji="😃" title="Hey! This feature is coming soon." subtitle={
                <>We are working actively on getting you rich contents. Please check back later.</>
            } />
        </div>

    </Dashboard>
  );
};

export default ComingSoon;
