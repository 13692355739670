import React, { Component, useState } from "react";
import Header from "../../components/Shared/Header";
import PageError from "../../components/PageError";
import Dashboard from "../../components/Layouts/Dashboard";

const LoginNotice = () => {


  return (
    <Dashboard header={<Header />}>

        <div className="w-5/6 mx-auto">
          <PageError title="You are not logged in!" subtitle={
                <>You must be logged in to access this page. Please <a href="login">login</a> or <a href="register">register</a> to continue.</>
            } />
        </div>

    </Dashboard>
  );
};

export default LoginNotice;
