import React, { Component } from "react";
import Header from "../../components/HomePage/Header";
import Footer from "../../components/HomePage/Footer";
import {
    PageHeaderContainer,
    PageHeaderText,
} from "../../components/Shared/PageHeader/pageheader.style";



const faqs = [
    {
        title: "What is NGL?",
        details: "We provide short, animated videos, worksheets and mock exams to teach the entire KS2 and 11+ Maths syllabus."
    },
    {
        title: "What makes NGL different?",
        details: "We are the <strong>first</strong> online platform who can confidently say we teach <strong>every topic</strong> in the KS2 and 11+ Maths syllabus. Our videos are short, engaging and provide an exceptional learning experience."
    },
    {
        title: "Does NGL follow the national curriculum?",
        details: "Yes! However, we have changed the order in which topics in the national curriculum are taught so that your child will pass. For instance, through the national curriculum, you will learn algebra in Year 6, after the 11+ exam has already taken place! With NGL, algebra is one of the first topics you learn. "
    },
    {
        title: "Will NGL actually make my child pass? ",
        details: "Yes! If you use NGL consistently, we are <strong>very confident</strong> your child will pass!"
    },
    {
        title: "What age can my child start using NGL? ",
        details: "We recommend your child starts using NGL from Year 3 onwards. However, we are confident our animated videos are clear enough for <strong>any child to understand</strong>, as long as they can count! "
    },
    {
        title: "My child has some foundation, where do we start with NGL?",
        details: "We strongly recommend starting with all of the “Number” sections, as this will set the foundation for the rest of the syllabus. "
    },
    {
        title: "Can my child use NGL on their own?",
        details: "Yes! We had a 9 year old learn algebra on his own, using our videos in just <strong>five weeks</strong> - look at our testimonial from Amanda on the homepage. However, you are free to use the platform with your child as we have also designed it for parents to learn alongside their child."
    },
    {
        title: "How do you recommend we use NGL?",
        details: "We strongly suggest you cover the content in our recommended order. By completing four videos and worksheets a week, you can cover the <strong>entire syllabus in 8 months</strong>."
    },
    {
        title: "How much does NGL cost?",
        details: "Our pricing plans can be viewed at the bottom of the home page."
    },
    {
        title: "My child has gaps in their knowledge. Can NGL solve this? ",
        details: 'Yes! If your child is struggling in a particular area, you can use our <strong><a href="/glossary">Glossary</a></strong> to find all of the content covering that area.'
    },
    // {
    //   title: "What is NGL? ",
    //   details: "We provide short, animated videos, worksheets and mock exams to teach the entire KS2 and 11+ Maths syllabus."
    // },
]
export default function App({ title, children }) {

    return (
        <>
            <Header />
            <div className="pt-12" />
            <main className="container mx-auto min-h-screen">
                {title && (
                    <main className="">
                        <h1 class="text-2xl font-extrabold text-gray-900 sm:leading-none sm:tracking-tight ">{title}</h1>

                    </main>
                )}
                <main className="min-h-screen my-10 ">
                    {children}
                </main>

            </main>

            <Footer />
        </>
    );
}
