import React from 'react';
import books from "../../img/books.jpg";

export default function GlossaryHeader(props) {
  return (
    <div className="relative bg-blue-800">
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover"
          src={books}
          alt=""
        />
        <div className="absolute inset-0 bg-blue-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-12 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl mb-0">Glossary</h1>
        {/* <p className="mt-6 text-xl text-blue-100 max-w-3xl">
          Search for topics, videos, courses, etc.
        </p>
 */}
        {/* <form onSubmit={() => props.toSearch(props.search)} >
          <main className="flex flex-wrap w-full">
            <input
              type="text"
              className="form__border w-full md:w-2/5 p-2"
              placeholder="Search for Topics. E.g Decimals"
              onChange={(e) => props.setSearch(e.target.value)}
            />
            <StyledButton
              className="ml-2"
              type="submit"
              borderRadius="3px"
              backgroundColor="#286cff"
              disabled={props.loading ? true : false}
            >
              {props.loading ? <Loader /> : "Search"}
            </StyledButton>
          </main>
        </form> */}

      </div>
    </div>
  )
}
