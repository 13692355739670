import React from 'react';

import { useHistory } from "react-router-dom";
import { renderImage } from "../../utils/helpers";

export default function CategoryCard({ exams }) {
  const history = useHistory();

  return (
    <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
      {exams.map((item, index) => (
        <div key={item.id} className=""
          onClick={() =>
            history.push(`/exams/${item.id}`)
          }
        >
          <div className="aspect-w-4 w-full h-72 rounded-lg overflow-hidden  relative">
            <img src={renderImage(item.cover_image_url, 'image')} alt="" className="h-full w-full z-0 object-center object-cover" />
            <div className="flex items-end opacity-100 p-4 group-hover:opacity-100 z-20 absolute bottom-0 w-full" aria-hidden="true">
              <a
                href={`/exams/${item.id}`}
                className="w-full bg-white  hover:bg-blue-500 hover:text-white backdrop-filter backdrop-blur py-2 px-4 rounded-md text-sm font-medium text-gray-900 text-center">
                View Papers
              </a>
            </div>
          </div>
          <div className="mt-4  text-center  text-blue-500 hover:text-blue-600 justify-between text-base font-medium text-gray-900 space-x-8">
            <h3>
              <a href={`/exams/${item.id}`}>
                {/* <span aria-hidden="true" className="absolute inset-0" /> */}
                {item.name}
              </a>
            </h3>
          </div>
        </div>
      ))}
    </div>
  )
}
