import React, { Component, useState, useEffect } from "react";
import Header from "../../components/Auth/Header";
import Side from "../../components/Auth/Side";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { StyledProgress } from "../../components/Auth/progress.style";
import Loader from "../../components/Shared/Loader";
import verify from "../../img/verify.png";
import messages from "../../img/messages.svg";
import register_mini from "../../img/register-mini.png";
import "./authentication.css";
import OtpInput from "react18-input-otp";
import { api } from "../../utils/api";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Button from "../../components/Shared/Button";

const header = {
  text: "Login",
  side: "Already have an account?",
  route: "/login",
};


const VerifyPasswordToken = (props) => {
  const [timeLeft, setTimeLeft] = useState(59);
  const [sideNav, setSideNav] = useState({
    name: "Tunde Adebanjo",
    quote:
      "The ease of having access to different subjects at any point in time is all the reason you need to be a part of this community.",
    backgroundImage: verify,
    profile: register_mini,
  });

  const [token, setToken] = useState("");
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    setEmail(localStorage.getItem("password-reset-email"));
  }, [])

  const handleChange = (otp) => {
    setToken(otp);
  };
  const resetTimer = () => {
    setTimeLeft(59);
    mutateResend({ email });
  };

  const { mutate, isLoading } = useMutation((payload) => verifyUser(payload), {
    onError: (error, variables, context) => {
      toast.error(error.response.data.message);
      setLoading(false);
    },
    onSuccess: (data, variables, context) => {
      toast.success("Token verified successfully!");
      setLoading(false);
      localStorage.setItem("password-reset-token", token)
      props.history.push("/update-password");
    },
  });

  const { mutate: mutateResend, isLoading: mutateLoading } = useMutation(
    (payload) => resendToken(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        toast.success("Token successfully sent to your email!");
        setLoading(false);
      },
    }
  );

  const verifyUser = async (data) => {
    try {
      setLoading(true);
      return api.post("/auths/verify-password-token", data);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const resendToken = async (data) => {
    try {
      setLoading(true);
      return api.post("/auths/forgot-password", data);
    } catch (e) {
      setLoading(true);
      toast.error("An error occurred");
      return e;
    }
  };

  const handleSubmit = (event) => {
    mutate({ email, token });
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
  return (
    <div className="authentication min-h-screen flex flex-wrap w-full">
      <main className="w-full hidden xl:block xl:w-1/3">
        <Side data={sideNav} />
      </main>
      <main className="w-full xl:w-2/3">
        <Header data={header} />
        <main className="mt-8 w-4/5 mx-auto xl:px-32">
          <p className="form__header">Verify</p>
          <p className="">
            Please enter the verification code sent to {email}
          </p>
          <main className="unfilled__progress rounded mt-4">
            <StyledProgress width="100%" />
          </main>

          <main className="mt-8">
            <img className="my-2 block" src={messages} />
            <main className="my-8">
              <p className="form__label">Verification Code</p>
              <OtpInput
                value={token}
                onChange={handleChange}
                numInputs={6}
                inputStyle={"form__border__mini"}
                separator={<span> </span>}
              />
              {timeLeft > 0 ? (
                <p className="form__muted my-2">
                  Request new verification code in: {" "}
                  <span className="yellow__text">00:{timeLeft}</span>
                </p>
              ) : (
                <p
                  onClick={() => {
                    resetTimer();
                  }}
                  className="form__muted my-2 cursor-pointer text-blue-500 underline"
                >
                  Resend Code
                </p>
              )}
              {/* <p className="form__muted my-2">
                or you can choose to get verification code through your number
                by <span className="blue__text"> clicking here</span>
              </p> */}
            </main>
            <main className="w-full mt-16 px-2">
              <Button onClick={handleSubmit} className="w-full mt-3" disabled={loading ? true : false}>
                {loading ? <Loader /> : "Continue"}
              </Button>
            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default VerifyPasswordToken;
