import React, { Component } from "react";
import Header from "../../components/HomePage/Header";
import Landing from "../../components/HomePage/Landing";
import PopularTopics from "../../components/HomePage/PopularTopics";
import Benefits from "../../components/HomePage/Benefits";
import Pricing from "../../components/HomePage/Pricing";
import BeforeFooter from "../../components/HomePage/BeforeFooter";
import Footer from "../../components/HomePage/Footer";
import Discussion from "../../components/HomePage/Discussion";
import WhyTheBest from "../../components/HomePage/WhyTheBest";
import Features from "../../components/HomePage/Features";
import Testimonials from "../../components/HomePage/Testimonials";
import Support from "../../components/HomePage/Support";
import PremiumTuition from "../../components/HomePage/PremiumSupport";

export default class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        <Landing />
        {/* <PopularTopics /> */}
        <Features />
        <Support />
        <PremiumTuition />

        <Testimonials />

        {/* <Benefits /> */}
        <Pricing />
        {/* <Discussion /> */}
        {/* <BeforeFooter /> */}
        <Footer />
      </div>
    );
  }
}
