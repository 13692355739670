import React, { Component, useState, useContext, useEffect } from "react";
import Header from "../../components/Shared/Header";
import { withRouter } from "react-router-dom";
import { api, get, post } from "../../utils/api";
import { AuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CourseCard from "./CourseCard";
import CardsSkeleton from "../../components/CardsSkeleton";
import Dashboard from "../../components/Layouts/Dashboard";
import SectionTitle from "../../components/SectionTitle";
import { coursesService } from "../../services/coursesService";

const Theory = (props) => {
  const history = useHistory();

  const { user, token } = useContext(AuthContext);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchCourses()
  }, [])

  const fetchCourses = async () => {
    setLoading(true);

    const response = await coursesService.fetchList();

    if (response.status) {
      setCourses(response.data.courses);
      setLoading(false);
    }
  }

  const handleClick = (route) => {
    history.push(route);
  };
  return (
    <Dashboard header={<Header />}>


      <div className="py-2" />

      <SectionTitle title="Videos & Worksheets" subtitle={<ul><li>This tab contains videos and worksheets for the entire 11+ Maths syllabus. </li><li>We have numbered each section of the syllabus. This is the order we would like you to follow! </li><li>If you don’t want to follow the recommended order, we <strong>strongly recommend</strong> your child covers the four “Number” sections first, as this provides the foundation for every other topic.</li></ul>} />
      {/* subtitle={<>Explore the world of exciting mathematics topics 🎉</>} /> */}

      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 py-8 pt-4">
        <div className="flex items-center justify-between space-x-4">
          <h2 className="text-lg font-medium text-gray-900">Select a Section</h2>
        </div>

        {loading ? <CardsSkeleton /> : (
          <CourseCard courses={courses} />
        )}
      </div>
    </Dashboard>
  );
};

export default withRouter(Theory);
