import React, { useState } from "react";
import Header from "../../components/Auth/Header";
import Side from "../../components/Auth/Side";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { StyledProgress } from "../../components/Auth/progress.style";
import Loader from "../../components/Shared/Loader";
import register from "../../img/register.png";
import register_mini from "../../img/register-mini.png";
import "./authentication.css";
import { api } from "../../utils/api";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Button from "../../components/Shared/Button";

const Reset = (props) => {
  const [header, setHeader] = useState({
    text: "Create new account",
    side: "Sign Up",
    route: "/register",
  });
  const [sideNav, setSideNav] = useState({
    name: "Tunde Adebanjo",
    quote:
      "The ease of having access to different subjects at any point in time is all the reason you need to be a part of this community.",
    backgroundImage: register,
    profile: register_mini,
  });
  const [loading, setLoading] = useState(false);

  const resetPassword = async (data) => {
    try {
      setLoading(true);
      return api.post("/auths/forgot-password", data);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const { mutate, isLoading } = useMutation(
    (payload) => resetPassword(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        toast.success(data.data.message + '. Please check your mail inbox or SPAM mail for a password token.');
        props.history.push("/verify-token");
        setEmail("");
        localStorage.setItem("password-reset-email", email)
        setLoading(false);
      },
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault()
    mutate({ email });
  };

  const [email, setEmail] = useState("");

  return (
    <div className="authentication min-h-screen flex flex-wrap w-full">
      <main className="w-full hidden xl:block xl:w-1/3">
        <Side data={sideNav} />
      </main>
      <main className="w-full xl:w-2/3">
        <Header data={header} />
        <main className="mt-8 w-4/5 mx-auto xl:px-32">
          <p className="form__header">Forgot Username/Password</p>
          <p className="">
            Enter your email and a token will be sent your mailbox
          </p>
          <main className="unfilled__progress rounded mt-4">
            <StyledProgress width="100%" />
          </main>

          <form onSubmit={handleSubmit} className="flex flex-wrap mt8 ">
            <main className="w-full my-2 px-2">
              <p className="form__label">
                Email<span className="text-red-500">*</span>
              </p>
              <input
                type="email"
                className="form__border w-full my-2"
                placeholder="e.g mail@mathematica.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </main>

            <main className="my-3 w-full">
              <Button className="w-full" disabled={loading ? true : false}>
                {loading ? <Loader /> : "Send Reset Link"}
              </Button>
            </main>
          </form>
        </main>
      </main>
    </div>
  );
};

export default Reset;
