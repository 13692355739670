import React, { Component, useEffect, useState } from "react";
import Header from "../../components/Shared/Header";
import "./index.css";
import Sidebar from "./Sidebar";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post, put } from "../../utils/api";
import { toast } from "react-toastify";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import { AuthContext } from "../../contexts/AuthContext";
import Button from "../../components/Shared/Button";
import { userServices } from "../../services/userServices";


const Dashboard = (props) => {

  const [userData, setUserData] = useState(userServices.getUser());
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(AuthContext);


  useEffect(() => {
    // console.log({ user, userData })
  }, [])

  const handleUpdateProfile = async (data) => {

    const response = await put("/users/profile/update", data, true);

    console.log({ response })
    if (response.status) {
      toast.success("Profile updated successfully!");
      setUser(response.data.user);
      userServices.setUser(response.data.user)
    } else {
      toast.error("Error updating profile");
    }

    setLoading(false);
  }

  const handleChange = async (e, key) => {
    e.preventDefault();
    const newUserData = { ...userData, [key]: e.target.value };
    setUserData(newUserData);
    // userServices.setUser(newUserData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstname, lastname } = userData;
    handleUpdateProfile({ firstname, lastname })
  };


  // console.log({user})
  return (
    <div className="account__page ">
      <Header />
      <main className="w-full ">
        <main className=" my-8 max-w-7xl mx-auto flex flex-wrap boxed__container top__wrapper ">
          <main className="w-full md:w-1/5 border">
            <Sidebar activeMenu="account" />
          </main>
          <main className="w-full md:w-4/5 border">
            <main className="mx-auto">
              <form onSubmit={handleSubmit} className="w-full mx-auto md:w-1/2 my-2 px-2">
                <p className="my-8"></p>
                <p className="text-center page__header">Edit Profile Details</p>
                <p className="text-center sub__header">
                  Edit your account settings
                </p>
                <p className="my-8"></p>

                <main className="my-2">
                  <p className="form__label">First Name</p>
                  <input
                    type="text"
                    placeholder="e.g Ademola"
                    value={userData.firstname}
                    onChange={(e) => handleChange(e, "firstname")}
                    className={`form__border w-full my-2 `}
                  />
                </main>

                <main className="my-2">
                  <p className="form__label">Last Name</p>
                  <input
                    type="text"
                    placeholder="e.g Ademola"
                    value={userData.lastname}
                    onChange={(e) => handleChange(e, "lastname")}
                    className={`form__border w-full my-2 `}
                  />
                </main>
                {/* 
                <main className="w-full ">
                  <p className="form__label">
                    Location<span className="text-red-500">*</span>
                  </p>
                  <select
                    value={user.location}
                    className="form__border w-full my-2 "
                    onChange={(e) => handleChange(e, "location")}
                  >
                    <option>Select an option</option>
                    {states.map((sta, i) => {
                      return (
                        <option key={i} value={sta}>
                          {sta}
                        </option>
                      );
                    })}
                  </select>
                </main> */}

                <main className="w-full  my-3">
                  <Button
                    className="w-full"
                    onClick={handleSubmit}
                    disabled={loading}
                  >

                    {loading ? <Loader /> : "Update Profile"}
                  </Button>
                </main>
              </form>
            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Dashboard;
