import React from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from 'react-router-dom';

const CookieNotice = ({ chartData }) => {
    return (
        <CookieConsent
            location="bottom"
            enableDeclineButton
            buttonText="Accept"
            declineButtonText="Reject"
            cookieName="ngl_cookie"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            Our site uses cookies. For more information, see our <a href="cookie-policy" className='underline'>cookie policy</a>.{" "}
            {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
        </CookieConsent>
    );
};

export default CookieNotice;