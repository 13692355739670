import React, { Component, useState } from "react";
import "./document.css";
import { StyledButton } from "../../Shared/Button/button.style";
import Loader from "../../Shared/Loader";
import ReactPaginate from "react-paginate";
import { withRouter } from "react-router";
import { pdfjs, Document, Page } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentWrapper = (props) => {
  const route = props.route || "courses";
  // const questionPDF = props.document.question_pdf;
  // const solutionPDF = props.document.solution_pdf;
  // // console.log(props.document)
  const pdf = props.document;
  const [isDocument, setIsDocument] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // const switchDocumentType = () => {
  //   setIsDocument(!isDocument)
  // };

  const handlePageClick = (event) => {
    setPageNumber(event.selected + 1);
  };

  return (
    <main className="quiz__wrapper p-6 w-full rounded shadow w-full">
      {/* <main className="flex justify-end w-full">
        <StyledButton
          type="submit"
          borderRadius="3px"
          width="200px"
          backgroundColor="#286cff"
          onClick={() => {
            setIsDocument(!isDocument);
          }}
        >
          Switch Document
        </StyledButton>
      </main> */}
      {pdf?.allow_download && (
        <main className="flex justify-end w-full">
          <StyledButton
            type="submit"
            borderRadius="3px"
            width="200px"
            backgroundColor="#286cff"
            onClick={() => {
              window.open(pdf.url, '_blank');
            }}
          >
            Download PDF
          </StyledButton>
        </main>
      )}
      <main className="flex justify-center w-full py-4 text-center">
        <main>
          <Document
            file={pdf}
            // renderMode="svg"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} scale={1.5} />
          </Document>

          <div className="mt-4">
            <ReactPaginate
              className="m-4 p-4"
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"inline mx-2 border px-1 pagination__bg"}
              previousClassName={"inline mx-2 border w-12 px-1 pagination__bg"}
              nextClassName={"inline mx-2 border px-1 pagination__bg"}
              pageCount={numPages}
              // marginPagesDisplayed={1}
              // pageRangeDisplayed={1}
              pageClassName={"inline mx-2 border px-1 pagination__bg"}
              onPageChange={handlePageClick}
              containerClassName={"="}
              activeClassName={"bg-blue-200"}
            />
          </div>
        </main>


        {/* {isDocument ? (
          <main>
            <Document
              file={questionPDF?.url}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>

            <div className="mt-4">
              <ReactPaginate
                className="m-4"
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"inline mx-2 border px-1 pagination__bg"}
                previousClassName={"inline mx-2 border w-12 px-1 pagination__bg"}
                nextClassName={"inline mx-2 border px-1 pagination__bg"}
                pageCount={numPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                pageClassName={"inline mx-2 border px-1 pagination__bg"}
                onPageChange={handlePageClick}
                containerClassName={"="}
                activeClassName={"active"}
              />
            </div>
          </main>
        ) : (
          <main>
            <Document
              file={solutionPDF?.url}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>

            <div className="mt-4 text-center">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"inline mx-2 border px-1 pagination__bg"}
                previousClassName={"inline mx-2 border w-12 px-1 pagination__bg"}
                nextClassName={"inline mx-2 border px-1 pagination__bg"}
                pageCount={numPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                pageClassName={"inline mx-2 border px-1 pagination__bg"}
                onPageChange={handlePageClick}
                containerClassName={"="}
                activeClassName={"active"}
              />
              </div>
          </main>
        )} */}
      </main>
    </main>
  );
};
export default withRouter(DocumentWrapper);
