import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { post } from '../../utils/api'
import { toast } from 'react-toastify'
import { useState } from 'react'
import './attachment.css';
import { useEffect } from 'react'

const Attachments = (props) => {

  const [uploadedFiles, setUploadedFiles] = useState([])

  useEffect(() => {
    if (props.deleteAll) {
      reset()
    }
  }, [props])


  const getUploadParams = async ({ file, meta }) => {
    const { name, type } = meta;
    // // console.log({meta})
    const response = await post(`/services/file/presign-url/?file_name=${name}&file_type=${type}`, {}, true)

    if (response.status) {
      let { signedRequest, url } = response.data.data;

      return { method: 'PUT', body: file, meta: { fileUrl: url }, url: signedRequest }
    }
  }

  const handleChangeStatus = ({ meta, file, ...rest }, status) => {
    const fileRemove = rest.remove;

    if (status == 'done') {

      const newFile = {
        name: meta.name,
        type: meta.type,
        size: meta.size,
        url: meta.fileUrl
      };

      props.onUploadFile(newFile)

      setUploadedFiles(fids => [...fids, {
        name: file.name,
        remove: fileRemove
      }]);

    }

    if (status === "removed") {
      setUploadedFiles(fids => fids.filter(item => item.name !== file.name));
    }
  }

  const reset = () => {
    uploadedFiles.forEach(f => f.remove())
    // @todo: you also need to clear state
  }


  return (
    <>
      {/* <button onClick={reset}>clear</button> */}
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept="image/*,audio/*,video/*"
        style={{ width: "20%" }}
        maxFiles={4}
        // initialFiles={props.files ?? []}
        addClassNames='flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'
      />
    </>

  )
}

export default Attachments;