
import React, { Component, useEffect, useState } from "react";
import Header from "../../components/Shared/Header";
import { api, get, post, handleError } from "../../utils/api";
import "./index.css";
import { toast } from "react-toastify";
import Dashboard from "../../components/Layouts/Dashboard";
import CardsSkeleton from "../../components/CardsSkeleton";
// import Document from "./Document";
import CourseSideMenu from "../../components/Course/SideMenu";
import Document from "../../components/Course/Document";
import PDFViewer from "../../components/PDFViewer";

const ExamsList = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [exams, setExams] = useState(null);
  const [activeExam, setActiveExam] = useState({});
  const [loading, setLoading] = useState(true);
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);

  const activeExamId = new URLSearchParams(window.location.search).get('lesson');



  useEffect(() => {
    fetchExams();
  }, [])

  // // console.log({id: props.match.params})
  const fetchExams = () => {

    setLoading(true);

    get(`/exams/category/${props.match.params.categoryId}/fetch-all`, true).then((res) => {

      const examsList = res.data.data;

      // console.log({ examsList })
      setExams(examsList);

      if (activeExamId != null) {
        let _activeExam = examsList?.exams?.filter((lesson) => lesson._id == activeExamId);

        // console.log({ _activeExam })
        if (_activeExam.length > 0) {
          // setActiveC(activeLesson[0]);
          let curIndex = examsList.exams?.findIndex(lesson => lesson._id == activeExamId);
          // // console.log({curIndex})
          // setActiveCourseindex(curIndex);
        } else {
          setActiveExam(examsList.exams[0]);
        }
        // setHasFetched(false);
      } else if (Object.keys(activeExam).length === 0) {
        setActiveExam(examsList.exams[0]);
      } else {
        setActiveExam(examsList?.exams[0])
      }
      setLoading(false);
    }).catch((e) => {
      toast.error('Error fetching exam contents');
      setLoading(false);
    })
  }

  const handleLessonChange = (lesson) => {
    // // console.log({lesson})
    setActiveExam(lesson);
  };


  return (
    <Dashboard header={<Header />} isWide>
      <div className=" m-10 ">
        {loading ? <CardsSkeleton /> : (
          <main className=" w-full">
            <main className="flex flex-wrap  ">
              <div className="flex w-full bg-black p-6 justify-between">
                <main className="text-white">Category: {exams ? exams.category?.name : ''}</main>
                <main className="flex">
                </main>
              </div>
              <main className="w-full md:w-1/5 border">
                <CourseSideMenu
                  activeLessonId={activeExam?.id}
                  lessons={exams.exams}
                  route="exams"
                  onClickItem={(lesson) => {
                    handleLessonChange(lesson);
                  }}
                />

                {/* <ExamsSideMenu
                  activeLessonId={activeExam?.id}
                  lessons={exams.exams}
                  route="exams"
                  onClickItem={(lesson) => {
                    handleLessonChange(lesson);
                  }} 
                />*/}
              </main>
              <main className="w-full min-h-screen md:w-4/5 border">
                {activeExam?.url ? (
                  <PDFViewer url={activeExam?.url} allowDownload={activeExam?.allow_download} />
                ) : <div className="my-20 text-center flex justify-center"><p>Select a paper on the left sidebar</p></div>}
                {/* <Document route="document" document={activeExam?.url} /> */}
              </main>
            </main>
          </main>
        )}
      </div>
    </Dashboard>


  );
};

export default ExamsList;
