import React, { Component, useState } from "react";
import "./quiz.css";
import { StyledButton } from "../../Shared/Button/button.style";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

const Quiz = (props) => {
  const question = props.question;
  const [answer, setAnswer] = useState(null);
  const [hasSelectedAnswer, setHasSelectedAnswer] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  const handleSelect = (e, option) => {
    e.preventDefault();
    setHasSelectedAnswer(true);
    setAnswer(option);
    setActiveOption(option);
    if (!hasSelectedAnswer) {
      if (option.isCorrect) {
        props.addScore();
      } else {
        props.addIncorrect();
      }
    }
  };

  return (
    <main className="quiz__wrapper p-6 w-full  w-full">
      <main className="w-full p-4">
        {activeOption && (
          <main
            className={`w-56 p-4 mx-auto border rounded ${activeOption && activeOption.isCorrect
              ? "border-green-300 bg-green-100 "
              : "border-red-300 bg-red-100"
              }`}
          >
            <p>{activeOption?.reason}</p>
          </main>
        )}
        <p className="question mb-6">{question.text}</p>
        {question.question_options.map((option, i) => (
          <main key={i} className="m-2 flex items-center w-full cursor-pointer">
            {/* <input type="radio" checked={answers[index]} />{" "} */}
            <p
              onClick={(e) => {
                handleSelect(e, option);
              }}
              className={`ml-2 border w-full p-2 option ${answer && answer.isCorrect && answer._id === option._id
                ? "border-green-300 border-4"
                : ""
                }  ${answer && !answer.isCorrect && answer._id === option._id
                  ? "border-red-300 border-4"
                  : ""
                }`}
            >
              {option.text}
            </p>
          </main>
        ))}
      </main>
    </main>
  );
};
export default withRouter(Quiz);
