
import React from "react";
import Skeleton from "react-loading-skeleton";
import TrendingCard from "../../components/Forum/Thread/TrendingCard";


export default function Trending({ trendingData, loading }) {

  return (
    <div className="pl-6 lg:w-80">
      <div className="pt-6 pb-2">
        <h2 className="text-sm font-semibold">Trending Topics</h2>
      </div>
      <div>
        {loading ? (
          <Skeleton className="mt-4" count={5} />
        ) : (
          <ul role="list" className="-my-4 divide-y divide-gray-200">
            {trendingData.length > 0 ? trendingData.map((post) => (
              <TrendingCard post={post} />
            )) : <p className="mt-4 text-gray-500 text-sm">No trending topic at the moment. </p>}
          </ul>
        )}
      </div>
    </div>
  )
}