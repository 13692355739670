import "./pricing.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "../../Shared/Button";
import starter from "../../../img/starter.svg";
import basic from "../../../img/basic.svg";
import professional from "../../../img/professional.svg";

import { StyledHeader } from "./pricing-header-style";
import { StyledButton } from "../../Shared/Button/button.style";
import { StyledCard } from "./pricing-card-style";
import Plans from '../../../views/Subscribe/Plans';
import SupportGroup from "../../../views/SupportGroupExplainer/SupportGroup";

export default class Home extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   subscriptions: [
    //     {
    //       name: "Basic Plan",
    //       amount: "£100",
    //       image: basic,
    //       color: "#009c2a",
    //       hasBorder: false,
    //       content: [
    //         "Lorem ipsum dolor sit amet",
    //         "Lorem ipsum dolor sit amet",
    //         "Lorem ipsum dolor sit amet",
    //       ],
    //     },
    //     {
    //       name: "Starter Plan",
    //       amount: "£300",
    //       color: "#FFAA00",
    //       image: starter,
    //       hasBorder: true,
    //       content: [
    //         "Lorem ipsum dolor sit amet",
    //         "Lorem ipsum dolor sit amet",
    //         "Lorem ipsum dolor sit amet",
    //       ],
    //     },
    //     {
    //       name: "Professional Plan",
    //       amount: "£500",
    //       image: professional,
    //       color: "#9C0025",
    //       hasBorder: false,
    //       content: [
    //         "Lorem ipsum dolor sit amet",
    //         "Lorem ipsum dolor sit amet",
    //         "Lorem ipsum dolor sit amet",
    //       ],
    //     },
    //   ],
    // };
  }

  render() {
    // const { subscriptions } = this.state;
    return (
      <main className="mb-8 py-10">
        <div className=" py-12 ">
          <main className="boxed__container pb-32 max-w-6xl mx-auto">
            <p className="mini__header text-4xl font-bold mx-auto w-full text-center px-5">
              Pricing and Subscription
            </p>
            <p className="my-3 text-gray-800 mx-auto w-full text-center">
              Choose a plan that works for you
            </p>

            <Plans />

          </main>
        </div>
      </main>
    );
  }
}
