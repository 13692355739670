
export default function Overview({ title, subtitle, content = null }) {
  return (
    <div className="bg-white overflow-hidden min-height-full">
      <div className="px-2 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        {subtitle &&
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{subtitle}</p>
        }
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="text-sm font-medium text-gray-500">{content ?? "No overview available for this topic"}</div>
      </div>
    </div>
  )
}
