import React, { useState } from "react";
import Header from "../../components/Auth/Header";
import Side from "../../components/Auth/Side";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { StyledProgress } from "../../components/Auth/progress.style";
import register from "../../img/register.png";
import register_mini from "../../img/register-mini.png";
import Loader from "../../components/Shared/Loader";
import "./authentication.css";
import { api } from "../../utils/api";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { passwordCheck } from "../../utils/helpers";
import PasswordInput from "../../components/PasswordInput";

const Reset = (props) => {
  const [header, setHeader] = useState({
    text: "Create new account",
    side: "Sign Up",
    route: "/register",
  });
  const [sideNav, setSideNav] = useState({
    name: "Tunde Adebanjo",
    quote:
      "The ease of having access to different subjects at any point in time is all the reason you need to be a part of this community.",
    backgroundImage: register,
    profile: register_mini,
  });
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const token = localStorage.getItem('password-reset-token');
  const email = localStorage.getItem('password-reset-email');

  const resetPassword = async (data) => {
    // // console.log({data})
    try {
      setLoading(true);
      return api.post("/auths/change-password", data);
    } catch (e) {
      toast.error("An error occurred");
      return e;
    }
  };

  const { mutate, isLoading } = useMutation(
    (payload) => resetPassword(payload),
    {
      onError: (error, variables, context) => {
        toast.error(error.response.data.message);
        setLoading(false);
      },
      onSuccess: (data, variables, context) => {
        toast.success(data.data.message);
        // setEmail("");
        history.push('/login')
        localStorage.removeItem('password-reset-token');
        localStorage.removeItem('password-reset-email');
        setLoading(false);
      },
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!checkPassword()) {
      return false
    } else {
      mutate({ email, token, password });
    }

  };


  const checkPassword = () => {


    // let check = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,30}$/;

    if (password != confirm_password) {
      toast.error('Passwords do not match');
      return false;
    } else if (!passwordCheck(password)) {
      toast.error('Password requirements were not met. New password must contain: at least 6 letters, one number, and a special character e.g [?£$*?/#]()!', {
        delay: 3000
      });
      return false;
    }

    return true;
  }

  return (
    <div className="authentication min-h-screen flex flex-wrap w-full">
      <main className="w-full hidden xl:block xl:w-1/3">
        <Side data={sideNav} />
      </main>
      <main className="w-full xl:w-2/3">
        <Header data={header} />
        <form onSubmit={handleSubmit} className="mt-8 w-4/5 mx-auto xl:px-32">
          <p className="form__header">Update Password</p>
          <p className="">
            Enter your new password
          </p>
          <main className="unfilled__progress rounded mt-4">
            <StyledProgress width="100%" />
          </main>

          <main className="flex flex-wrap mt8 ">
            <main className="w-full my-2 px-2">
              <p className="form__label">
                Enter new Password<span className="text-red-500">*</span>
              </p>
              <PasswordInput
                type="password"
                className="form__border w-full my-2"
                placeholder="Enter New Password"
                min={8}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="form__label">
                Confirm Password<span className="text-red-500">*</span>
              </p>
              <PasswordInput
                type="password"
                className="form__border w-full my-2"
                placeholder="Confirm New Password"
                min={8}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </main>

            <main className="my-3">
              <StyledButton
                borderRadius="3px"
                width="100%"
                backgroundColor="#286cff"
                type="submit"
              >
                {loading ? <Loader /> : "Continue"}
              </StyledButton>
            </main>
          </main>
        </form>
      </main>
    </div>
  );
};

export default Reset;
