
import AdobePDFReader from "./AdobePDFReader"
import Button from './Button'


export default function PDFReader({ url, allowDownload = false }) {
    return (
        <>
            {allowDownload && (
                <main className="flex justify-end w-full p-3">
                    <Button
                        type="submit"
                        borderRadius="3px"
                        width="200px"
                        backgroundColor="#286cff"
                        onClick={() => {
                            window.open(url, '_blank');
                        }}
                    >
                        Download PDF
                    </Button>
                </main>
            )}
            <>
                <AdobePDFReader className="pdfReader" url={url} allowDownload={allowDownload} />
            </>
        </>
    )
}