import React from 'react';

import { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import { userServices } from "../../services/userServices";
import { api, post } from "../../utils/api";


export default function () {
  const { user, setUser, setToken } = useContext(AuthContext);
  const history = useHistory();

  useEffect(async () => {

    await userServices.logOut();

    // await setInactive();
    // setUser(null);
    // setToken(null);

    // toast.success("You have been successfully logged out!");
    // localStorage.clear();
    // history.push("/");
  }, []);

  const setInactive = async (data) => {
    try {
      // // console.log('here');
      // setLoading(true);
      let response = await post("/auths/set-inactive", null, true);
      // // console.log({response})

      if (response.data.status == 'success') {
        // setUser(null);
        await userServices.logOut();        // setToken(null);

        // toast.success("You have been successfully logged out!");
        // localStorage.clear();
        // setTimeout(() => {
        //   window.location.href = "/";
        // }, 300);

        // history.push("/");    
      }
    } catch (e) {
      // toast.error("An error occurred");
      // return e;
    }
  };


  return (<></>)
}