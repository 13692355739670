
import React, { Component, useContext, useState } from "react";

import { useForm } from "react-hook-form";
import { StyledButton } from "../../components/Shared/Button/button.style";
import Loader from "../../components/Shared/Loader";
import { api, get, post, handleError } from "../../utils/api";
import { toast } from "react-toastify";
import { useMutation, useQueries, useQuery } from "react-query";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";
import Button from "../../components/Shared/Button";
import Editor from "../../components/Editor";
import Uploader from "../../components/Uploader";
import Attachments from "./Attachments";



export default function NewPost(props) {

  const history = useHistory();


  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [bodyError, setBodyError] = useState(false);
  const [categories, setCategories] = useState(props.categories ?? []);



  const [attachments, setAttachments] = useState([]);

  const [deleteAllAttachments, setDeleteAllAttachments] = useState(false);

  const onSubmit = (event) => {
    //   // console.log({errors});
    setBodyError(false);
    // mutate({ title, body, category, attachments });
    let data = { title, body, category, attachments };

    createPost(data);
  };

  const createPost = async (data) => {
    try {
      setLoading(true);
      const res = await post("/forums/create-thread", data, true);
      setLoading(false);
      toast.success("Thread created successfully!");

      setInterval(() => {
        window.location.href = `/post/${res.data.thread._id}`;
      }, 1000);
    } catch (e) {
      setLoading(false);
      const message = await handleError(e);
      toast.error(message);
    }
  };

  const handleChange = (value) => {
    setBody(value);
  };

  const handleFilesUpload = (file) => {
    setAttachments([...attachments, file])
  }

  return (
    <>
      <p className="modal__header pt-2 text-center">Create a New Topic</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit);
        }}
      >
        <main className="flex flex-wrap w-full justify-center mt-4">
          <div className="w-full">
            <main className="w-full md:w-4/5 mx-auto my-4 ">
              <p className="modal__label my-1">Topic Title</p>
              <input
                type="text"
                placeholder="e.g Solving Fractions"
                {...register("title", { required: true, minLength: 2 })}
                className={`modal__form__border w-full my-2  w-full my-2 ${errors.title ? "red__form__border" : ""
                  }`}
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <p className="error__message">
                {errors &&
                  errors.title &&
                  errors.title.type === "required" &&
                  "Title is required"}
                {errors &&
                  errors.title &&
                  errors.title.type === "minLength" &&
                  "Title must be a minimum of 10 characters"}
              </p>
            </main>
          </div>
          <div className="w-full flex justify-center my-4">
            <main className="w-full mx-auto md:w-4/5">
              <p className="modal__label my-1">Select Category</p>
              <select
                onChange={(e) => setCategory(e.target.value)}
                value={category}
                required
                className={`modal__form__border w-full w-full my-2 ${errors.title ? "red__form__border" : ""
                  }`}
              >
                <option>Select a category</option>
                {categories.map((option) => (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </main>
          </div>
          <div className="w-full flex justify-center my-4">
            <main className="w-full mx-auto md:w-4/5">
              <p className="modal__label my-1">Topic details</p>
              <Editor
                className=" w-full mb-8 mx-auto h-40"
                value={body}
                onChange={handleChange}
              />

              <p className="error__message">
                {bodyError &&
                  "Details is required must be a minimum of 100 characters"}
              </p>
            </main>
          </div>
          <div className="w-full mx-auto md:w-4/5">
            <Attachments onUploadFile={handleFilesUpload} files={attachments} deleteAll={deleteAllAttachments} />
          </div>
          {/* <Uploader multiple uploadFiles={handleFilesUpload} uploadUrl={process.env.REACT_APP_API_URL + '/services/file/presign-url/'} /> */}
          <main className="w-full flex  mt-8 justify-center">
            <Button type='submit' onClick={onSubmit}>
              {loading ? <Loader /> : " Create Topic"}
            </Button>
          </main>
        </main>
      </form>

    </>
  )
}