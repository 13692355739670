import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./views/Home";
import Login from "./views/Auth/Login";
import Register from "./views/Auth/Register";
import UpdatePassword from "./views/Auth/UpdatePassword";
import Verify from "./views/Auth/Verify";
import VerifyToken from "./views/Auth/VerifyPasswordToken";
import Reset from "./views/Auth/Reset";
import About from "./views/About";
import Forum from "./views/Forum";
import Exams from "./views/Exams";
import ExamsList from "./views/Exams/list";
import ForumByCategory from "./views/Forum/byCategory";
import Chat from "./views/Chat";
import Post from "./views/Post";
import FAQs from "./views/FAQs";
import User from "./views/User";
import Challenges from "./views/Challenges";
import Challenge from "./views/Challenge";
import Glossary from "./views/Glossary";
import GlossarySearch from "./views/Glossary/search";
import Account from "./views/Account";
import UpdateProfilePassword from "./views/Account/password";
import Course from "./views/Course";
import Error from "./views/Error";
import Photo from "./views/Account/photo";
import Contact from "./views/Contact";
import Me from "./views/Account/view";
import Subscription from "./views/Account/subscription";
import AuthContextProvider from "./contexts/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import ProtectedRoute from "./components/ProtectedRoute";
import "react-toastify/dist/ReactToastify.css";
// import 'antd/dist/antd.css';
import "./index.css";

import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import Subscribe from "./views/Subscribe";
import Logout from "./views/Auth/Logout";
import NewPost from "./views/Forum/NewPost";
import Theory from "./views/Theory";
import Settings from "./views/Account/settings";
import LoginNotice from "./views/Auth/LoginNotice";
import CookiePolicy from "./views/CookiePolicy";
import MoneyBackGuarantee from "./views/MoneyBackGuarantee";
import Success from "./views/Subscribe/success";
import MonthlyTutorPlan from "./views/Subscribe/MonthlyTutorPlan";
import SupportGroupExplainer from "./views/SupportGroupExplainer";
import PremiumTuition from "./views/PremiumTuition";
import PremiumTuitionRegister from "./views/PremiumTuition/Register";



const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
function App() {
  return (
    <QueryClientProvider className="app" client={queryClient}>
      <ToastContainer
        autoClose={8000}
      />
      <AuthContextProvider>
        <BrowserRouter>
          <div className=" font-sans">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/update-password" component={UpdatePassword} />
              <Route path="/verify" component={Verify} />
              <Route path="/verify-token" component={VerifyToken} />
              <Route path="/reset" component={Reset} />
              <Route path="/new-post" component={NewPost} />
              <Route path="/about" component={About} />
              <Route path="/money-back-guarantee" component={MoneyBackGuarantee} />
              <Route path="/support-group" component={SupportGroupExplainer} />
              <Route path="/premium-tuition/register" component={PremiumTuitionRegister} />
              <Route path="/premium-tuition" component={PremiumTuition} />
              <Route
                path="/forum/category/:categoryId"
                component={ForumByCategory}
              />
              {/* <Route path="/forum" component={Forum} /> */}

              <Route path="/post/:postId" component={Post} />
              <Route path="/faqs" component={FAQs} />
              <Route path="/subscribe" component={Subscribe} />
              <Route path="/tutorplan" component={MonthlyTutorPlan} />
              <Route path="/contact" component={Contact} />

              <ProtectedRoute path="/user/:userId" component={User} />
              <ProtectedRoute path="/settings" component={Settings} />
              <ProtectedRoute path="/theory" component={Theory} />
              <ProtectedRoute
                path="/glossary/search/:tag"
                component={GlossarySearch}
              />
              <ProtectedRoute path="/glossary" component={Glossary} />
              <ProtectedRoute path="/account" component={Account} />
              <ProtectedRoute path="/password" component={UpdateProfilePassword} />
              <ProtectedRoute
                path="/challenge/:challengeid"
                component={Challenge}
              />
              <ProtectedRoute path="/challenge" component={Challenges} />
              {/* <ProtectedRoute path="/messages" component={Chat} /> */}
              <ProtectedRoute path="/course/:courseId" component={Course} />
              <ProtectedRoute path="/photo" component={Photo} />
              <ProtectedRoute path="/profile" component={Me} />
              <ProtectedRoute path="/subscription/success" component={Success} />
              <ProtectedRoute path="/subscription" component={Subscription} />
              <ProtectedRoute path="/exams/:categoryId" component={ExamsList} />
              <ProtectedRoute path="/exams" component={Exams} />
              <ProtectedRoute path="/logout" component={Logout} />
              <Route path="/cookie-policy" component={CookiePolicy} />
              <Route path="/login-notice" component={LoginNotice} />
              <Route path="not-found" exact={true} component={Error} />
              <Route path="*" exact={true} component={Error} />
            </Switch>
          </div>
        </BrowserRouter>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
