import React from "react";
import { useHistory } from "react-router-dom";

export default function Button({ className, disabled = false, children, outline, onClick, href, type, size = "text-base", padding = "px-6 py-3" }) {
    const history = useHistory();

    function handleClick(route) {
        history.push(route);
    }

    return <button disabled={disabled} type={type} onClick={href ? () => handleClick(href) : onClick} className={`cursor-pointer inline-flex justify-center ${size} font-medium rounded-md ${outline ? ' bg-white text-blue-500  border-blue-500 border border-transparent' : ' bg-blue-500 text-white '}  ${className} ${padding}`}>{children}</button>;
}
