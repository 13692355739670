
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import React, { useEffect, useRef, useState } from 'react';

export default function PasswordInput({
    type = 'text',
    name,
    value,
    className,
    autoComplete,
    required,
    isFocused,
    onChange,
    secondary = false,
    // defaultValue='',
    onBlur,
    label = '',
    id = '',
    ref,
    placeholder = '',
    show = false,
}) {
    const input = useRef();

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setShowPassword(show)
    }, [show])

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);

    return (
        <div className="flex flex-col items-start mb-3 relative">
            {label &&
                <label htmlFor={id ? id : name} className="block text-sm font-medium mb-1 text-gray-700">
                    {label}
                </label>
            }
            <input
                type={showPassword ? 'text' : 'password'}
                name={name}
                value={value}
                className={
                    `border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 rounded-md shadow-sm ` +
                    className
                }
                ref={ref ? ref : input}
                autoComplete={autoComplete}
                required={required}
                onChange={(e) => onChange(e)}
                placeholder={placeholder}
                onBlur={onBlur}
            // defaultValue={defaultValue}         
            />
            <div className="absolute inset-y-0 right-0 p-3 bg-dark z-50 cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ?
                    <EyeIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                    :
                    <EyeOffIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />}
            </div>
        </div>
    );
}