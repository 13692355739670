import React, { Component, useEffect, useState } from "react";
// import Header from "../../components/Shared/Header";
import "./Glossary.css";
import { StyledButton } from "../../components/Shared/Button/button.style";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Shared/Loader";
import { api, get, post } from "../../utils/api";
import { useMutation, useQueries, useQuery } from "react-query";
import Header from "../../components/Shared/Header";
import Dashboard from "../../components/Layouts/Dashboard";
import CardsSkeleton from "../../components/CardsSkeleton";
import GlossaryHeader from './GlossaryHeader';
import GlossaryList from './GlossaryList';
import { toast } from "react-toastify";

const Glossary = (props) => {
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [lessons, setLessons] = useState([]);
  const [glossary, setGlossary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchGlossaries();
  }, [])

  const fetchGlossaries = () => {
    setLoading(true);
    get("/courses/glossary", true).then((res) => {

      setGlossary(res.data.glossary);
      setLoading(false);

    }).catch(e => {
      setLoading(false);
    })
  }

  const toSearch = (item) => {
    props.history.push(`glossary/search/${item}`);
  };

  return (
    <>
      <Header />
      <Dashboard isWide>

        <GlossaryHeader search={search} setSearch={setSearch} toSearch={toSearch} />


        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 pb-10">

          {loading ? <CardsSkeleton /> : (
            <GlossaryList list={glossary} toSearch={toSearch} />
          )}
        </div>
      </Dashboard>

    </>

  );
};

export default Glossary;
