import React from 'react';
import { CheckIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { subscriptionServices } from '../../services/subscriptionServices';
import { userServices } from '../../services/userServices';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Plan({ tier, index }) {

    const history = useHistory();

    const token = userServices.getToken();
    const user = userServices.getUser();

    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [userHasSubscription, setUserHasSubscription] = useState(false);

    useEffect(() => {
        getUserSubscription();
        fetchSubscriptions();
    }, []);


    const fetchSubscriptions = async () => {
        setLoading(true);

        const response = await subscriptionServices.getPlans();

        if (response.data?.products) {
            if (await subscriptionServices.checkUsedTrialSubscription()) {
                setPlans(response.data.products.reverse().filter((item) => item.amount != 0));
            } else {
                setPlans(response.data.products.reverse());
            }

            setLoading(false);
        }
    }

    const getUserSubscription = async () => {
        if (userServices.isLoggedIn()) {
            const response = await subscriptionServices.checkSubscription();

            if (response.data?.subscription?.is_active && response.data?.subscription?.past_due == false) {
                setUserHasSubscription(true)
            }

        }
    }

    const handlePurchase = async (plan) => {

        setSelectedPlan(plan)

        if (!token) {
            // toast.error("Please login to continue");

            // userServices.logOut(true);
            history.replace('/register')

        } else if (user && ((await userServices.checkIsVerified()) == false)) {
            history.replace('/verify')
        } else {
            setProcessing(true);
            try {
                const response = await subscriptionServices.subscribe(plan);

                //FIX 'plan.'/user plan
                // if (plan.amount == 0) {
                //   window.location.href = plan.payment_link + "?prefilled_email=" + user.email;
                // } else {
                if (response.data.checkoutUrl) {
                    window.location.href = response.data.checkoutUrl;
                    setLoading(false);
                }
                else {
                    toast.error('Error generating payment link. Please try again later');
                }
                setLoading(false);
                setProcessing(false);
                // }
            } catch (e) {
                setProcessing(false);
                toast.error('Cannot process payment at the moment. Please try again later.');
            }
        }
    };

    return (
        <div
            key={tier._id}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
        >
            <div className="flex-1 flex flex-col h-full justify-between">
                <h3 className="text-xl font-semibold text-gray-900 capitalize break-after-column">{tier.name}</h3>
                <div>
                    {tier.amount == 29.99 ? (
                        // {tier.mostPopular ? (
                        <p className="absolute top-0 py-1.5 px-4 bg-blue-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                            Most popular
                        </p>
                    ) : null}
                    <p className="mt-4 flex items-baseline text-gray-900">
                        <span className="text-5xl font-extrabold tracking-tight">£{tier.amount}</span>
                        <span className="ml-1 text-xl font-semibold">/{tier.interval}</span>
                    </p>
                    {/* <p className="mt-6 text-gray-500 capitalize">{tier.description}</p> */}

                    <ul role="list" className="mt-6 space-y-6">
                        {tier.description?.split(',').map((feature) => (
                            <li key={feature} className="flex">
                                <CheckIcon className="flex-shrink-0 w-6 h-6 text-blue-500" aria-hidden="true" />
                                <span className="ml-3 text-gray-500">{feature}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {!userHasSubscription && (
                <button
                    onClick={() => {
                        handlePurchase(tier);
                    }}
                    disabled={processing && selectedPlan._id == tier._id}
                    className={classNames(
                        // index % 2 == 0
                        // ? 'bg-blue-500 text-white hover:bg-blue-500'
                        // : 'bg-blue-50 text-blue-600 hover:bg-blue-100',
                        'bg-blue-500 text-white hover:bg-blue-500',
                        'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium',
                        (processing && selectedPlan._id == tier._id) && 'cursor-default hover:bg-blue-50'
                    )}
                >
                    {processing && selectedPlan._id == tier._id ? 'Please wait' : 'Purchase Now'}
                </button>
            )}
        </div>
    )

}
