import React, { Component, useState } from "react";
import Header from "../../components/HomePage/Header";
import BeforeFooter from "../../components/HomePage/BeforeFooter";
import Footer from "../../components/HomePage/Footer";
import ire from "../../img/team/ire.jpeg";
import seye from "../../img/team/seye.png";
import emmanuel from "../../img/team/emmanuel.jpg";

import {
  PageHeaderContainer,
  PageHeaderText,
} from "../../components/Shared/PageHeader/pageheader.style";
import "./About.css";
import Modal from "../../components/Shared/Modal";

const team = [
  {
    name: "Iretunde Soleye",
    image: ire,
    about: `Ire grew up in Essex and attended Brentwood School as an Academic Scholar.
            He achieved A*A*A in Maths, Further Maths and Economics in his A-Levels and went on to read BSc Mathematics with Economics at the London School of Economics (LSE). He worked in Finance for about 2 years, before quitting to focus on NGL full-time! Outside of academia and finance, he has a strong passion for music! He regularly plays the piano at different functions, so if you need a pianist/keyboardist for any gig, he’s your man! 
            `,
    link: "https://www.linkedin.com/in/iretundesoleye/"
  },
  {
    name: "Seye Akinboyewa",
    image: seye,
    about: `Seye attended Chislehurst and Sidcup Grammar School and achieved A*AA in Maths, Further Maths and Economics respectively. Subsequently, he studied Accounting and Finance at the University of Warwick - Warwick Business School. He started his professional career at Deloitte, where he became a chartered accountant and now works in corporate finance. Aside from his professional career, Seye enjoys going to the gym, watching anime and online gaming!    `,
    link: "https://www.linkedin.com/in/seye-akinboyewa-037074115/"
  },
  // {
  //   name: "Emmanuel Akanbi",
  //   image: emmanuel,
  //   about: `Emmanuel completed his A-Levels at Queens’ School Sixth Form achieving AAAA in Maths, Further Maths, Economics and Politics. He then went on to achieve a first-class degree in Mathematics with Finance at King’s College London. Whilst at university, he founded a financial education company called TRINTEL. His interest in starting a career in quantitative finance influenced his decision to study for a post-graduate degree in Mathematics and Finance at Imperial College London.`,
  //   link: "https://www.linkedin.com/in/kclemmanuelakanbi/"
  // },
]

const Home = () => {

  const [showModal, setShowModal] = useState(false);
  const [activeTeamMember, setActiveTeamMember] = useState({});

  const handleShowProfile = (e, profile) => {
    e.preventDefault();

    setActiveTeamMember(profile)
    setShowModal(true);
  }

  return (
    <div className="about ">
      <Header />
      <div className="pt-12" />
      <main className="boxed__container">
        <main className="mx-auto flex justify-center">
          <PageHeaderContainer className="">
            <PageHeaderText>Our Mission</PageHeaderText>
          </PageHeaderContainer>
        </main>
        <main className="flex justify-center">
          <main className="w-full md:w-1/2 ">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-2 text-center"> Making 11+ Maths easy to pass!</h2>
            <p className="page__header my-4"></p>
            <div className="text-base text-center my-4 mb-8 space-y-3">
              <p>
                “We're a big believer in equal opportunity and aim to be an affordable tech-based learning provider. Every child should have an equal chance to get into a top grammar/independent school!
              </p>
              <p>
                We both have strong backgrounds in mathematics and we’ve ensured that our platform is as comprehensive as possible, so you can save money on textbooks and exam papers, whilst receiving the best possible education.”
              </p>
            </div>
          </main>
        </main>

        <main className="flex justify-center my-8">
          <main className="w-full md:w-1/2 ">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900  mt-2 text-center"> Meet the Team</h2>
          </main>
        </main>

        <main className="flex justify-center flex-wrap mb-12 w-full">
          {team.map((person, index) => (
            <div key={index} className="w-4/6 md:w-1/3 text-center p-2">
              <main className="my-2">
                <img className="mx-auto h-48 rounded-full cursor-pointer hover:opacity-90 border-transparent transition-all duration-100	 border-4 border-solid hover:border-blue-400" src={person.image} onClick={(e) => handleShowProfile(e, person)} />
                <p className="text-center text-base font-bold mt-4">{person.name}</p>
                <p className="text-center">
                  <a href={person.link} onClick={(e) => handleShowProfile(e, person)} target="_blank">Learn more about {person.name}</a>
                </p>
              </main>
            </div>
          ))}
        </main>
      </main>


      <Modal open={showModal} setOpen={() => setShowModal(false)}>
        <div className="text-center text-lg">
          <img className="mx-auto h-48 rounded-full cursor-pointer mb-8" src={activeTeamMember.image} />

          {activeTeamMember.about}

          <div className="block mt-8">
            <a href={activeTeamMember.link} className="px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-white hover:text-blue-500 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-8 " target="_blank"><i className="fa fa-link"></i> View LinkedIn Profile</a>
          </div>
        </div>
      </Modal>
      {/* <BeforeFooter /> */}
      <Footer />
    </div>
  )
}

export default Home;