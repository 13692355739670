import React, { createContext, useEffect, useState } from "react";
import { userServices } from "../services/userServices";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [token, setToken] = useState("");
  const [user, setUser] = useState({});


  useEffect(() => {
    // userServices.setUser(user);
    // console.log('user data changed', user)
  }, [user])

  return (
    <AuthContext.Provider value={{ token, setToken, user, setUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
