import React, { Component, useEffect, useState } from "react";
import Header from "../../components/Shared/Header";
import "./Glossary.css";
import { useHistory } from "react-router-dom";
import { api, get, post } from "../../utils/api";
import CardsSkeleton from "../../components/CardsSkeleton";
import { toast } from "react-toastify";
import SearchResultList from "./SearchResultList";

const Glossary = (props) => {
  const history = useHistory();

  const [search, setSearch] = useState("");
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tag, setTag] = useState(props.match.params.tag);


  useEffect(() => {
    setLoading(true);
    get(`/courses/glossary/search?tag=${tag}`, true).then((res) => {
      setCourses(res.data.result);
      setLoading(false);
    }).catch((e) => {
      toast.error('An error occured. Please try again.')
      setLoading(false);
    })

  }, [])

  return (
    <div className="">
      <Header />
      <main className="py-4 w-full px-10 ">
        <div className="" />
        {/* <BackButton route="/glossary" className="pt-8 " /> */}

        <p className="font-bold text-3xl text-left mt-4 capitalize">{tag}</p>

        {loading ? <CardsSkeleton /> :
          <main className=" my-8 flex flex-wrap  top__wrapper ">
            {courses.length > 0 ?
              <SearchResultList courses={courses} />
              // <VideosCard videos={courses} />
              : <p>No result found</p>}
          </main>
        }
      </main>
    </div>
  );
};

export default Glossary;
