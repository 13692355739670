import React, { Component, useState } from "react";
import './index.css';
import Iframe from 'react-iframe'

import App from "../../components/Layouts/App";

const texts = [
    'Weekly online group tuition with a max class size of 6 children.',
    'Full access to the NGL Maths Platform',
    'Access to our 24/7 Support Group'
]







const PremiumTuitionRegister = () => {


    return (
        <App title="Register for Premium Tuition offer">

            <div className="p-4 bg-gray-50 rounded-md w-full block mb-6">

                <Iframe url="https://tally.so/r/wz7r81"
                    width="100%"
                    height="320px"
                    id=""
                    className=" h-screen mx-auto w-full"
                    display="block"
                    position="relative" />
                {/* {/* <iframe src="https://tally.so/r/wz7r81?transparentBackground=1"
                    className=" h-screen mx-auto w-full"
                    display="block"
                    position="relative" */}
                    frameborder="0" marginHeight="0" marginWidth="0" title="Tuition Registration Form" /> */}
            </div>

        </App>

    )
}

export default PremiumTuitionRegister;