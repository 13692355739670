import React, { useState, useEffect } from "react";
import Header from "../../components/Shared/Header";
// import "./index.css";
import Sidebar from "./Sidebar";
import { useMutation, useQueries, useQuery } from "react-query";
import { api, get, post } from "../../utils/api";
import { toast } from "react-toastify";
import queryString from "query-string";
import Skeleton from "react-loading-skeleton";
import { useHistory, useLocation } from "react-router-dom";
import * as moment from "moment";
import { InformationCircleIcon } from "@heroicons/react/solid";
import Plans from "../Subscribe/Plans";
import Confirm from "../../components/Shared/Confirm";
import { subscriptionServices } from "../../services/subscriptionServices";

const Subscription = (props) => {

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [mySubscription, setMySubscription] = useState({});
  const [hasSubscription, setHasSubscription] = useState(false);
  const [isLoadingStatus, setLoadingStatus] = useState(true);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);


  useEffect(() => {
    fetchSubscriptionStatus();
  }, []);


  const fetchSubscriptionStatus = async () => {
    setLoadingStatus(true);
    const res = await subscriptionServices.checkSubscription(); // get("/subscription/my-subscription", true).then((res) => {

    if (res.status == 200) {
      setHasSubscription(true);
      if (res.data.subscription) {
        setMySubscription(res.data.subscription);
      }
    }
    setLoadingStatus(false);

  }


  const handleCancelSubscription = () => {
    setLoadingStatus(true);
    get(`/subscription/${mySubscription.id}/cancel-subscription`, true).then((res) => {
      if (res.status == 200) {
        toast(res.data.message);
      }
      setMySubscription({})
      setLoadingStatus(false);
    }).catch(e => {
      toast.error(e.data?.message);
      setLoadingStatus(false);
    })
    setShowCancelConfirm(false);

  }
  const sessionId = queryString.parse(props.location.search).session_id;


  const {
    isLoading: isConfirmingPayment,
    error: errorConfirmingPayment,
    data: paymentData,
    refetch: refetchData,
  } = useQuery(
    useQuery("verifyPayment", () => {
      if (sessionId) {
        return get(`/subscription/success?session_id=${sessionId}`, true).then((res) => {
          setLoading(false);
          if (res.status == 200) {
            toast.success("Subscription Successful!");
            history.replace(`/subscription/success?session_id=${sessionId}`);
            //location.pathname
          }
        }).catch(e => {
          setLoading(false);
          toast.error('An error occured. Please try again.');
        })

      }
    }),
    {
      // The query will not execute until the userId exists
      enabled: sessionId,
    }
  );


  return (
    <div className="account__page ">
      <Header />

      <main className="">
        <main className="  my-8 max-w-7xl mx-auto  flex flex-wrap boxed__container top__wrapper ">
          <main className="w-full md:w-1/5 border">
            <Sidebar activeMenu="subscription" />
          </main>
          <main className="w-full md:w-4/5 border">
            <main className="mx-auto">
              <main className="w-full mx-auto my-2 px-2">
                <p className="my-8"></p>
                <p className="text-center page__header">
                  {" "}
                  SUBSCRIPTIONS & BILLING
                </p>

                <main className="shadow rounded-xl mx-4 md:ml-4 md:mr-12">
                  {isLoadingStatus ? (
                    <Skeleton className="mt-4" count={5} />
                  ) : (
                    ""
                  )}
                </main>

                {/* <p className="text-center sub__header">
                  You are yet to enrol in any of our programs. Do this, then
                  we'll get you started
                </p> */}

                {/* {!isLoadingStatus && hasSubscription && mySubscription &&  mySubscription.plan && ( */}
                {!isLoadingStatus && mySubscription &&
                  (
                    mySubscription.plan ? (
                      <main>
                        {" "}
                        <p className="text-center ">
                          Here are the details of your current subscription
                        </p>
                        <main className="w-full flex flex-wrap  text-center my-8 ">
                          <main className="w-full md:w-1/2 my-4  ">
                            <p className="font-bold">PLAN NAME</p>
                            <p className="description capitalize">
                              {mySubscription.is_trial ? "Trial Plan" : mySubscription.plan.name}
                            </p>
                          </main>
                          <main className="w-full md:w-1/2 my-4">
                            <p className="font-bold">SUBSCRIPTION CHARGES</p>
                            <p className="description ">
                              £{mySubscription.is_trial ? "0" : mySubscription.plan.amount}
                            </p>
                          </main>
                          <main className="w-full md:w-1/2 my-4">
                            <p className="font-bold">DURATION PERIOD</p>
                            <p className="description capitalize">{mySubscription.is_trial ? "10 Days" : mySubscription.plan.interval}</p>
                          </main>
                          <main className="w-full md:w-1/2 my-4">
                            <p className="font-bold">SUBSCRIPTION DATE</p>
                            <p className="description">
                              {moment(mySubscription.start_date).format(
                                "Do MMMM YYYY"
                              )}
                            </p>
                          </main>
                          <main className="w-full md:w-1/2 my-4">
                            <p className="font-bold">PAYMENT RENEWAL DATE</p>
                            <p className="description">
                              {/* {mySubscription.due_date} */}
                              {mySubscription.is_trial ?
                                moment(mySubscription.start_date).add(10, 'days').format(
                                  "Do MMMM YYYY"
                                ) :
                                moment(mySubscription.start_date).add(mySubscription.plan.interval == 'month' ? 1 : 12, 'months').format(
                                  "Do MMMM YYYY"
                                )}
                              {/* {moment(mySubscription.createdAt).add(1, 'month').format(
                                "Do MMMM YYYY"
                              )} */}
                            </p>
                          </main>
                        </main>
                      </main>
                    ) :
                      <div className="rounded-md bg-blue-50 p-4 m-3">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">You have no active subscription</p>
                          </div>
                        </div>
                      </div>

                  )}


                {!isLoadingStatus && Object.keys(mySubscription).length == 0 && (
                  <main>
                    <hr />
                    <p className="text-center text-md mt-5">
                      Upgrade plan. Select a billing plan below:
                    </p>
                    <Plans />
                  </main>
                )}

                {Object.keys(mySubscription).length > 0 && (
                  <div className="text-center p-4">
                    <a href="#" onClick={() => setShowCancelConfirm(true)}>Cancel Subscription</a>
                  </div>
                )}
                {/* handleCancelSubscription */}

                <Confirm
                  title="Are you sure?"
                  message={<>You are about to cancel your subscription. <br />This will remove your access to all features <strong>immediately</strong>.<br />
                    Are you sure you want to proceed with this action?</>}
                  open={showCancelConfirm} performAction={handleCancelSubscription} />
              </main>
            </main>
          </main>
        </main>
      </main>
    </div>
  );
};

export default Subscription;
