// import "./sidebar.module.css";
import React, { Component, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { AuthContext } from "../../../contexts/AuthContext";
import { classNames } from "../../../utils/helpers";
import { CogIcon, CreditCardIcon, KeyIcon, UserCircleIcon } from "@heroicons/react/outline";
import { PencilAltIcon } from "@heroicons/react/solid";
import { userServices } from "../../../services/userServices";

const Sidebar = (props) => {
  const { user, setUser } = useContext(AuthContext);
  const { activeMenu } = props;

  const routes = [
    {
      name: "View Profile",
      key: "profile",
      route: "/profile",
      icon: UserCircleIcon,

    },
    {
      name: "Profile Photo",
      key: "photo",
      route: "/photo",
      icon: CogIcon,
    },
    {
      name: "Edit Profile",
      key: "account",
      route: "/account",
      icon: PencilAltIcon,
    },
    {
      name: "Update Password",
      key: "password",
      route: "/password",
      icon: KeyIcon,
    },
    // {
    //   name: "Privacy",
    //   key: "privacy",
    //   route: "/privacy",
    // },
    {
      name: "Subscriptions & Billing",
      key: "subscription",
      route: "/subscription",
      icon: CreditCardIcon,
    },
    // {
    //   name: "Notification",
    //   key: "notification",
    //   route: "/notification",
    // },
    // {
    //   name: "Close Account",
    //   key: "close",
    //   route: "/account",
    // },
  ];

  return (
    <aside className="py-6 lg:col-span-3">
      <nav className="space-y-1">
        {user && user.profile_image ? (
          <img
            src={user.profile_image}
            alt=""
            className="rounded-full bg-gray-300 object-fit mx-auto h-24 w-24 "
          />
        ) : (
          <p className="text-center">
            <i className="fas fa-5x text-black fa-user-circle text-center mx-auto"></i>
          </p>
        )}
        <br />
        <br />
        {routes.map((item, index) => (
          <Link
            key={index}
            to={item.route}
            className={classNames(
              activeMenu == item.key
                ? 'bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700'
                : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
              'group border-l-4 px-3 py-2 flex items-center text-sm font-medium'
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            <item.icon
              className={classNames(
                item.current
                  ? 'text-blue-500 group-hover:text-blue-500'
                  : 'text-gray-400 group-hover:text-gray-500',
                'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="truncate">{item.name}</span>
          </Link>
        ))}
      </nav>
    </aside>
  );
};


{/* // <main
          //   key={index}
          //   className={`cursor-pointer w-full py-3 pl-12 nav__link ${
          //     activeMenu == route.key ? "active__route" : ""
          //   }`}
          // >
          //   <Link to={route.route}>{route.name}</Link>
          // </main> */}

export default withRouter(Sidebar);
