import axios from "axios";
// import { BehaviorSubject } from 'rxjs';


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000
});

// request interceptor
instance.interceptors.request.use(
  (config, reqAuth) => {
    return config;
  },
  (error) => { }
);

// const tokenSubject = new BehaviorSubject(process.browser && localStorage.getItem('maths-user') != 'undefined' && localStorage.getItem('maths-user'));
const token = localStorage.getItem('maths-user');

// const getToken = async () => {
//   const token = await userServices.getToken();
// }


// // console.log({token,tokenSubject})

const getConfig = () => {
  // let _token = null;
  // getToken().then((token) => {
  //   _token = token;
  // })

  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

  }
}



const get = async (url, requireAuth = false) => {
  try {
    let response = requireAuth
      ? await instance.get(url, getConfig())
      : await instance.get(url);
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const post = async (url, payload, requireAuth = false) => {
  // // console.log({requireAuth});
  try {
    let response = requireAuth
      ? await instance.post(url, payload, getConfig())
      : await instance.post(url, payload);
    // // console.log({response});
    return response;
  } catch (error) {
    return handleError(error);
    // // console.log({error})
    // throw error;
  }
};

const patch = async (url, payload, requireAuth = false) => {
  try {
    let response = requireAuth
      ? await instance.patch(url, payload, getConfig())
      : await instance.patch(url, payload);
    return response;
  } catch (error) {
    return handleError(error);
    // return error.response;
  }
};

const put = async (url, payload = {}, requireAuth = false) => {
  try {
    let response = requireAuth
      ? await instance.put(url, payload, getConfig())
      : await instance.put(url, payload);
    return response;
  } catch (error) {
    return handleError(error);
    // return error.response;
  }
};

const del = async (url, payload = {}, requireAuth = true) => {
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    };
    let response = requireAuth
      ? await instance.delete(url, getConfig())
      : await instance.delete(url, { data: payload });
    return response;
  } catch (error) {
    return handleError(error);
    // return error.response;
  }
};
// };
// const handleError = async (error) => {

//   // console.log({error})

//   if(error?.response?.status == 401) {
//     localStorage.clear();

//   }
//   return error.response

// };

const handleError = async (error) => {

  let message = "Some errors occured";
  let errors = [];
  // // console.log({ error })
  if (error.response) {
    if (typeof error.response.data.message == "string") {
      message = error.response.data.message;
    } else {
      errors = error.response.data.message;
    }
  } else {
    message = error.message;
  }

  // Check if user account is verified  
  if (error?.response?.data?.is_activated == false && window.location.pathname != "/") {
    window.location.href = '/verify';
  }
  if (error?.response?.data?.is_subscribed == false) {
    window.location.href = '/subscribe';
  }

  if (error?.response?.status == 401) {
    localStorage.clear();

    window.location.href = '/login-notice';
  }

  return { message, errors, error, status: false, statusCode: error.response?.status };
};

const api = instance;

export { get, post, put, handleError, token, del, api };