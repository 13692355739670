import { UserCircleIcon } from "@heroicons/react/solid"

export default function Avatar({ user }) {
    return (
        user.profile_image ?

            <img
                className="h-8 w-8 rounded-full"
                src={user.profile_image}
                alt=""
            />

            :
            <UserCircleIcon className="w-10 h-10" />
        // <i className="fas fa-3x text-black fa-user-circle text-center mx-auto"></i>
    )
}