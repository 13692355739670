import React from 'react';
import { CheckIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import Skeleton from '../../components/Skeleton'
import { subscriptionServices } from '../../services/subscriptionServices';
import { userServices } from '../../services/userServices';
import Plan from './PlanCard';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Plans({ showOnlyTutorPlan = false }) {

  const history = useHistory();

  const token = userServices.getToken();
  const user = userServices.getUser();

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [userHasSubscription, setUserHasSubscription] = useState(false);

  useEffect(() => {
    getUserSubscription();
    fetchSubscriptions();
  }, []);



  const fetchSubscriptions = async () => {
    setLoading(true);

    const response = await subscriptionServices.getPlans();

    if (response.data?.products) {
      let plans = response.data.products.reverse();

      if (await subscriptionServices.checkUsedTrialSubscription()) {
        plans = plans.filter((item) => item.amount != 0);
      }

      if (showOnlyTutorPlan) {
        // Show only Tutor Plan
        plans = plans.sort(function (a, b) { return a.order - b.order }).filter(item => !item.is_featured);

      } else {
        // Remove tutor plans
        plans = plans.filter(item => item.is_featured);

      }

      setPlans(plans);

      setLoading(false);
    }
  }

  const getUserSubscription = async () => {
    if (userServices.isLoggedIn()) {
      const response = await subscriptionServices.checkSubscription();

      if (response.data?.subscription?.is_active && response.data?.subscription?.past_due == false) {
        setUserHasSubscription(true)
      }

    }
  }

  const handlePurchase = async (plan) => {

    setSelectedPlan(plan)

    if (!token) {
      // toast.error("Please login to continue");

      // userServices.logOut(true);
      history.replace('/register')

    } else if (user && ((await userServices.checkIsVerified()) == false)) {
      history.replace('/verify')
    } else {
      setProcessing(true);
      try {
        const response = await subscriptionServices.subscribe(plan);

        //FIX 'plan.'/user plan
        // if (plan.amount == 0) {
        //   window.location.href = plan.payment_link + "?prefilled_email=" + user.email;
        // } else {
        if (response.data.checkoutUrl) {
          window.location.href = response.data.checkoutUrl;
          setLoading(false);
        }
        else {
          toast.error('Error generating payment link. Please try again later');
        }
        setLoading(false);
        setProcessing(false);
        // }
      } catch (e) {
        setProcessing(false);
        toast.error('Cannot process payment at the moment. Please try again later.');
      }
    }
  };


  return (
    <>

      {loading ? <Skeleton count={10} /> : (
        <>

          <div className={`mt-12 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-${plans.length > 3 ? 3 : plans.length} gap-3 lg:gap-4`}>

            {plans?.map((tier, index) => {

              return (
                <Plan tier={tier} key={index} index={index} />
              )
            }
            )}
          </div>
        </>
      )
      }
    </>
  )
}
