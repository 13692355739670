import React from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function Modal(props) {
  // const [open, setOpen] = useState(props.open ? props.open : false)

  return (
    <Transition.Root show={props.open ? props.open : false} as={Fragment}>
      {/* setOpen */}
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={props.setOpen}>
        <div className="flex justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* text-left sm:align-middle sm:max-w-sm sm:w-full sm:p-6 */}
            <div className="inline-block align-middle h-5/6 bg-white rounded-lg px-4 pb-4 overflow-hidden shadow-xl transform transition-all md:w-3/5 w-full text-left">
              <main className="flex justify-end items-end ">
                <main
                  onClick={() => props.setOpen(false)}
                  className="text-2xl mr-2 text-center cursor-pointer"
                >
                  &times;
                </main>
              </main>


              <div>
                {props.children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}